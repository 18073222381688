export enum ELanguage {
  RU = 'ru',
  EN = 'en',
  CN = 'cn',
  ES = 'es',
  FR = 'fr',
  HI = 'hi',
  AR = 'ar',
  PT = 'pt',
  DE = 'de',
  IT = 'it',
  TR = 'tr',
  ID = 'id',
  JP = 'jp',
  KR = 'kr',
  PH = 'ph',
  TH = 'th',
  VN = 'vn',
}

export enum EAccountRole {
  USER = 'USER',
  ADMIN = 'ADMIN',
  SUPPORT = 'SUPPORT',
}

export enum EPromoStatus {
  ACTIVE = 'ACTIVE',
  APPLIED = 'APPLIED',
  FINISHED = 'FINISHED',
}

export enum ESort {
  ASC = 'asc',
  DESC = 'desc',
}

export enum ETransactionType {
  DEPOSIT_USDT = 'DEPOSIT_USD',
  GAT_INVEST_AFT = 'GAT_INVEST_AUF',
  GAT_INVEST_USDT = 'GAT_INVEST_USD',
  GAT_INVEST_GAT = 'GAT_INVEST_GAT',
  USDT_INVEST_USDT = 'USD_INVEST_USD',
  USDT_INVEST_GAT = 'USD_INVEST_GAT',
  USDT_INVEST_AFT = 'USD_INVEST_AUF',
  ETHI_INVEST_USDT = 'ETHI_INVEST_USD',
  ETHI_UPGRADE_USDT = 'ETHI_UPGRADE_USD',
  SWAP_ETHI_USDT_TO_USDT = 'SWAP_ETHI_USD_TO_USD',
  SWAP_CURRENCY_TO_CURRENCY = 'SWAP_CURRENCY_TO_CURRENCY',
  TRANSFER_USDT_TO_ETHI = 'TRANSFER_USD_TO_ETHI',
  INVEST_USDT = 'INVEST_USD',
  INVEST_AFT = 'INVEST_AUF',
  PASSIVE_PAYOUT = 'PASSIVE_PAYOUT',
  USDT_PASSIVE_PAYOUT = 'USD_PASSIVE_PAYOUT',
  GAT_PASSIVE_PAYOUT = 'GAT_PASSIVE_PAYOUT',
  GAT_GREAT_PASSIVE_PAYOUT = 'GAT_GREAT_PASSIVE_PAYOUT',
  ETHI_PASSIVE_PAYOUT = 'ETHI_PASSIVE_PAYOUT',
  REFERRAL_PAYOUT = 'REFERRAL_PAYOUT',
  REFERRAL_PAYOUT_GAT = 'REFERRAL_PAYOUT_GAT',
  REFERRAL_PAYOUT_USDT = 'REFERRAL_PAYOUT_USD',
  REFERRAL_PAYOUT_ETHI = 'REFERRAL_PAYOUT_ETHI',
  WITHDRAWAL = 'WITHDRAWAL',
  // Exit
  USDT_INVEST_EXIT = 'USD_INVEST_EXIT',
  AFT_INVEST_EXIT = 'AUF_INVEST_EXIT',
  GAT_INVEST_EXIT = 'GAT_INVEST_EXIT',
  ETHI_INVEST_DOWNGRADE = 'ETHI_INVEST_DOWNGRADE',
  ETHI_INVEST_EXIT = 'ETHI_INVEST_EXIT',
  ETHI_EMERGENCY_INVEST_EXIT = 'ETHI_EMERGENCY_INVEST_EXIT',
  USDT_INVEST_EMERGENCY_EXIT = 'USD_INVEST_EMERGENCY_EXIT',
  USDT_INVEST_LAST_PERCENTS_EXIT = 'USD_INVEST_LAST_PERCENTS_EXIT',
  // Delete plans transactions
  ETHI_INVEST_PLAN_DELETE = 'ETHI_INVEST_PLAN_DELETE',
  USDT_INVEST_PLAN_DELETE = 'USD_INVEST_PLAN_DELETE',
  GAT_INVEST_PLAN_DELETE = 'GAT_INVEST_PLAN_DELETE',
  AFT_INVEST_PLAN_DELETE = 'AUF_INVEST_PLAN_DELETE',
  // Clear balances transactions
  ETHI_SET_BALANCE_TO_ZERO = 'ETHI_SET_BALANCE_TO_ZERO',
  USDT_SET_BALANCE_TO_ZERO = 'USD_SET_BALANCE_TO_ZERO',
  AFT_SET_BALANCE_TO_ZERO = 'AUF_SET_BALANCE_TO_ZERO',
  GAT_SET_BALANCE_TO_ZERO = 'GAT_SET_BALANCE_TO_ZERO',
  // Rank
  RANK_BONUS_PAYOUT = 'RANK_BONUS_PAYOUT',
  // Admin
  ADMIN_DEPOSIT_SWAP = 'ADMIN_DEPOSIT_SWAP',
  ADMIN_DEPOSIT_USDT = 'ADMIN_DEPOSIT_USD',
  ADMIN_DEPOSIT_ETHI = 'ADMIN_DEPOSIT_ETHI',
  ADMIN_DEPOSIT_AFT = 'ADMIN_DEPOSIT_AUF',
  ADMIN_DEPOSIT_GAT = 'ADMIN_DEPOSIT_GAT',
}

export enum EInvestPlan {
  SWAP = 'SWAP',
  ETHI = 'ETHI',
  AFT = 'AFT',
  GAT = 'GAT',
}

export enum ECurrency {
  USD = 'USD',
  AUF = 'AUF',
  GAT = 'GAT',
  SWAP_USD = 'SWAP_USD',
  ETHI_USD = 'ETHI_USD',
}

export enum ECryptoTicker {
  BTC = 'BTC',
  ETH = 'ETH',
  ERC_USDT = 'ERC_USDT',
  TRC_USDT = 'TRC_USDT',
}

export enum ETransactionStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE = 'COMPLETE',
  CANCEL = 'CANCEL',
  LOCKED_BY_X_FACTOR = 'LOCKED_BY_X_FACTOR',
  MISSED = 'MISSED',
  ERROR = 'ERROR',
}

export enum EInvestPlanStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum EEthiPackageName {
  START = 'START',
  BUSINESS = 'BUSINESS',
  PREMIUM = 'PREMIUM',
}

export enum ETableView {
  TABLE = 'table',
  LIST = 'list',
}

export enum EHistoryFilter {
  ALL = 'all',
  DEPOSITS = 'deposits',
  WITHDRAWALS = 'withdrawals',
  REFERRALS = 'referrals',
  INVESTMENTS = 'investments',
  PAYOUTS = 'payouts',
  BONUSES = 'bonuses',
  OTHERS = 'others',
}
