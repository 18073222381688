import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightFromBracket } from '@fortawesome/pro-duotone-svg-icons';
import { memo, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalAlert } from '@~components/modal-alert/ModalAlert';
import { useStateUpdate } from '@~context/StateContext';
import { useResponseHandler } from '@~hooks/useResponseHandler';
import { exitEthi } from '@~network/account';
import type { IEthiInvestPlan } from '@~types/types';
import { EInvestPlanStatus } from '@~types/enums';

interface IEthiEarlyWithdrawalModalProps {
  fetchEthiInvestPlan: () => void;
  planData: IEthiInvestPlan;
}

export const EthiEarlyWithdrawalModal = memo(({ fetchEthiInvestPlan, planData }: IEthiEarlyWithdrawalModalProps) => {
  const { t } = useTranslation();

  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const requestSentRef = useRef(false);

  const handleResponse = useResponseHandler();
  const { fetchWallets } = useStateUpdate();

  const activePackage = planData.planPackage;

  const submitForm = useCallback(() => {
    if (requestSentRef.current || !activePackage || planData.workedDaysCount > 28) {
      return;
    }

    requestSentRef.current = true;
    setLoading(true);

    exitEthi()
      .then((response) => {
        if (response.success) {
          fetchWallets();
          fetchEthiInvestPlan();
          handleResponse(
            t('pages.account.staking.content.ethi-plan.exit-success', {
              amount: (activePackage.price * 0.7).toLocaleString(),
            }) + '',
            false
          );
        } else {
          handleResponse(response, true);
        }
      })
      .catch((response) => {
        handleResponse(response.response.data, true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [fetchWallets, fetchEthiInvestPlan, handleResponse, activePackage, planData, t]);

  const handleModalAction = useCallback(
    (success: boolean) => {
      if (!success) {
        setOpenModal(false);
        return;
      }

      submitForm();
    },
    [submitForm]
  );

  return (
    <>
      <button
        className="btn btn-link tx-primary py-1 px-2"
        onClick={() => setOpenModal(true)}
        disabled={!activePackage || planData.status !== EInvestPlanStatus.ACTIVE || planData.workedDaysCount > 28}
      >
        <FontAwesomeIcon icon={faRightFromBracket} />
      </button>

      <ModalAlert
        key="modal-for-early-ethi-exit"
        proceed={handleModalAction}
        show={openModal}
        title={t('pages.account.staking.content.ethi-plan.exit-title') + ''}
        okLabel={t('pages.account.staking.content.ethi-plan.exit-ok') + ''}
        okVariant="warning"
        size="lg"
        enableEscape
        loading={isLoading}
        content={
          <div className="modal-content-bg">
            <div className="tx-bold tx-warning">
              {t('pages.account.staking.content.ethi-plan.exit-confirmation', {
                amount: (activePackage ? activePackage.price * 0.7 : 0).toLocaleString(),
              })}
            </div>
            <p className="tx-14 mt-5">* {t('pages.account.staking.content.ethi-plan.text.note')}</p>
          </div>
        }
      />
    </>
  );
});
