import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons/faCircleNotch';

interface IIconWithLoadingProps {
  icon?: IconDefinition;
  className?: string;
  isLoading?: boolean;
}

export function IconWithLoading({ icon, className = 'me-2', isLoading = false }: IIconWithLoadingProps) {
  if (!icon) {
    return null;
  }

  return <FontAwesomeIcon icon={isLoading ? faCircleNotch : icon} className={className} spin={isLoading} />;
}
