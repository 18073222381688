import { api } from '@~network/api';
import { ECryptoTicker, ECurrency, ETransactionStatus, ETransactionType } from '@~types/enums';
import type {
  IAccount,
  IDepositOption,
  IEthiInvestPlan,
  IEthiPlanPackage,
  IEthiUpgradedPlan,
  IGatInvestPlan,
  IInvestPlan,
  IProfile,
  IProfileUpdateProps,
  IRankInfo,
  IRankRule,
  IReferralInfo,
  IReferralRule,
  IReferralSubLine,
  IQrCode,
  IToken,
  ITransaction,
  IUsdInvestPlan,
  IWallet,
  ILoginResponse,
  IPaged,
  IStatusResponse,
  TTemporaryAny,
  IEthiClaimAvailable,
} from '@~types/types';
import type {
  IBuyGatData,
  IChangePasswordData,
  IChangePasswordWithToken,
  ICreateDepositOptionData,
  IEthiPlanPackageIdData,
  IInvestData,
  ILoginData,
  IOneTimePasswordData,
  IPromoCappadociaData,
  IPromoTurkeyData,
  IReferralSubLineData,
  IRegisterData,
  IResetPasswordTokenData,
  ITransactionsData,
  ITwoFactorCode,
  IWithdrawData,
} from '@~types/requests';

export function register(username: string, email: string, password: string, uplinerRefId: string) {
  return api<IRegisterData, TTemporaryAny>({
    method: 'post',
    path: '/auth/register',
    data: { username, email, password, uplinerRefId },
  });
}

export function login(loginField: string, password: string, twoFactorCode?: string) {
  return api<ILoginData, IStatusResponse<ILoginResponse>>({
    method: 'post',
    path: '/auth/login',
    data: { login: loginField, password, twoFactorCode },
  });
}

export function resetPasswordToken(usernameOrEmail: string) {
  return api<IResetPasswordTokenData, IStatusResponse<TTemporaryAny>>({
    method: 'post',
    path: '/auth/reset-password-token',
    data: { usernameOrEmail },
  });
}

export function changePasswordWithToken(token: string, password: string) {
  return api<IChangePasswordWithToken, IStatusResponse<null>>({
    method: 'post',
    path: '/auth/new-password',
    data: { token, password },
  });
}

export function getWallets() {
  return api<null, IWallet[]>({ method: 'get', path: '/wallet' });
}

export function getTransactions(page: number, size = 10, types?: ETransactionType[], statuses?: ETransactionStatus[]) {
  const data: ITransactionsData = { page, size };
  if (types && types.length > 0) {
    data.type = types.join(',');
  }
  if (statuses && statuses.length > 0) {
    data.status = statuses.join(',');
  }

  return api<ITransactionsData, IPaged<ITransaction>>({
    method: 'get',
    path: '/transaction',
    data,
  });
}

export function getDepositOptions() {
  return api<null, IDepositOption[]>({ method: 'get', path: '/deposit/usd/options' });
}

export function createDepositOption(crypto: ECryptoTicker) {
  return api<ICreateDepositOptionData, IStatusResponse<IDepositOption>>({
    method: 'post',
    path: '/deposit/usd/generate',
    data: { crypto },
  });
}

export function getInvestPlan() {
  return api<null, IInvestPlan>({ method: 'get', path: '/invest/plan' });
}

export function invest(amount: number, currency = ECurrency.USD) {
  return api<IInvestData, IStatusResponse<TTemporaryAny>>({
    method: 'post',
    path: '/invest',
    data: { currency, amount },
  });
}

export function getGatInvestPlans() {
  return api<null, IGatInvestPlan[]>({ method: 'get', path: '/gat-invest/plans' });
}

export function investGat(amount: number, currency = ECurrency.USD) {
  return api<IInvestData, IStatusResponse<TTemporaryAny>>({
    method: 'post',
    path: '/gat-invest',
    data: { currency, amount },
  });
}

export function getUsdInvestPlan() {
  return api<null, IUsdInvestPlan>({ method: 'get', path: '/usd-invest/plan' });
}

export function investUsd(amount: number, currency = ECurrency.USD) {
  return api<IInvestData, IStatusResponse<TTemporaryAny>>({
    method: 'post',
    path: '/usd-invest',
    data: { currency, amount },
  });
}

export function exitUsd() {
  return api<null, IStatusResponse<TTemporaryAny>>({ method: 'post', path: '/usd-invest/emergency-exit' });
}

export function checkEthiClaimIsAvailable() {
  return api<null, IStatusResponse<IEthiClaimAvailable>>({ method: 'get', path: '/ethiopia-invest/claim' });
}

export function getEthiInvestPlan() {
  return api<null, IEthiInvestPlan>({ method: 'get', path: '/ethiopia-invest' });
}

export function investToEthiPlan(packageId: number) {
  return api<IEthiPlanPackageIdData, IStatusResponse<IEthiUpgradedPlan>>({
    method: 'post',
    path: '/ethiopia-invest',
    data: { packageId },
  });
}

export function exitEthi() {
  return api<null, IStatusResponse<TTemporaryAny>>({ method: 'post', path: '/ethiopia-invest/emergency-exit' });
}

export function getEthiPlanWithdrowalAllow() {
  // TODO: Should be changed to JSON
  return api<null, boolean>({ method: 'get', path: '/ethiopia-invest/withdrawal' });
}

export function getEthiPlanPackages() {
  // packages
  return api<null, IEthiPlanPackage[]>({ method: 'get', path: '/ethiopia-invest/packages' });
}

export function buyGat(currencyAmount: number, currency = ECurrency.USD) {
  return api<IBuyGatData, IStatusResponse<TTemporaryAny>>({
    method: 'post',
    path: '/buy/gat',
    data: { currency, currencyAmount },
  });
}

export function getAccountInfo() {
  return api<null, IAccount>({ method: 'get', path: '/account' });
}

export function getProfileInfo() {
  return api<null, IProfile>({ method: 'get', path: '/profile' });
}

export function updateProfileInfo(data: IProfileUpdateProps) {
  return api<IProfileUpdateProps, IStatusResponse<IProfile>>({ method: 'post', path: '/profile', data });
}

export function disconnectFromTelegram(twoFactorCode?: string) {
  return api<IOneTimePasswordData, IStatusResponse<IProfile>>({
    method: 'delete',
    path: '/profile/tg-token',
    data: { twoFactorCode },
  });
}

export function getTgToken() {
  return api<null, IStatusResponse<IToken>>({ method: 'get', path: '/profile/tg-token' });
}

export function generateTgToken(twoFactorCode?: string) {
  return api<IOneTimePasswordData, IStatusResponse<IToken>>({
    method: 'post',
    path: `/profile/tg-token`,
    data: { twoFactorCode },
  });
}

export function changePassword(currentPass: string, newPassword: string, twoFactorCode?: string) {
  return api<IChangePasswordData, IStatusResponse<null>>({
    method: 'post',
    path: '/auth/change-password',
    data: { currentPass, newPassword, twoFactorCode },
  });
}

export function getTwoFactorSetup() {
  return api<null, IStatusResponse<IQrCode>>({ method: 'get', path: '/two-factor/setup' });
}

export function enableTwoFactor(twoFactorCode: string) {
  return api<ITwoFactorCode, IStatusResponse<null>>({
    method: 'post',
    path: '/two-factor/enable',
    data: { twoFactorCode },
  });
}

export function disableTwoFactor(twoFactorCode: string) {
  return api<ITwoFactorCode, IStatusResponse<null>>({
    method: 'post',
    path: '/two-factor/disable',
    data: { twoFactorCode },
  });
}

export function getRankInfo() {
  return api<null, IStatusResponse<IRankInfo>>({ method: 'get', path: '/rank' });
}

export function getRankRules() {
  return api<null, IStatusResponse<IRankRule[]>>({ method: 'get', path: '/rank/rules' });
}

export function getReferralInfo() {
  return api<null, IReferralInfo>({ method: 'get', path: '/referral' });
}

export function getReferralRules() {
  return api<null, IReferralRule[]>({ method: 'get', path: '/referral/rules' });
}

export function getReferralSubLine(username: string) {
  return api<IReferralSubLineData, IStatusResponse<IReferralSubLine[]>>({
    method: 'get',
    path: `/referral/first-line/${username}`,
    data: { username },
  });
}

export function getPromoTurkey() {
  return api<null, IStatusResponse<IPromoTurkeyData>>({ method: 'get', path: '/promo/turkey' });
}

export function applyForPromoTurkey() {
  return api<null, IStatusResponse<IPromoTurkeyData>>({ method: 'post', path: '/promo/turkey' });
}

export function getPromoCappadocia() {
  return api<null, IStatusResponse<IPromoCappadociaData>>({ method: 'get', path: '/promo/kappadokia' });
}

export function applyForPromoCappadocia() {
  return api<null, IStatusResponse<IPromoCappadociaData>>({ method: 'post', path: '/promo/kappadokia' });
}

export function withdraw(amount: number, usdtTrc20Address: string, currency: ECurrency, twoFactorCode?: string) {
  return api<IWithdrawData, IStatusResponse<TTemporaryAny>>({
    method: 'post',
    path: '/withdrawal',
    data: { currency, amount, usdtTrc20Address, twoFactorCode },
  });
}
