import { Options } from 'qr-code-styling';

export const defaultOptions: Options = {
  width: 120,
  height: 120,
  type: 'svg',
  data: 'AUROUS.FINANCE',
  margin: 10,
  qrOptions: {
    typeNumber: 0,
    mode: 'Byte',
    errorCorrectionLevel: 'L',
  },
  dotsOptions: {
    color: '#FFFFFF',
    type: 'rounded',
  },
  backgroundOptions: {
    gradient: {
      type: 'linear',
      rotation: 0,
      colorStops: [
        { offset: 0, color: '#8D6B00' },
        { offset: 1, color: '#EAD177' },
      ],
    },
  },
  cornersSquareOptions: {
    color: '#FFFFFF',
    type: 'extra-rounded',
  },
  cornersDotOptions: {
    color: '#FFFFFF',
    type: 'dot',
  },
};
