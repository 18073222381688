import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import { isFuture } from 'date-fns';
import { useAtom } from 'jotai';
import { memo, useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { FormattedDate } from '@~components/formatted-date/FormattedDate';
import { FormattedNumber } from '@~components/formatted-number/FormattedNumber';
import { tokenBankAtom } from '@~store/account.store';
import type { IGatInvestPlan } from '@~types/types';
import { ECurrency } from '@~types/enums';
import { convertCurrency } from '@~helpers/convertCurrency';

interface IGatInvestPlanProps {
  plansData: IGatInvestPlan[];
}

export const GatInvestPlan = memo(({ plansData }: IGatInvestPlanProps) => {
  const { t } = useTranslation();

  const [tokenBank] = useAtom(tokenBankAtom);

  const [selectedPlan, setSelectedPlan] = useState<IGatInvestPlan>();

  useEffect(() => {
    if (plansData.length > 0) {
      setSelectedPlan(plansData[plansData.length - 1]);
    } else {
      setSelectedPlan(undefined);
    }
  }, [plansData]);

  return (
    <div className="card-block my-3">
      {plansData.length > 1 && selectedPlan && (
        <div>
          <Dropdown align="end">
            <Dropdown.Toggle variant="transparent" className="gat-plan-select notranslate">
              <div className="d-inline-flex gap-4">
                {t('pages.account.staking.content.package', {
                  number: plansData.findIndex((plan) => selectedPlan?.id === plan.id) + 1,
                })}{' '}
                ({t('pages.account.staking.content.until')}{' '}
                <FormattedDate date={selectedPlan.workUntil} dateFormat="dd.MM.yyyy" />)
              </div>
              <FontAwesomeIcon icon={faAngleDown} className="caret" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {plansData.map((gatPlan, index) => (
                <Dropdown.Item
                  key={gatPlan.id}
                  className={selectedPlan?.id === gatPlan.id ? 'active' : ''}
                  onClick={() => setSelectedPlan(gatPlan)}
                >
                  {t('pages.account.staking.content.package', { number: index + 1 })} (
                  {t('pages.account.staking.content.until')}{' '}
                  <FormattedDate date={gatPlan.workUntil} dateFormat="dd.MM.yyyy" />)
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}
      <div className="d-block d-xxl-flex justify-content-between align-items-center align-items-xxl-start align-items-xxxl-center mt-3">
        <div className="d-flex justify-content-center justify-content-md-start flex-column flex-sm-row align-items-center align-items-sm-start gap-4 mb-3 mb-xxxl-0 text-center text-sm-start">
          <div>
            <div className="label tx-uppercase">{t('pages.account.staking.content.invested')}</div>
            <div className="value">
              <FormattedNumber value={selectedPlan?.totalAufInvested || 0} postfix={convertCurrency(ECurrency.AUF)} />
              {selectedPlan && selectedPlan.totalAufInvested > 0 && tokenBank && (
                <FormattedNumber
                  className="d-block tx-normal tx-14 tx-italic"
                  value={selectedPlan.totalAufInvested * tokenBank.aufPrice}
                  suffix="≈$"
                  floor
                />
              )}
            </div>
            <div className="value">
              <FormattedNumber value={selectedPlan?.totalGatInvested || 0} postfix={ECurrency.GAT} />
              {selectedPlan && selectedPlan.totalGatInvested > 0 && tokenBank && (
                <FormattedNumber
                  className="d-block tx-normal tx-14 tx-italic"
                  value={selectedPlan.totalGatInvested * tokenBank.gatPrice}
                  suffix="≈$"
                  floor
                />
              )}
            </div>
            <div className="value">
              <FormattedNumber value={selectedPlan?.totalUsdInvested || 0} postfix={convertCurrency(ECurrency.USD)} />
            </div>
          </div>
          <div>
            <div className="label tx-uppercase">{t('pages.account.staking.content.stake-balance')}</div>
            <div className="value">
              <FormattedNumber value={selectedPlan?.gatInvestmentBody || 0} postfix={ECurrency.GAT} />
              {selectedPlan && selectedPlan.gatInvestmentBody > 0 && tokenBank && (
                <FormattedNumber
                  className="d-block tx-normal tx-14 tx-italic"
                  value={selectedPlan.gatInvestmentBody * tokenBank.gatPrice}
                  suffix="≈$"
                  floor
                />
              )}
            </div>
          </div>
          <div>
            <div className="label tx-uppercase">{t('pages.account.staking.content.paid')}</div>
            <div className="value">
              <FormattedNumber value={selectedPlan?.paidGatPassive || 0} postfix={ECurrency.GAT} />
              {selectedPlan && selectedPlan.paidGatPassive > 0 && tokenBank && (
                <FormattedNumber
                  className="d-block tx-normal tx-14 tx-italic"
                  value={selectedPlan.paidGatPassive * tokenBank.gatPrice}
                  suffix="≈$"
                  floor
                />
              )}
            </div>
          </div>
        </div>
        {selectedPlan && (
          <div className="d-flex justify-content-center justify-content-md-start">
            <div className="d-flex d-xxl-block d-xxxl-flex plate-text px-3 gap-3">
              {isFuture(new Date(selectedPlan.frozenUntil)) ? (
                <>
                  <div className="label tx-uppercase tx-spacing-1">
                    {t('pages.account.staking.content.frozen-until')}
                  </div>
                  <div className="value">
                    <FormattedDate date={selectedPlan.frozenUntil} dateFormat="dd.MM.yyyy" />
                  </div>
                </>
              ) : (
                <>
                  <div className="label tx-uppercase tx-spacing-1">{t('pages.account.staking.content.work-until')}</div>
                  <div className="value">
                    <FormattedDate date={selectedPlan.workUntil} dateFormat="dd.MM.yyyy" />
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
      {selectedPlan && (
        <div className="d-block text-center mt-3">
          {t('pages.account.staking.content.coin-weight-days')}: {selectedPlan.gatWeightDaysCount}
        </div>
      )}
    </div>
  );
});
