import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Quarter } from '@~pages/landing/sections/road-map/elements/quarter/Quarter';

import styles from './RoadMapArrow.module.scss';

export const RoadMapArrow = memo(() => {
  const { t } = useTranslation();

  const listItem = useCallback((list: number, count: number): string[] => {
    const arr = [];

    for (let i = 1; i <= count; i++) {
      arr.push('pages.home.content.road-map.list-' + list + '-item-' + i);
    }

    return arr;
  }, []);

  const lists = [1, 1, 1, 1, 1, 1, 1, 2, 3, 2];

  return (
    <div className={styles.root}>
      <div>
        {lists.map((count, index) => {
          const key = index + 1;
          const quarter = <Quarter index={index} />;

          return (
            <div key={'list-' + key}>
              {quarter}
              <ul className={styles.roadMapList} id={'list-' + key}>
                {listItem(key, count).map((item) => (
                  <li key={'list-' + key + '-item-' + item}>{t(item)}</li>
                ))}
              </ul>
            </div>
          );
        })}
      </div>

      <svg width="1315" height="450" viewBox="0 0 1315 450" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_df_1153_6430)">
          <path
            d="M1211.03 323.232C1212.01 324.209 1212.01 325.791 1211.03 326.768L1195.12 342.678C1194.14 343.654 1192.56 343.654 1191.59 342.678C1190.61 341.701 1190.61 340.118 1191.59 339.142L1205.73 325L1191.59 310.858C1190.61 309.882 1190.61 308.299 1191.59 307.322C1192.56 306.346 1194.14 306.346 1195.12 307.322L1211.03 323.232ZM1317 56.5H149V51.5H1317V56.5ZM20.5 185V194H15.5V185H20.5ZM149 322.5H730.835V327.5H149V322.5ZM730.835 322.5H1209.26V327.5H730.835V322.5ZM20.5 194C20.5 264.969 78.0315 322.5 149 322.5V327.5C75.27 327.5 15.5 267.73 15.5 194H20.5ZM149 56.5C78.0315 56.5 20.5 114.031 20.5 185H15.5C15.5 111.27 75.27 51.5 149 51.5V56.5Z"
            fill="url(#paint0_linear_1153_6430)"
          />
        </g>
        <circle r="8" transform="matrix(-1 0 0 1 1012 54)" fill="white" />
        <circle r="8" transform="matrix(-1 0 0 1 1012 324)" fill="white" />
        <circle r="8" transform="matrix(-1 0 0 1 798 54)" fill="white" />
        <circle r="8" transform="matrix(-1 0 0 1 798 324)" fill="white" />
        <circle r="8" transform="matrix(-1 0 0 1 584 54)" fill="white" />
        <circle r="8" transform="matrix(-1 0 0 1 584 324)" fill="white" />
        <circle r="8" transform="matrix(-1 0 0 1 370 56)" fill="white" />
        <circle r="8" transform="matrix(-1 0 0 1 370 326)" fill="white" />
        <circle r="8" transform="matrix(-1 0 0 1 156 54)" fill="white" />
        <circle r="8" transform="matrix(-1 0 0 1 156 324)" fill="white" />
        <defs>
          <filter
            id="filter0_df_1153_6430"
            x="0.5"
            y="36.5"
            width="1331.5"
            height="321.91"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="7.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.913725 0 0 0 0 0.439216 0 0 0 0 0.772549 0 0 0 0.44 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1153_6430" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1153_6430" result="shape" />
            <feGaussianBlur stdDeviation="1" result="effect2_foregroundBlur_1153_6430" />
          </filter>
          <linearGradient
            id="paint0_linear_1153_6430"
            x1="279.862"
            y1="-12.4999"
            x2="1372.32"
            y2="551.16"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFA115" />
            <stop offset="1" stopColor="#FCD349" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
});
