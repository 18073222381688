import { memo } from 'react';
import { Helmet } from 'react-helmet-async';

import { ParticlesBackground } from '@~components/particles-background/ParticlesBackground';
import { AboutUs } from '@~pages/landing/sections/about-us/AboutUs';
import { AssetsPrice } from '@~pages/landing/sections/assets-price/AssetsPrice';
import { Ecosystem } from '@~pages/landing/sections/ecosystem/Ecosystem';
import { Faq } from '@~pages/landing/sections/faq/Faq';
import { Main } from '@~pages/landing/sections/main/Main';
import { News } from '@~pages/landing/sections/news/News';
import { Partners } from '@~pages/landing/sections/partners/Partners';
import { RoadMap } from '@~pages/landing/sections/road-map/RoadMap';
import { Video } from '@~pages/landing/sections/video/Video';

export const Landing = memo(() => {
  return (
    <>
      <ParticlesBackground />
      <Helmet>
        <title>Aurous.Finance</title>
      </Helmet>
      <div className="page">
        <Main />
        <AssetsPrice />
        <AboutUs />
        <Ecosystem />
        <RoadMap />
        <Video />
        <Partners />
        <News />
        <Faq />
      </div>
    </>
  );
});
