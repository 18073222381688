import { faPen } from '@fortawesome/pro-duotone-svg-icons/faPen';
import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAtom } from 'jotai';
import { memo, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalAlert } from '@~components/modal-alert/ModalAlert';
import { useResponseHandler } from '@~hooks/useResponseHandler';
import { changePassword } from '@~network/account';
import { profileInfoAtom } from '@~store/account.store';
import { digitsRx } from '@~constants';

export const ChangePasswordModal = memo(() => {
  const { t } = useTranslation();

  const [profile] = useAtom(profileInfoAtom);

  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [oneTimePassword, setOneTimePassword] = useState<string>();

  const requestSentRef = useRef(false);

  const handleResponse = useResponseHandler();

  const isValidPasswordsInput = useCallback(() => {
    if (confirmPassword !== newPassword) {
      handleResponse(t('error.passwords-not-match') + '', true);
      return;
    }

    if (password.length < 8 || password.length > 40) {
      handleResponse(t('error.password-length') + '', true);
      return;
    }

    if (newPassword.length < 8 || newPassword.length > 40) {
      handleResponse(t('error.password-length') + '', true);
      return;
    }

    if (profile?.isTwoFactorEnabled) {
      if (!oneTimePassword) {
        handleResponse(t('error.one-time-password-required') + '', true);
        return;
      }

      if (oneTimePassword.length < 6 || !digitsRx.test(oneTimePassword)) {
        handleResponse(t('error.one-time-password-error') + '', true);
        return;
      }
    }

    return true;
  }, [password, newPassword, confirmPassword, profile, oneTimePassword, handleResponse, t]);

  const submitForm = useCallback(() => {
    if (requestSentRef.current) {
      return;
    }

    if (!isValidPasswordsInput()) {
      return;
    }

    requestSentRef.current = true;
    setLoading(true);

    changePassword(password, newPassword, oneTimePassword)
      .then((response) => {
        if (response.success) {
          setOneTimePassword(undefined);
          handleResponse(t('pages.account.profile.content.security.password.password-changed') + '', false);
          setOpenModal(false);
        } else {
          handleResponse(response);
        }
      })
      .catch((response) => {
        handleResponse(response.response.data);
      })
      .finally(() => {
        requestSentRef.current = false;
        setLoading(false);
      });
  }, [isValidPasswordsInput, handleResponse, password, newPassword, oneTimePassword, t]);

  const handleModalAction = useCallback(
    (success: boolean) => {
      if (!success) {
        setOpenModal(false);
        return;
      }

      submitForm();
    },
    [submitForm]
  );

  return (
    <>
      <button className="btn btn-link tx-primary py-1 px-2" onClick={() => setOpenModal(true)}>
        <FontAwesomeIcon icon={faPen} />
      </button>

      <ModalAlert
        key="modal-for-change-password"
        proceed={handleModalAction}
        show={openModal}
        title={t('pages.account.profile.content.security.password.modal-title') + ''}
        okLabel={t('pages.account.profile.content.security.password.change-password') + ''}
        enableEscape
        loading={isLoading}
        content={
          <div className="modal-content-bg mb-3">
            <div className="form-group mb-3">
              <div className="ms-3 tx-18 mb-1">
                {t('pages.account.profile.content.security.password.current-password')}
              </div>
              <div className="input-group">
                <input
                  type={showPassword ? 'text' : 'password'}
                  className="form-control"
                  placeholder={t('pages.account.profile.content.security.password.current-password') + ''}
                  value={password}
                  id="password"
                  onChange={(event) => setPassword(event.target.value)}
                  disabled={isLoading}
                />
                <div className="input-group-text">
                  <FontAwesomeIcon
                    icon={showPassword ? faEye : faEyeSlash}
                    role="button"
                    onClick={() => setShowPassword((prevState) => !prevState)}
                  />
                </div>
              </div>
            </div>

            <div className="form-group mb-3">
              <div className="ms-3 tx-18 mb-1">{t('pages.account.profile.content.security.password.new-password')}</div>
              <div className="input-group">
                <input
                  type={showNewPassword ? 'text' : 'password'}
                  className="form-control"
                  placeholder={t('pages.account.profile.content.security.password.new-password') + ''}
                  value={newPassword}
                  id="new-password"
                  onChange={(event) => setNewPassword(event.target.value)}
                  disabled={isLoading}
                />
                <div className="input-group-text">
                  <FontAwesomeIcon
                    icon={showNewPassword ? faEye : faEyeSlash}
                    role="button"
                    onClick={() => setShowNewPassword((prevState) => !prevState)}
                  />
                </div>
              </div>
            </div>

            <div className="form-group mb-3">
              <div className="ms-3 tx-18 mb-1">
                {t('pages.account.profile.content.security.password.confirm-password')}
              </div>
              <div className="input-group">
                <input
                  type={showNewPassword ? 'text' : 'password'}
                  className="form-control"
                  placeholder={t('pages.account.profile.content.security.password.confirm-password') + ''}
                  value={confirmPassword}
                  id="confirm-new-password"
                  onChange={(event) => setConfirmPassword(event.target.value)}
                  disabled={isLoading}
                />
                <div className="input-group-text">
                  <FontAwesomeIcon
                    icon={showNewPassword ? faEye : faEyeSlash}
                    role="button"
                    onClick={() => setShowNewPassword((prevState) => !prevState)}
                  />
                </div>
              </div>
            </div>

            {profile?.isTwoFactorEnabled && (
              <div className="form-group mt-6 mb-3 text-center">
                <div className="tx-18 mb-1">{t('pages.login.content.2fa.password-label')}</div>
                <input
                  id="2fa-password"
                  type="text"
                  className="form-control one-time-password-input"
                  placeholder={t('pages.login.content.2fa.password-placeholder') + ''}
                  defaultValue={oneTimePassword}
                  maxLength={6}
                  onChange={(event) => setOneTimePassword(event.target.value)}
                />
                <div className="tx-gold">{t('pages.login.content.2fa.modal-subheader')}</div>
              </div>
            )}
          </div>
        }
      />
    </>
  );
});
