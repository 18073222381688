import { faArrowUpRightFromSquare, faLink, faLinkSlash } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAtom } from 'jotai';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CopyButton } from '@~components/copy-button/CopyButton';
import { ModalAlert } from '@~components/modal-alert/ModalAlert';
import { Preloader } from '@~components/preloader/Preloader';
import { Translate } from '@~components/translate/Translate';
import { digitsRx } from '@~constants';
import { config } from '@~config';
import { useStateUpdate } from '@~context/StateContext';
import { useResponseHandler } from '@~hooks/useResponseHandler';
import { disconnectFromTelegram, generateTgToken, getTgToken } from '@~network/account';
import { profileInfoAtom } from '@~store/account.store';
import type { IProfile } from '@~types/types';

interface IManageTelegramModalProps {
  profileInfo: IProfile;
}

export const ManageTelegramModal = memo(({ profileInfo }: IManageTelegramModalProps) => {
  const { t } = useTranslation();

  const [profile] = useAtom(profileInfoAtom);

  const [tgToken, setTgToken] = useState('');
  const [openConnectModal, setOpenConnectModal] = useState(false);
  const [openDisconnectModal, setOpenDisconnectModal] = useState(false);
  const [isTokenLoading, setTokenLoading] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [oneTimePassword, setOneTimePassword] = useState<string>();

  const requestSentRef = useRef(false);
  const tokenRequestSentRef = useRef(false);

  const { fetchProfileInfo } = useStateUpdate();
  const handleResponse = useResponseHandler();

  const requestTelegramToken = useCallback(() => {
    if (tokenRequestSentRef.current) {
      return;
    }

    tokenRequestSentRef.current = true;
    setTokenLoading(true);
    generateTgToken(oneTimePassword)
      .then((response) => {
        if (response.success) {
          setTgToken(response?.data?.token || '');
          fetchProfileInfo();
          setOneTimePassword(undefined);
        } else {
          handleResponse(response);
        }
      })
      .catch((response) => {
        handleResponse(response.response.data);
      })
      .finally(() => {
        setTokenLoading(false);
        tokenRequestSentRef.current = false;
      });
  }, [handleResponse, fetchProfileInfo, oneTimePassword]);

  const requestTelegramDisconnect = useCallback(() => {
    if (requestSentRef.current) {
      return;
    }

    requestSentRef.current = true;
    setLoading(true);
    disconnectFromTelegram(oneTimePassword)
      .then((response) => {
        if (response.success) {
          handleResponse(t('pages.account.profile.content.tg.disconnect-modal.success') + '', false);
          if (response.data) {
            fetchProfileInfo();
            setOpenDisconnectModal(false);
            setTgToken('');
            setOneTimePassword(undefined);
          }
        } else {
          handleResponse(response);
        }
      })
      .catch((response) => {
        handleResponse(response.response.data);
      })
      .finally(() => {
        setLoading(false);
        requestSentRef.current = false;
      });
  }, [handleResponse, fetchProfileInfo, oneTimePassword, t]);

  const handleConnectModalAction = useCallback(
    (success: boolean) => {
      if (!success) {
        fetchProfileInfo();
        setOpenConnectModal(false);
        return;
      }

      if (profile?.isTwoFactorEnabled) {
        if (!oneTimePassword) {
          handleResponse(t('error.one-time-password-required') + '', true);
          return;
        }

        if (oneTimePassword.length < 6 || !digitsRx.test(oneTimePassword)) {
          handleResponse(t('error.one-time-password-error') + '', true);
          return;
        }
      }

      requestTelegramToken();
    },
    [requestTelegramToken, profile, oneTimePassword, fetchProfileInfo, handleResponse, t]
  );

  const handleDisconnectModalAction = useCallback(
    (success: boolean) => {
      if (!success) {
        setOpenDisconnectModal(false);
        return;
      }

      if (profile?.isTwoFactorEnabled) {
        if (!oneTimePassword) {
          handleResponse(t('error.one-time-password-required') + '', true);
          return;
        }

        if (oneTimePassword.length < 6 || !digitsRx.test(oneTimePassword)) {
          handleResponse(t('error.one-time-password-error') + '', true);
          return;
        }
      }

      requestTelegramDisconnect();
    },
    [requestTelegramDisconnect, profile, oneTimePassword, handleResponse, t]
  );

  useEffect(() => {
    if (!profileInfo.telegramEnabled && openConnectModal) {
      setTokenLoading(true);
      getTgToken()
        .then((response) => {
          if (response.success) {
            setTgToken(response.data?.token || '');
          } else {
            handleResponse(response);
          }
        })
        .catch((response) => {
          handleResponse(response.response.data);
        })
        .finally(() => setTokenLoading(false));
    }
  }, [openConnectModal, profileInfo, handleResponse]);

  return (
    <>
      {!profileInfo.telegramEnabled && (
        <button
          className="btn btn-link tx-primary py-1 px-2"
          onClick={() => setOpenConnectModal(true)}
          title={t('pages.account.profile.content.tg.tg-connect') + ''}
        >
          <FontAwesomeIcon icon={faLink} />
        </button>
      )}
      {profileInfo.telegramEnabled && (
        <button
          className="btn btn-link tx-primary py-1 px-2"
          onClick={() => setOpenDisconnectModal(true)}
          title={t('pages.account.profile.content.tg.tg-disconnect') + ''}
        >
          <FontAwesomeIcon icon={faLinkSlash} />
        </button>
      )}

      <ModalAlert
        key="modal-for-connect-tg"
        proceed={handleConnectModalAction}
        show={openConnectModal}
        title={t('pages.account.profile.content.tg.modal-title-connect') + ''}
        okLabel={t('pages.account.profile.content.tg.get-tg-code') + ''}
        okIcon={faLink}
        cancelLabel={t('common.action.close') + ''}
        noOk={!!tgToken}
        size="lg"
        loading={isTokenLoading}
        enableEscape
        content={
          <div className="modal-content-bg mb-3">
            {isTokenLoading && (
              <div className="value">
                <Preloader inline />
              </div>
            )}
            {!isTokenLoading && !tgToken && (
              <>
                <div className="tx-18 mb-1 text-center">
                  <Translate i18nKey="pages.account.profile.content.tg.init-description" />
                </div>
                {profile?.isTwoFactorEnabled && (
                  <div className="form-group mt-6 mb-3 text-center">
                    <div className="tx-18 mb-1">{t('pages.login.content.2fa.password-label')}</div>
                    <input
                      id="2fa-password-2"
                      type="text"
                      className="form-control one-time-password-input"
                      placeholder={t('pages.login.content.2fa.password-placeholder') + ''}
                      defaultValue={oneTimePassword}
                      maxLength={6}
                      onChange={(event) => setOneTimePassword(event.target.value)}
                    />
                    <div className="tx-gold">{t('pages.login.content.2fa.modal-subheader')}</div>
                  </div>
                )}
              </>
            )}
            {!isTokenLoading && tgToken && (
              <div className="form-group mb-0">
                <div className="tx-18 mb-1 text-center">
                  <div className="tx-spacing-1">{t('pages.account.profile.content.tg.tg-code')}</div>
                  <CopyButton
                    text={tgToken}
                    className="btn btn-black tx-primary text-wrap my-2"
                    iconClass="tx-primary ml-2"
                  />
                  <div className="mb-4">{t('pages.account.profile.content.tg.tg-description')}</div>
                  <a className="btn btn-black tx-primary" href={config.tgBotUrl} rel="noreferrer" target="_blank">
                    <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="tx-primary me-2" />
                    {t('pages.account.profile.content.tg.open-tg-bot')}
                  </a>
                </div>
              </div>
            )}
          </div>
        }
      />

      <ModalAlert
        key="modal-for-disconnect-tg"
        proceed={handleDisconnectModalAction}
        show={openDisconnectModal}
        title={t('pages.account.profile.content.tg.disconnect-modal.title') + ''}
        okLabel={t('pages.account.profile.content.tg.disconnect-modal.confirm') + ''}
        okIcon={faLinkSlash}
        okVariant="warning"
        cancelLabel={t('common.action.close') + ''}
        size="lg"
        loading={isLoading}
        enableEscape
        content={
          <div className="modal-content-bg mb-3">
            <div className="tx-18 mb-1 text-center">
              <Translate i18nKey="pages.account.profile.content.tg.disconnect-modal.confirmation" />
            </div>
            {profile?.isTwoFactorEnabled && (
              <div className="form-group mt-6 mb-3 text-center">
                <div className="tx-18 mb-1">{t('pages.login.content.2fa.password-label')}</div>
                <input
                  id="2fa-password-1"
                  type="text"
                  className="form-control one-time-password-input"
                  placeholder={t('pages.login.content.2fa.password-placeholder') + ''}
                  defaultValue={oneTimePassword}
                  maxLength={6}
                  onChange={(event) => setOneTimePassword(event.target.value)}
                />
                <div className="tx-gold">{t('pages.login.content.2fa.modal-subheader')}</div>
              </div>
            )}
          </div>
        }
      />
    </>
  );
});
