import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faTable, faThList } from '@fortawesome/pro-regular-svg-icons';
import { memo, useCallback, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'react-bootstrap';

import { ISwitcherOption, RadioSwitcher } from '@~components/radio-switcher/RadioSwitcher';
import { TransactionTable } from '@~components/transactions-table/TransactionTable';
import { typesFilterMap } from '@~pages/account/history/constants';
import { EHistoryFilter, ETableView, ETransactionType } from '@~types/enums';
import type { ITableViewIcon } from '@~types/types';

const tableViews: ITableViewIcon[] = [
  { view: ETableView.TABLE, icon: faTable, translation: 'components.table.table-view' },
  { view: ETableView.LIST, icon: faThList, translation: 'components.table.cards-view' },
];

export const HistoryPage = memo(() => {
  const { t } = useTranslation();

  const [tableView, setTableView] = useState(ETableView.TABLE);
  const [dataFilter, setDataFilter] = useState(EHistoryFilter.ALL);

  const tableViewsOptions: ISwitcherOption<ETableView>[] = useMemo(
    () =>
      tableViews.map((viewIcon) => ({
        value: viewIcon.view,
        label: <FontAwesomeIcon icon={viewIcon.icon} title={t(viewIcon.translation) + ''} />,
      })),
    [t]
  );

  const handleViewChange = useCallback((value: ETableView) => {
    setTableView(value);
  }, []);

  const transactionTypes = useMemo(() => {
    const types: ETransactionType[] = [];
    if (dataFilter !== EHistoryFilter.ALL) {
      types.push(...typesFilterMap[dataFilter]);
    }
    return types;
  }, [dataFilter]);

  return (
    <>
      <Helmet>
        <title>Aurous | {t('pages.account.operations.title')}</title>
      </Helmet>
      <div id="history">
        <h2 className="heading d-flex justify-content-between align-items-center mb-6">
          <div className="d-flex justify-content-start align-items-center gap-4">
            <div className="d-none d-lg-inline-flex">
              <RadioSwitcher<ETableView>
                id="table-view"
                options={tableViewsOptions}
                handleValueChange={handleViewChange}
                selectedValue={tableView}
                onlyIcons={true}
              />
            </div>
            <div className="tx-uppercase">{t('pages.account.operations.content.heading')}</div>
          </div>
          <Dropdown align="end">
            <Dropdown.Toggle variant="transparent" className="filter-select notranslate">
              {t(`pages.account.operations.content.filter.${dataFilter}`)}{' '}
              <FontAwesomeIcon icon={faAngleDown} className="caret" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {Object.values(EHistoryFilter).map((filter) => (
                <Dropdown.Item
                  key={filter}
                  className={filter === dataFilter ? 'active' : ''}
                  onClick={() => {
                    setDataFilter(filter);
                  }}
                >
                  {t(`pages.account.operations.content.filter.${filter}`)}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </h2>
        <TransactionTable tableView={tableView} transactionTypes={transactionTypes} />
      </div>
    </>
  );
});
