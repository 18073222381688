import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import ar from 'locales/ar.json';
import cn from 'locales/cn.json';
import de from 'locales/de.json';
import en from 'locales/en.json';
import es from 'locales/es.json';
import fr from 'locales/fr.json';
import hi from 'locales/hi.json';
import id from 'locales/id.json';
import it from 'locales/it.json';
import jp from 'locales/jp.json';
import kr from 'locales/kr.json';
import ph from 'locales/ph.json';
import pt from 'locales/pt.json';
import ru from 'locales/ru.json';
import th from 'locales/th.json';
import tr from 'locales/tr.json';
import vn from 'locales/vn.json';

import { ELanguage } from '@~types/enums';

const resources = {
  ar: {
    translation: ar,
  },
  cn: {
    translation: cn,
  },
  de: {
    translation: de,
  },
  en: {
    translation: en,
  },
  es: {
    translation: es,
  },
  fr: {
    translation: fr,
  },
  hi: {
    translation: hi,
  },
  id: {
    translation: id,
  },
  it: {
    translation: it,
  },
  jp: {
    translation: jp,
  },
  kr: {
    translation: kr,
  },
  ph: {
    translation: ph,
  },
  pt: {
    translation: pt,
  },
  ru: {
    translation: ru,
  },
  th: {
    translation: th,
  },
  tr: {
    translation: tr,
  },
  vn: {
    translation: vn,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: ELanguage.EN,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

document.documentElement.lang = i18n.language;

i18n.on('languageChanged', (lng) => {
  document.documentElement.setAttribute('lang', lng);
});

export default i18n;
