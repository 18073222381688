import classNames from 'classnames';
import { useAtom } from 'jotai';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { Navigate, useParams } from 'react-router-dom';

import { ParticlesBackground } from '@~components/particles-background/ParticlesBackground';
import { Preloader } from '@~components/preloader/Preloader';
import { newsList } from '@~pages/landing/sections/news/data/news';
import { NewsView } from '@~pages/news/components/news-view/NewsView';
import { selectedLanguageAtom } from '@~store/app.store';
import { ELandingLink } from '@~types/navigationEnums';
import type { INews } from '@~types/types';
import { ELanguage } from '@~types/enums';

import styles from './NewsPage.module.scss';

export const NewsPage = memo(() => {
  const { t } = useTranslation();
  const [selectedLanguage] = useAtom(selectedLanguageAtom);

  const { slug } = useParams();

  const [news, setNews] = useState<INews>();
  const [notFound, setNotFound] = useState<boolean>(false);

  useEffect(() => {
    if (slug) {
      const newsLanguage = selectedLanguage === ELanguage.RU ? ELanguage.RU : ELanguage.EN;
      const found = newsList.filter(({ lang }) => lang === newsLanguage).find((n) => n.slug === slug);

      if (found) {
        setNews(found);
      } else {
        setNotFound(true);
      }
    } else if (!slug) {
      setNotFound(true);
    }
  }, [slug, selectedLanguage]);

  if (notFound) {
    return <Navigate replace to={ELandingLink.NOT_FOUND} />;
  }

  return (
    <>
      <ParticlesBackground />
      <Helmet>
        <title>Aurous | {news?.title || t('pages.home.content.news.title')}</title>
      </Helmet>
      <div id="news" className={classNames(styles.root, 'page flex-1 p-4 p-md-5 pt-navbar')}>
        {!news && <Preloader inline={true} />}
        {news && <NewsView item={news} />}
      </div>
    </>
  );
});
