import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { FormattedNumber } from '@~components/formatted-number/FormattedNumber';
import { ECurrency } from '@~types/enums';
import type { IEthiInvestPlan } from '@~types/types';
import { convertCurrency } from '@~helpers/convertCurrency';

interface IEthiInvestPlanProps {
  planData?: IEthiInvestPlan;
}

export const EthiInvestPlan = memo(({ planData }: IEthiInvestPlanProps) => {
  const { t } = useTranslation();

  const activePackage = planData?.planPackage;

  if (!planData) {
    return null;
  }

  const workedDaysCount = planData.planPackage !== null ? planData.workedDaysCount : null;

  return (
    <div className="card-block d-block d-xxl-flex justify-content-between align-items-center align-items-xxl-start align-items-xxxl-center my-3">
      <div className="d-flex justify-content-center justify-content-md-start flex-column flex-sm-row align-items-center align-items-sm-start gap-4 mb-3 mb-xxxl-0 text-center text-sm-start">
        <div>
          <div className="label tx-uppercase tx-spacing-1">{t('pages.account.staking.content.invested')}</div>
          <div className="value">
            <FormattedNumber value={activePackage?.price ?? 0} postfix={convertCurrency(ECurrency.USD)} />
          </div>
        </div>
        <div>
          <div className="label tx-uppercase tx-spacing-1">{t('pages.account.staking.content.available-to-claim')}</div>
          <div className="value">
            <FormattedNumber value={planData.availableToClaim} postfix={convertCurrency(ECurrency.USD)} />
          </div>
        </div>
        <div>
          <div className="label tx-uppercase tx-spacing-1">{t('pages.account.staking.content.charged')}</div>
          <div className="value">
            <FormattedNumber value={planData.paidPassive} postfix={convertCurrency(ECurrency.USD)} />
          </div>
        </div>
        <div>
          <div className="label tx-uppercase tx-spacing-1">
            {t('pages.account.staking.content.ethi-plan.daily-percent')}
          </div>
          <div className="value">
            <FormattedNumber value={planData.dailyPercent} postfix="%" />
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center justify-content-md-start">
        {workedDaysCount !== null && activePackage ? (
          <div className="pl-0 pl-xxl-3 gap-3 flex-1 flex-xxl-0">
            <div className="label tx-uppercase tx-spacing-1 text-center text-md-start">
              {t('pages.account.staking.content.ethi-plan.payments-amount')}
            </div>
            <div className="value mt-2">
              <div className="progress d-flex">
                <div
                  className="progress-bar"
                  role="progressbar"
                  aria-valuenow={(workedDaysCount / activePackage.payoutCount) * 100}
                  aria-valuemin={0}
                  aria-valuemax={activePackage.payoutCount}
                  style={{ width: `${(workedDaysCount / activePackage.payoutCount) * 100}%` }}
                />
                <div className="progress-text tx-16">
                  {workedDaysCount} {t('pages.account.staking.content.ethi-plan.from')} {activePackage.payoutCount}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex d-xxl-block d-xxxl-flex plate-text px-3 gap-3">
            <div className="label tx-uppercase tx-spacing-1">{t('pages.account.staking.content.paid')}</div>
            <div className="value">
              <FormattedNumber value={planData.paidPassive} postfix={convertCurrency(ECurrency.USD)} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
});
