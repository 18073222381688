import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus';
import { memo, useContext } from 'react';
import { AccordionContext, useAccordionButton } from 'react-bootstrap';

interface IFaqToggleProps {
  children: string | JSX.Element;
  eventKey: string;
  callback?: (eventKey: string) => void;
}

export const FaqToggle = memo(({ children, eventKey, callback }: IFaqToggleProps) => {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey));

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <button className="btn btn-link accordion-link" onClick={decoratedOnClick}>
      <div className="d-flex justify-content-between tx-left">
        {children}
        <FontAwesomeIcon icon={faPlus} transform={{ rotate: isCurrentEventKey ? 45 : 0.1 }} />
      </div>
    </button>
  );
});
