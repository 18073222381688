import { useState } from 'react';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { useTranslation } from 'react-i18next';

import { ModalAlert } from '@~components/modal-alert/ModalAlert';

interface IPdfFileProps {
  name: string;
  fileUri: string;
}

export const PdfFile = ({ name, fileUri }: IPdfFileProps) => {
  const { t } = useTranslation();

  const [openPdfModal, setOpenPdfModal] = useState(false);

  return (
    <>
      <button className="btn btn-link p-0" onClick={() => setOpenPdfModal(true)}>
        {name}
      </button>

      <ModalAlert
        key={`modal-for-${name}`}
        proceed={setOpenPdfModal}
        show={openPdfModal}
        title={name}
        cancelLabel={t('common.action.close') + ''}
        size="xl"
        noOk
        enableEscape
        content={<DocViewer pluginRenderers={DocViewerRenderers} documents={[{ uri: fileUri }]} />}
      />
    </>
  );
};
