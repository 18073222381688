import { ECurrency } from '@~types/enums';

const currencyMap: Record<ECurrency, string> = {
  [ECurrency.USD]: 'USDT',
  [ECurrency.AUF]: 'AFT',
  [ECurrency.GAT]: 'GAT',
  [ECurrency.ETHI_USD]: 'USDT',
  [ECurrency.SWAP_USD]: 'USDT',
};

export function convertCurrency(currency: ECurrency) {
  return currencyMap[currency];
}
