import { faPenToSquare } from '@fortawesome/pro-duotone-svg-icons/faPenToSquare';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAtom } from 'jotai';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalAlert } from '@~components/modal-alert/ModalAlert';
import { refIdRx } from '@~constants';
import { useResponseHandler } from '@~hooks/useResponseHandler';
import { updateProfileInfo } from '@~network/account';
import type { IProfile } from '@~types/types';
import { profileInfoAtom } from '@~store/account.store';

interface IEditRefIdModalProps {
  profileInfo: IProfile;
}

export const EditRefIdModal = memo(({ profileInfo }: IEditRefIdModalProps) => {
  const { t } = useTranslation();

  const [, setProfileInfo] = useAtom(profileInfoAtom);

  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [userRefId, setUserRefId] = useState('');

  const requestSentRef = useRef(false);

  const handleResponse = useResponseHandler();

  const isValidRefIdInput = useCallback(() => {
    if (userRefId.length < 6 || userRefId.length > 10) {
      handleResponse(t('error.referral-id-length') + '', true);
      return false;
    }

    if (!refIdRx.test(userRefId)) {
      handleResponse(t('error.wrong-referral-id') + '', true);
      return false;
    }

    return true;
  }, [userRefId, handleResponse, t]);

  const submitForm = useCallback(() => {
    if (requestSentRef.current) {
      return;
    }

    if (!isValidRefIdInput()) {
      return;
    }

    requestSentRef.current = true;
    setLoading(true);

    updateProfileInfo({
      username: profileInfo.username,
      name: profileInfo.name,
      surname: profileInfo.surname,
      ownRefId: userRefId,
    })
      .then((response) => {
        if (response.success) {
          handleResponse(t('pages.account.profile.content.referral.ref-id-update-success') + '', false);
          setProfileInfo(response.data);
          setOpenModal(false);
        } else {
          handleResponse(response);
        }
      })
      .catch((response) => {
        handleResponse(response.response.data);
      })
      .finally(() => {
        requestSentRef.current = false;
        setLoading(false);
      });
  }, [isValidRefIdInput, setProfileInfo, profileInfo, handleResponse, userRefId, t]);

  const handleModalAction = useCallback(
    (success: boolean) => {
      if (!success) {
        setOpenModal(false);
        return;
      }

      submitForm();
    },
    [submitForm]
  );

  useEffect(() => {
    setUserRefId(profileInfo.ownRefId);
  }, [openModal, profileInfo]);

  return (
    <>
      <button className="btn btn-link tx-primary py-1 px-2" onClick={() => setOpenModal(true)}>
        <FontAwesomeIcon icon={faPenToSquare} />
      </button>

      <ModalAlert
        key="modal-for-edit-ref-id"
        proceed={handleModalAction}
        show={openModal}
        title={t('pages.account.profile.content.referral.modal-title') + ''}
        okLabel={t('common.action.save') + ''}
        enableEscape
        loading={isLoading}
        content={
          <div className="modal-content-bg mb-3">
            <div className="form-group mb-0">
              <div className="ms-3 tx-18 mb-1">{t('pages.account.profile.content.referral.user-ref-id-label')}</div>
              <input
                type="text"
                className="form-control form-control-lg mb-2"
                placeholder={t('pages.account.profile.content.referral.user-ref-id-placeholder') + ''}
                value={userRefId}
                id="ownRefId"
                onChange={(event) => setUserRefId(event.target.value)}
                disabled={isLoading}
              />
            </div>
          </div>
        }
      />
    </>
  );
});
