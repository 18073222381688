import { faSackDollar } from '@fortawesome/pro-duotone-svg-icons/faSackDollar';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BigNumber from 'bignumber.js';
import { useAtom } from 'jotai';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownButton, Form, InputGroup } from 'react-bootstrap';

import { FormattedNumber } from '@~components/formatted-number/FormattedNumber';
import { IconWithLoading } from '@~components/icon-with-loading/IconWithLoading';
import { confirmAlert } from '@~components/modal-alert/corfirmAlert';
import { Preloader } from '@~components/preloader/Preloader';
import { ISwitcherOption, RadioSwitcher } from '@~components/radio-switcher/RadioSwitcher';
import { Translate } from '@~components/translate/Translate';
import { useStateUpdate } from '@~context/StateContext';
import { mapCurrency } from '@~helpers/mapCurrency';
import { useResponseHandler } from '@~hooks/useResponseHandler';
import {
  getEthiInvestPlan,
  getGatInvestPlans,
  getInvestPlan,
  getUsdInvestPlan,
  invest,
  investGat,
  investUsd,
} from '@~network/account';
import { SwapInvestPlan } from '@~pages/account/staking/plans/SwapInvestPlan';
import { AftInvestPlan } from '@~pages/account/staking/plans/AftInvestPlan';
import { GatInvestPlan } from '@~pages/account/staking/plans/GatInvestPlan';
import { EarlyWithdrawalModal } from '@~pages/account/staking/modals/EarlyWithdrawalModal';
import { EthiEarlyWithdrawalModal } from '@~pages/account/staking/modals/EthiEarlyWithdrawalModal';
import { AftPlanInfoModal } from '@~pages/account/staking/modals/AftPlanInfoModal';
import { GatPlanInfoModal } from '@~pages/account/staking/modals/GatPlanInfoModal';
import { SwapPlanInfoModal } from '@~pages/account/staking/modals/SwapPlanInfoModal';
import { walletsAtom } from '@~store/account.store';
import { ECurrency, EInvestPlan, EInvestPlanStatus } from '@~types/enums';
import type { IEthiInvestPlan, IGatInvestPlan, IInvestPlan, IUsdInvestPlan } from '@~types/types';
import { EthiPlanInfoModal } from '@~pages/account/staking/modals/EthiPlanInfoModal';
import { EthiInvestPlan } from '@~pages/account/staking/plans/EthiInvestPlan';
import { EthiInvestBlock } from '@~pages/account/staking/components/EthiInvestBlock';
import { convertCurrency } from '@~helpers/convertCurrency';

const availablePlans = [EInvestPlan.ETHI, EInvestPlan.AFT, EInvestPlan.GAT, EInvestPlan.SWAP];

interface IPlanData {
  id: string;
  label: string;
  langKey: string;
  investTokens: ECurrency[];
}

const investPlanMap: Record<EInvestPlan, IPlanData> = {
  [EInvestPlan.ETHI]: {
    id: 'ethi',
    label: 'ETHI',
    langKey: 'ethi-plan',
    investTokens: [ECurrency.USD, ECurrency.ETHI_USD],
  },
  [EInvestPlan.SWAP]: {
    id: 'swap',
    label: 'SWAP',
    langKey: 'swap-plan',
    investTokens: [ECurrency.USD, ECurrency.SWAP_USD],
  },
  [EInvestPlan.AFT]: {
    id: 'aft',
    label: 'AFT',
    langKey: 'aft-plan',
    investTokens: [ECurrency.USD, ECurrency.AUF],
  },
  [ECurrency.GAT]: {
    id: 'gat',
    label: 'GAT',
    langKey: 'gat-plan',
    investTokens: [ECurrency.USD, ECurrency.AUF, ECurrency.GAT],
  },
};

export const StakingPage = memo(() => {
  const { t } = useTranslation();

  const [wallets] = useAtom(walletsAtom);

  const [selectedPlan, setSelectedPlan] = useState<EInvestPlan | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isInvestLoading, setInvestLoading] = useState(false);
  const [investAmount, setInvestAmount] = useState<number>();
  const [investToken, setInvestToken] = useState<ECurrency>();
  const [investMaxAmount, setInvestMaxAmount] = useState<number>(0);

  const [investPlanAft, setInvestPlanAft] = useState<IInvestPlan>();
  const [investPlansGat, setInvestPlansGat] = useState<IGatInvestPlan[]>([]);
  const [investPlanSwap, setInvestPlanSwap] = useState<IUsdInvestPlan>();
  const [investPlanEthi, setInvestPlanEthi] = useState<IEthiInvestPlan>();

  const sentForAftPlanRef = useRef(false);
  const sentForGatPlanRef = useRef(false);
  const sentForSwapPlanRef = useRef(false);
  const sentForEthiPlanRef = useRef(false);
  const sentInvestRequestRef = useRef(false);

  const { fetchWallets, fetchTokenBank } = useStateUpdate();
  const handleResponse = useResponseHandler();

  const filteredPlans = useMemo(() => {
    if (!investPlanSwap || investPlanSwap.status === EInvestPlanStatus.INACTIVE) {
      return availablePlans.slice(0, 3);
    }
    return availablePlans;
  }, [investPlanSwap]);

  const options: ISwitcherOption<EInvestPlan>[] = useMemo(
    () => filteredPlans.map((investPlan) => ({ value: investPlan, label: investPlanMap[investPlan].label })),
    [filteredPlans]
  );

  const handleValueChange = useCallback((value: EInvestPlan) => {
    setSelectedPlan(value);
  }, []);

  const fetchAftInvestPlan = useCallback(() => {
    setIsLoading(true);
    getInvestPlan()
      .then((response) => setInvestPlanAft(response))
      .catch((response) => {
        handleResponse(response.response.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [handleResponse]);

  const fetchGatInvestPlan = useCallback(() => {
    setIsLoading(true);
    getGatInvestPlans()
      .then((response) => setInvestPlansGat(response))
      .catch((response) => {
        handleResponse(response.response.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [handleResponse]);

  const fetchSwapInvestPlan = useCallback(() => {
    setIsLoading(true);
    getUsdInvestPlan()
      .then((response) => setInvestPlanSwap(response))
      .catch((response) => {
        handleResponse(response.response.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [handleResponse]);

  const fetchEthiInvestPlan = useCallback(() => {
    setIsLoading(true);
    getEthiInvestPlan()
      .then((response) => setInvestPlanEthi(response))
      .catch((response) => {
        handleResponse(response.response.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [handleResponse]);

  useEffect(() => {
    if (selectedPlan !== EInvestPlan.AFT || sentForAftPlanRef.current) {
      return;
    }
    sentForAftPlanRef.current = true;
    fetchAftInvestPlan();
  }, [selectedPlan, fetchAftInvestPlan]);

  useEffect(() => {
    if (selectedPlan !== EInvestPlan.GAT || sentForGatPlanRef.current) {
      return;
    }
    sentForGatPlanRef.current = true;
    fetchGatInvestPlan();
  }, [selectedPlan, fetchGatInvestPlan]);

  // Get info about SWAP plan, when page just loaded
  useEffect(() => {
    if (sentForSwapPlanRef.current) {
      return;
    }
    sentForSwapPlanRef.current = true;
    fetchSwapInvestPlan();
  }, [fetchSwapInvestPlan]);

  useEffect(() => {
    if (selectedPlan !== EInvestPlan.SWAP || sentForSwapPlanRef.current) {
      return;
    }
    sentForSwapPlanRef.current = true;
    fetchSwapInvestPlan();
  }, [selectedPlan, fetchSwapInvestPlan]);

  useEffect(() => {
    if (selectedPlan !== EInvestPlan.ETHI || sentForEthiPlanRef.current) {
      return;
    }
    sentForEthiPlanRef.current = true;
    fetchEthiInvestPlan();
  }, [selectedPlan, fetchEthiInvestPlan]);

  useEffect(() => {
    setSelectedPlan(filteredPlans[0]);
  }, [filteredPlans]);

  useEffect(() => {
    if (selectedPlan) {
      setInvestToken(investPlanMap[selectedPlan].investTokens[0]);
    }
  }, [selectedPlan]);

  useEffect(() => {
    let investMaxAmountValue = 0;
    if (investToken && wallets.length > 0) {
      const foundWallet = wallets.find(({ currency }) => currency === investToken);
      if (foundWallet) {
        investMaxAmountValue = new BigNumber(foundWallet.balance).decimalPlaces(2, 1).toNumber();
      }
    }
    setInvestAmount(undefined);
    setInvestMaxAmount(investMaxAmountValue);
  }, [investToken, wallets]);

  const handleInvest = useCallback(() => {
    if (sentInvestRequestRef.current || wallets.length === 0) {
      return;
    }

    const investTokenWallet = wallets.find(({ currency }) => currency === investToken);
    if (!investTokenWallet) {
      return;
    }

    if (!investAmount || investAmount <= 0) {
      handleResponse(t('error.wrong-invest-amount', { currency: selectedPlan }) + '', true);
      return;
    }

    sentInvestRequestRef.current = true;

    confirmAlert({
      title: t('pages.account.staking.content.invest') + '',
      okLabel: t('pages.account.staking.content.invest') + '',
      okVariant: 'success',
      content: (
        <div className="modal-content-bg">
          <Translate
            i18nKey={'pages.account.staking.content.invest-confirmation'}
            values={{
              amount: investAmount.toLocaleString(),
              currency: investToken && t(mapCurrency(investToken)),
              investPlan: selectedPlan ? investPlanMap[selectedPlan].label : '',
            }}
          />
        </div>
      ),
    }).then((resp) => {
      if (resp) {
        if (selectedPlan === EInvestPlan.AFT) {
          setInvestLoading(true);

          invest(investAmount, investTokenWallet.currency)
            .then((response) => {
              if (response.success) {
                setInvestAmount(undefined);
                handleResponse(
                  t('pages.account.staking.content.success-invest', {
                    amount: investAmount.toLocaleString(),
                    currency: investToken && t(mapCurrency(investToken)),
                    invest: investPlanMap[selectedPlan].label,
                  }) + '',
                  false
                );
                fetchAftInvestPlan();
                fetchWallets();
                fetchTokenBank();
              }
            })
            .catch((response) => {
              handleResponse(response.response.data, true);
            })
            .finally(() => {
              setInvestLoading(false);
              sentInvestRequestRef.current = false;
            });
        } else if (selectedPlan === EInvestPlan.GAT) {
          setInvestLoading(true);

          investGat(investAmount, investTokenWallet.currency)
            .then((response) => {
              if (response.success) {
                setInvestAmount(undefined);
                handleResponse(
                  t('pages.account.staking.content.success-invest', {
                    amount: investAmount.toLocaleString(),
                    currency: investToken && t(mapCurrency(investToken)),
                    invest: investPlanMap[selectedPlan].label,
                  }) + '',
                  false
                );
                fetchGatInvestPlan();
                fetchWallets();
                fetchTokenBank();
              }
            })
            .catch((response) => {
              handleResponse(response.response.data, true);
            })
            .finally(() => {
              setInvestLoading(false);
              sentInvestRequestRef.current = false;
            });
        } else if (selectedPlan === EInvestPlan.SWAP) {
          setInvestLoading(true);

          investUsd(investAmount, investTokenWallet.currency)
            .then((response) => {
              if (response.success) {
                setInvestAmount(undefined);
                handleResponse(
                  t('pages.account.staking.content.success-invest', {
                    amount: investAmount.toLocaleString(),
                    currency: investToken && t(mapCurrency(investToken)),
                    invest: investPlanMap[selectedPlan].label,
                  }) + '',
                  false
                );
                fetchSwapInvestPlan();
                fetchWallets();
                fetchTokenBank();
              }
            })
            .catch((response) => {
              handleResponse(response.response.data, true);
            })
            .finally(() => {
              setInvestLoading(false);
              sentInvestRequestRef.current = false;
            });
        } else {
          sentInvestRequestRef.current = false;
        }
      } else {
        sentInvestRequestRef.current = false;
      }
    });
  }, [
    t,
    selectedPlan,
    investToken,
    investAmount,
    wallets,
    handleResponse,
    fetchAftInvestPlan,
    fetchGatInvestPlan,
    fetchSwapInvestPlan,
    fetchWallets,
    fetchTokenBank,
  ]);

  return (
    <>
      <Helmet>
        <title>Aurous | {t('pages.account.staking.title')}</title>
      </Helmet>
      <div id="staking">
        <h2 className="heading mb-2 tx-uppercase">{t('pages.account.staking.content.heading')}</h2>
        <h6 className="subheading mb-6 tx-gray-600">{t('pages.account.staking.content.subheading')}</h6>
        <div className="switcher-holder d-flex align-items-center">
          <RadioSwitcher<EInvestPlan>
            id="wallet-currency"
            options={options}
            handleValueChange={handleValueChange}
            selectedValue={selectedPlan}
          />
          <div className="ml-3 d-flex align-items-center">
            {!isLoading && selectedPlan === EInvestPlan.AFT && <AftPlanInfoModal />}
            {!isLoading && selectedPlan === EInvestPlan.GAT && <GatPlanInfoModal />}
            {!isLoading && selectedPlan === EInvestPlan.SWAP && <SwapPlanInfoModal />}
            {!isLoading && selectedPlan === EInvestPlan.ETHI && <EthiPlanInfoModal />}
          </div>
        </div>
        {selectedPlan && (
          <>
            <div className="staking-data">
              <div className="d-flex justify-content-between flex-wrap">
                <div className="card-block text-center text-lg-start py-1 px-4 mt-3 d-inline-block flex-nowrap">
                  <Translate i18nKey={`pages.account.staking.content.${investPlanMap[selectedPlan].langKey}.intro`} />
                </div>
                {!isLoading && selectedPlan === EInvestPlan.SWAP && (
                  <div className="card-block text-center text-lg-start pb-0 pt-1 px-4 mt-3 d-inline-block tx-uppercase flex-nowrap">
                    {t(`pages.account.staking.content.swap-plan.exit`)}{' '}
                    <EarlyWithdrawalModal fetchSwapInvestPlan={fetchSwapInvestPlan} planData={investPlanSwap} />
                  </div>
                )}
                {!isLoading &&
                  selectedPlan === EInvestPlan.ETHI &&
                  investPlanEthi &&
                  investPlanEthi.workedDaysCount < 29 && (
                    <div className="card-block text-center text-lg-start py-1 px-4 mt-3 d-inline-block tx-uppercase flex-nowrap">
                      {t(`pages.account.staking.content.ethi-plan.exit`)}{' '}
                      <EthiEarlyWithdrawalModal fetchEthiInvestPlan={fetchEthiInvestPlan} planData={investPlanEthi} />
                    </div>
                  )}
              </div>
              {isLoading && (
                <div className="card-block ht-150 d-flex my-3 justify-content-center align-items-center">
                  <Preloader inline />
                </div>
              )}
              {!isLoading && selectedPlan === EInvestPlan.AFT && <AftInvestPlan planData={investPlanAft} />}
              {!isLoading && selectedPlan === EInvestPlan.GAT && <GatInvestPlan plansData={investPlansGat} />}
              {!isLoading && selectedPlan === EInvestPlan.SWAP && <SwapInvestPlan planData={investPlanSwap} />}
              {!isLoading && selectedPlan === EInvestPlan.ETHI && <EthiInvestPlan planData={investPlanEthi} />}
            </div>
            {selectedPlan !== EInvestPlan.ETHI &&
              selectedPlan !== EInvestPlan.GAT &&
              (selectedPlan !== EInvestPlan.SWAP ||
                (selectedPlan === EInvestPlan.SWAP && investPlanSwap?.status === EInvestPlanStatus.ACTIVE)) && (
                <div className="invest-block mt-6">
                  <h5 className="tx-normal">
                    {t('pages.account.staking.content.invest-into')} {investPlanMap[selectedPlan].label}
                  </h5>
                  <InputGroup className="mt-3 mb-1">
                    <Form.Control
                      type="number"
                      size="lg"
                      placeholder="0.00"
                      value={investAmount !== undefined ? investAmount : ''}
                      id="investAmount"
                      onChange={(event) => setInvestAmount(event.target.value ? +event.target.value : undefined)}
                      disabled={isLoading}
                    />
                    <DropdownButton
                      variant="outline-primary"
                      title={
                        <div className="token-select notranslate">
                          {investToken && t(mapCurrency(investToken))}{' '}
                          <FontAwesomeIcon icon={faAngleDown} className="caret" />
                        </div>
                      }
                      align="start"
                    >
                      {investPlanMap[selectedPlan].investTokens.map((currency) => (
                        <Dropdown.Item
                          key={currency}
                          className={currency === investToken ? 'active' : ''}
                          onClick={() => setInvestToken(currency)}
                        >
                          {t(mapCurrency(currency))}
                        </Dropdown.Item>
                      ))}
                    </DropdownButton>
                  </InputGroup>
                  <div className="ml-3 tx-gray-600">
                    {t('common.available')}:
                    <FormattedNumber
                      value={investMaxAmount}
                      postfix={investToken && convertCurrency(investToken)}
                      decimals={2}
                      floor
                      className="text-decoration-underline ms-1 cur-pointer"
                      onClick={() => setInvestAmount(investMaxAmount)}
                    />
                  </div>
                  <div className="mt-5">
                    <button className="notranslate btn btn-primary tx-primary mt-2 mt-lg-0" onClick={handleInvest}>
                      <IconWithLoading icon={faSackDollar} isLoading={isInvestLoading} className="me-3" />
                      {t('pages.account.staking.content.invest')}
                    </button>
                  </div>
                </div>
              )}
            {selectedPlan === EInvestPlan.ETHI && (
              <EthiInvestBlock
                label={investPlanMap[selectedPlan].label}
                investPlanEthi={investPlanEthi}
                fetchEthiInvestPlan={fetchEthiInvestPlan}
              />
            )}
            {selectedPlan === EInvestPlan.GAT && (
              <div className="mt-5">
                <h4>{t('pages.account.staking.content.gat-plan.unavailable-title')}</h4>
                <p>{t('pages.account.staking.content.gat-plan.unavailable-text')}</p>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
});
