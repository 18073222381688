import { ECurrency } from '@~types/enums';

const currencyMapToTranslationKey: Record<ECurrency, string> = {
  [ECurrency.USD]: 'currencies.usdt',
  [ECurrency.AUF]: 'currencies.aft',
  [ECurrency.GAT]: 'currencies.gat',
  [ECurrency.ETHI_USD]: 'currencies.ethi-usdt',
  [ECurrency.SWAP_USD]: 'currencies.swap-usdt',
};

export function mapCurrency(currency: ECurrency) {
  return currencyMapToTranslationKey[currency];
}
