import BigNumber from 'bignumber.js';

import { convertStringToNumber } from '@~helpers/bigNumber';

export const randomNumber = () => Math.floor(100000000 + Math.random() * 900000000000000);

export const getRandomInt = (max: number) => {
  return Math.floor(Math.random() * max);
};

export const round = (num: string | number | BigNumber, decimals: number) => {
  const factor = 10 ** decimals;
  return Math.round(convertStringToNumber(num + '') * factor) / factor;
};

export const repairPrecision = (num: number) => round(num, 10);

export const roundUp = (num: string | number | BigNumber, decimals: number) => {
  const repairedNum = repairPrecision(convertStringToNumber(num + ''));
  const factor = 10 ** decimals;
  const magnified = repairPrecision(repairedNum * factor);
  return Math.ceil(magnified) / factor;
};

export const roundDown = (num: string | number | BigNumber, decimals: number) => {
  const repairedNum = repairPrecision(convertStringToNumber(num + ''));
  const factor = 10 ** decimals;
  const magnified = repairPrecision(repairedNum * factor);
  return Math.floor(magnified) / factor;
};

export const numberWithCommas = (
  x: number | string,
  floor: boolean | undefined,
  decimals: number | undefined,
  subZeros = false
) => {
  let value = decimals !== undefined ? round(x, decimals) : x;

  if (floor === true && decimals !== undefined) {
    value = roundDown(x, decimals);
  } else if (floor === false && decimals !== undefined) {
    value = roundUp(x, decimals);
  }

  const stringValue = new BigNumber(value).toFixed();

  const integer = parseInt(stringValue);
  const digits = stringValue.split('.')[1];
  const result = integer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  if (subZeros && digits) {
    let zeros = 0;
    let leftover = '';
    for (let i = 0; i < digits.length; i++) {
      if (digits[i] === '0') {
        zeros++;
      } else {
        leftover = digits.substring(i, i + 4);
        break;
      }
    }
    if (zeros > 1) {
      return (
        <>
          {integer}.0<sub>{zeros}</sub>
          {leftover}
        </>
      );
    }
  }

  return `${result}${digits ? '.' + digits.substring(0, decimals) : ''}`;
};

export const bigNumberToNumber = (num: BigNumber) => {
  return convertStringToNumber(num.toString());
};

export const formatNumber = (value: number, unit = '', decimals = 2) => {
  let number;
  let postfix = unit === '%' ? '%' : '';
  const suffix = unit === '$' ? '$' : '';

  if (value > 1000) {
    let divider = 1000;
    postfix = 'K';
    if (value > 1000000) {
      divider = 1000000;
      postfix = 'M';
    }
    if (value > 1000000000) {
      divider = 1000000000;
      postfix = 'B';
    }
    number = new BigNumber(value).div(divider).toFormat(decimals, 1).toString();
  } else {
    number = new BigNumber(value).toFormat(decimals, 1).toString();
  }
  const removeDigits = (postfix === 'M' || postfix === 'B') && parseInt(number).toString().length > 1;
  if (removeDigits || number.substring(number.length - (decimals + 1)) === '.00') {
    number = number.substring(0, number.length - (decimals + 1));
  }
  return suffix + number + postfix;
};

export const getPercentage = (value: number, total: number) => {
  return new BigNumber(value).div(total).times(100).toNumber();
};
