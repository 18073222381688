import { useAtom } from 'jotai';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import phones from '@~assets/images/landing/about-pic.png';

import { Translate } from '@~components/translate/Translate';
import { loggedAtom } from '@~store/account.store';
import { EAccountLink } from '@~types/navigationEnums';

export const AboutUs = memo(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isLoggedIn] = useAtom(loggedAtom);

  const handleRegisterClick = useCallback(() => {
    navigate(EAccountLink.REGISTER);
  }, [navigate]);

  return (
    <section className="pt-lg-7 pb-lg-4 pt-md-2 pb-md-4 pt-sm-4 pb-sm-4 pt-4 pb-4 pos-relative" id="about-us">
      {/* <div className="shapes-wrapper">
        <span className="blurred-shape for-prev shape-purple wd-300 ht-250" />
        <span className="blurred-shape for-prev shape-pink wd-450 ht-300 animation-delay-2000" />
        <span className="blurred-shape for-prev shape-dark wd-550 ht-350 animation-delay-4000" />

        <span className="blurred-shape main animation-none shape-purple wd-200 ht-70 ht-lg-120" />
        <span className="blurred-shape main shape-pink wd-200 ht-150 animation-delay-2000" />
        <span className="blurred-shape main animation-none shape-dark wd-200 ht-70 ht-lg-120 animation-delay-4000" />
      </div> */}

      <div className="container pb-6">
        <div className="row">
          <div className="col-md-6 order-2 order-md-1">
            <h2 className="mb-md-5 mt-md-5 pt-md-3 tx-lg-48">
              <Translate i18nKey={'pages.home.content.about.title'} />
            </h2>
            <div className="mb-5 wd-md-550" style={{ width: '100%' }}>
              <Translate i18nKey={'pages.home.content.about.text'} />
            </div>

            {!isLoggedIn && (
              <button className="btn btn-lg btn-primary wd-150" onClick={handleRegisterClick}>
                {t('pages.register.title')}
              </button>
            )}
          </div>

          <div className="col-md-6 order-1 order-md-2 align-items-center d-flex d-flex justify-content-center">
            <img src={phones} alt="Aurous.finance" />
          </div>
        </div>
      </div>
    </section>
  );
});
