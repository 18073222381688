import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import classNames from 'classnames';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';

import { Preloader } from '@~components/preloader/Preloader';
import { Placeholder } from '@~components/placeholder/Placeholder';
import { ETableView } from '@~types/enums';
import type { IHeader, IRow } from '@~types/types';

interface ITableProps {
  id: string;
  className?: string;
  tableView?: ETableView;
  headers: IHeader[];
  rows: IRow[];
  isLoading?: boolean;
  dataLoaded?: boolean;
  total?: number;
  handlePageChange?: (pageNr: number) => void;
  initialPage?: number;
  rowsPerPage?: number;
  noDataTranslationKey?: string;
}

export const Table = memo((props: ITableProps) => {
  const {
    id,
    className,
    tableView = ETableView.TABLE,
    headers,
    rows,
    isLoading = false,
    dataLoaded = true,
    total = rows.length,
    handlePageChange = () => {},
    initialPage = 0,
    rowsPerPage = 10,
    noDataTranslationKey = 'components.table.no-data',
  } = props;

  const { t } = useTranslation();

  return (
    <div className={className}>
      <div id={id}>
        <div className={classNames('table-wrapper-responsive', [{ 'list-view': tableView === ETableView.LIST }])}>
          {isLoading && rows.length > 0 && <Preloader />}
          <table className="table mb-0 tx-center">
            <thead className="tx-white">
              <tr>
                {headers.map((header) => (
                  <th className="bd-t-0-f" key={'th-' + header.key}>
                    {t(header.translateKey, header.options || {})}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {!dataLoaded && (
                <>
                  <tr>
                    {headers.map((header) => (
                      <td key={'td-line1-' + header.key}>
                        <div className="list-view-heading">{t(header.translateKey, header.options || {})}</div>
                        <Placeholder width="100%" />
                      </td>
                    ))}
                  </tr>
                  <tr>
                    {headers.map((header) => (
                      <td key={'td-line2-' + header.key}>
                        <div className="list-view-heading">{t(header.translateKey, header.options || {})}</div>
                        <Placeholder width="100%" />
                      </td>
                    ))}
                  </tr>
                </>
              )}
              {rows.map((entry) => (
                <tr key={'table-transaction-' + entry.id}>
                  {entry.cells.map((cell, index) => (
                    <td key={`cell-${headers[index].key}`}>
                      <div className="list-view-heading">
                        {t(headers[index].translateKey, headers[index].options || {})}
                      </div>
                      {cell}
                    </td>
                  ))}
                </tr>
              ))}
              {dataLoaded && rows.length === 0 && (
                <tr>
                  <td colSpan={headers.length} className="text-center p-4">
                    {t(noDataTranslationKey)}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {total > rowsPerPage && (
        <ReactPaginate
          previousLabel={<FontAwesomeIcon icon={faArrowLeft} />}
          nextLabel={<FontAwesomeIcon icon={faArrowRight} />}
          pageCount={Math.ceil(total / rowsPerPage)}
          initialPage={initialPage}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          containerClassName={'pagination mt-3 pb-3 justify-content-center'}
          activeClassName={'active'}
          onPageChange={(data) => handlePageChange(data.selected)}
          eventListener={isLoading ? '' : 'onClick'}
        />
      )}
    </div>
  );
});
