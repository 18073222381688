export enum ESocialRoute {
  INSTAGRAM = '/instagram',
  YOUTUBE = '/youtube',
  TELEGRAM = '/telegram',
  FACEBOOK = '/facebook',
  VK = '/vkontakte',
  LINKED_IN = '/linkedin',
  MEDIUM = '/medium',
  TWITTER = '/twitter',
  REDDIT = '/reddit',
  TIKTOK = '/tiktok',
  BITCOIN_TALK = '/bitcoin-talk',
}

export enum ESocialLink {
  INSTAGRAM = 'https://instagram.com/aurous.finance',
  YOUTUBE = 'https://www.youtube.com/@aurous.finance',
  TELEGRAM = 'https://t.me/aurous_finance_official',
  FACEBOOK = 'http://www.facebook.com/groups/aurous.finance',
  VK = 'https://vk.com/aurous.finance',
  BITCOIN_TALK = 'https://bitcointalk.com/user/',
  LINKED_IN = 'https://www.linkedin.cn/',
  MEDIUM = 'https://medium.com/',
  TWITTER = 'https://twitter.com/aurous_gold',
  REDDIT = 'https://www.reddit.com/',
  TIKTOK = 'https://www.tiktok.com/@aurous.finance',
  SUPPORT = '',
}

export enum ELandingLink {
  HOME = '/',
  ERROR = '/error',
  NOT_FOUND = '/404',
  NEWS = '/news/:slug',
}

export enum EAccountLink {
  LOGIN = '/login',
  REGISTER = '/register',
  PASSWORD_RECOVERY = '/password-recovery',
  PASSWORD_RESET = '/reset-password',
  ROOT = '/account',
  DEPOSIT = 'deposit',
  SWAP = 'swap',
  STAKING = 'staking',
  PARTNERS = 'partners',
  RANK_SYSTEM = 'rank-system',
  HISTORY = 'history',
  PROFILE = 'profile',
  WITHDRAWAL = 'withdrawal',
  PROMO_ACTIONS = 'promo-actions',
}
