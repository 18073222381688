import { useAtom } from 'jotai';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import mainSectionImage from '@~assets/images/landing/main-image.jpg';

import { Translate } from '@~components/translate/Translate';
import { loggedAtom } from '@~store/account.store';
import { EAccountLink } from '@~types/navigationEnums';

import styles from './Main.module.scss';

export const Main = memo(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isLoggedIn] = useAtom(loggedAtom);

  const handleLoginClick = useCallback(() => {
    navigate(EAccountLink.LOGIN);
  }, [navigate]);

  const handleRegisterClick = useCallback(() => {
    navigate(EAccountLink.REGISTER);
  }, [navigate]);

  const handleDashboardClick = useCallback(() => {
    navigate(EAccountLink.ROOT);
  }, [navigate]);

  return (
    <section className={styles.root} id="main">
      <div className="container">
        <div className="row pt-6 pb-5">
          <div className="col-md-6 d-flex justify-content-center align-items-center">
            <div>
              <img src={mainSectionImage} alt="" />

              {/* <iframe
                src={t('pages.home.content.video')}
                frameBorder="0"
                title="Aurous.Finance"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen={true}
              /> */}
            </div>
          </div>

          <div className="col-md-6 text-center text-md-start">
            <h1 className="wd-lg-500 tx-32 tx-lg-48 pt-3 pt-lg-5 mb-4">
              <Translate i18nKey={'pages.home.content.heading'} />
            </h1>
            <h3 className="tx-18 mb-4">
              <Translate i18nKey={'pages.home.content.subheading'} />
            </h3>

            <div>
              {!isLoggedIn && (
                <>
                  <button className="btn btn-lg btn-primary me-2 wd-150" onClick={handleLoginClick}>
                    {t('pages.login.title')}
                  </button>

                  <button className="btn btn-lg btn-outline-primary wd-150" onClick={handleRegisterClick}>
                    {t('pages.register.title')}
                  </button>
                </>
              )}
              {isLoggedIn && (
                <button className="btn btn-lg btn-primary wd-150" onClick={handleDashboardClick}>
                  {t('global.navigation.account')}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});
