const {
  REACT_APP_IS_TEST_ENV,
  REACT_APP_API_URL: apiUrl = '',
  REACT_APP_TG_BOT_URL: tgBotUrl = '',
  REACT_APP_IS_UNDER_MAINTENANCE = 'false',
} = process.env;

export const config = {
  isTestEnv: REACT_APP_IS_TEST_ENV === 'true',
  isUnderMaintenance: REACT_APP_IS_UNDER_MAINTENANCE === 'true',
  apiUrl,
  tgBotUrl,
};
