import { faCopy } from '@fortawesome/pro-solid-svg-icons/faCopy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { ReactNode } from 'react';
import { memo, useCallback } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

interface ICopyButtonProps {
  text: string;
  alertMessage?: string;
  element?: ReactNode;
  className?: string;
  iconClass?: string;
  noIcon?: boolean;
}

export const CopyButton = memo((props: ICopyButtonProps) => {
  const { t } = useTranslation();

  const {
    text,
    element,
    alertMessage = t('common.copied') + '',
    className = 'btn btn-link p-0 tx-left',
    iconClass = 'tx-white tx-12 ml-2',
    noIcon = false,
  } = props;

  const handleCopy = useCallback(() => {
    toast.success(alertMessage, { autoClose: 5000, theme: 'dark' });
  }, [alertMessage]);

  return (
    <CopyToClipboard text={text} onCopy={handleCopy}>
      <button className={className}>
        <span>{element !== undefined ? element : text}</span>
        {!noIcon && <FontAwesomeIcon icon={faCopy} className={iconClass} />}
      </button>
    </CopyToClipboard>
  );
});
