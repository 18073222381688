import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import {
  faTelegramPlane,
  faInstagram,
  faFacebook,
  faTwitter,
  faTiktok,
  faVk,
} from '@fortawesome/free-brands-svg-icons';
import classNames from 'classnames';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { ESocialLink } from '@~types/navigationEnums';

import styles from './SocialLinks.module.scss';

export const SocialLinks = memo(() => {
  const { t } = useTranslation();

  const socials = [
    {
      icon: faInstagram,
      id: 'instagram',
      link: ESocialLink.INSTAGRAM,
      title: 'Instagram',
    },
    {
      icon: faFacebook,
      id: 'facebook',
      link: ESocialLink.FACEBOOK,
      title: 'Facebook',
    },
    {
      icon: faTwitter,
      id: 'twitter',
      link: ESocialLink.TWITTER,
      title: 'Twitter',
    },
    // {
    //   icon: faLinkedin,
    //   id: 'linkedin',
    //   link: SocialLinksE.LINKED_IN,
    //   title: 'LinkedIn',
    // },
    // {
    //   icon: faReddit,
    //   id: 'reddit',
    //   link: SocialLinksE.REDDIT,
    //   title: 'Reddit',
    // },
    {
      icon: faTelegramPlane,
      id: 'telegram',
      link: t('links.telegram-channel'),
      title: 'Telegram',
    },
    {
      icon: faTiktok,
      id: 'tiktok',
      link: ESocialLink.TIKTOK,
      title: 'TikTok',
    },
    // {
    //   icon: faMedium,
    //   id: 'medium-com',
    //   link: SocialLinksE.MEDIUM,
    //   title: 'Medium',
    // },
    {
      icon: faVk,
      id: 'vk',
      link: ESocialLink.VK,
      title: 'VK',
    },
  ];

  return (
    <div className="d-flex flex-wrap mt-3 justify-content-center justify-content-md-start">
      {socials.map((social) => (
        <a
          className={classNames(styles.footerLink, 'tx-20 me-2', 'tx-' + social.id)}
          href={social.link}
          target="_blank"
          rel="noreferrer"
          title={social.title}
          key={social.title}
        >
          <FontAwesomeIcon icon={social.icon as IconDefinition} />
        </a>
      ))}
    </div>
  );
});
