import { faArrowUpArrowDown, faRotate } from '@fortawesome/pro-duotone-svg-icons';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BigNumber from 'bignumber.js';
import { useAtom } from 'jotai';
import type { ChangeEvent } from 'react';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownButton, Form, InputGroup } from 'react-bootstrap';

import { FormattedNumber } from '@~components/formatted-number/FormattedNumber';
import { IconWithLoading } from '@~components/icon-with-loading/IconWithLoading';
import { useStateUpdate } from '@~context/StateContext';
import { mapCurrency } from '@~helpers/mapCurrency';
import { useResponseHandler } from '@~hooks/useResponseHandler';
import { tokenBankAtom, walletsAtom } from '@~store/account.store';
import { ECurrency } from '@~types/enums';
import type { IRate } from '@~types/types';
import { Translate } from '@~components/translate/Translate';
import { confirmAlert } from '@~components/modal-alert/corfirmAlert';
import { convertCurrency } from '@~helpers/convertCurrency';

// const fromCurrencies = [ECurrency.AUF, ECurrency.GAT, ECurrency.USD, ECurrency.ETHI_USD, ECurrency.SWAP_USD];
const fromCurrencies = [ECurrency.AUF, ECurrency.GAT, ECurrency.USD];

const MAX_INPUT_LENGTH = 13;

interface ICurrencyData {
  id: ECurrency;
  label: string;
  toCurrencies: ECurrency[];
}

const currencyMap: Record<ECurrency, ICurrencyData> = {
  [ECurrency.USD]: { id: ECurrency.USD, label: 'USDT', toCurrencies: [ECurrency.AUF, ECurrency.GAT] },
  [ECurrency.ETHI_USD]: { id: ECurrency.ETHI_USD, label: 'USDT', toCurrencies: [ECurrency.USD] },
  [ECurrency.SWAP_USD]: { id: ECurrency.SWAP_USD, label: 'USDT', toCurrencies: [ECurrency.USD] },
  [ECurrency.AUF]: { id: ECurrency.AUF, label: 'AFT', toCurrencies: [ECurrency.USD, ECurrency.GAT] },
  [ECurrency.GAT]: { id: ECurrency.GAT, label: 'GAT', toCurrencies: [ECurrency.USD, ECurrency.AUF] },
};

export const SwapPage = memo(() => {
  const { t } = useTranslation();

  const [wallets] = useAtom(walletsAtom);
  const [tokenBank] = useAtom(tokenBankAtom);

  const { fetchWallets } = useStateUpdate();
  const handleResponse = useResponseHandler();

  const [isLoading, setLoading] = useState(false);
  const [fromCurrency, setFromCurrency] = useState<ECurrency>();
  const [toCurrency, setToCurrency] = useState<ECurrency>();
  const [fromAmount, setFromAmount] = useState<number>();
  const [fromMaxAmount, setFromMaxAmount] = useState<number>();
  const [toAmount, setToAmount] = useState<number>();

  const fromAmountRef = useRef<number>();
  const fromCurrencyRef = useRef<ECurrency>();
  const toCurrencyRef = useRef<ECurrency>();

  const rates: IRate = useMemo(() => {
    if (!tokenBank) {
      return { to: 1, from: 1 };
    }
    if ([fromCurrency, toCurrency].includes(ECurrency.USD)) {
      if ([fromCurrency, toCurrency].includes(ECurrency.GAT)) {
        return {
          to: fromCurrency === ECurrency.USD ? tokenBank.gatPrice : 1 / tokenBank.gatPrice,
          from: toCurrency === ECurrency.USD ? tokenBank.gatPrice : 1 / tokenBank.gatPrice,
        };
      } else if ([fromCurrency, toCurrency].includes(ECurrency.AUF)) {
        return {
          to: fromCurrency === ECurrency.USD ? tokenBank.aufPrice : 1 / tokenBank.aufPrice,
          from: toCurrency === ECurrency.USD ? tokenBank.aufPrice : 1 / tokenBank.aufPrice,
        };
      } else {
        return {
          to: 1,
          from: 1,
        };
      }
    } else {
      return {
        to:
          fromCurrency === ECurrency.GAT
            ? tokenBank.aufPrice / tokenBank.gatPrice
            : tokenBank.gatPrice / tokenBank.aufPrice,
        from:
          toCurrency === ECurrency.GAT
            ? tokenBank.aufPrice / tokenBank.gatPrice
            : tokenBank.gatPrice / tokenBank.aufPrice,
      };
    }
  }, [fromCurrency, toCurrency, tokenBank]);

  useEffect(() => {
    setFromCurrency(fromCurrencies[0]);
  }, []);

  useEffect(() => {
    if (!fromCurrency) {
      return;
    }

    if (
      fromCurrencyRef.current !== fromCurrency ||
      (toCurrencyRef.current && !currencyMap[fromCurrency].toCurrencies.includes(toCurrencyRef.current))
    ) {
      setToCurrency(currencyMap[fromCurrency].toCurrencies[0]);
    }
  }, [fromCurrency]);

  useEffect(() => {
    let fromMaxAmountValue = 0;
    if (fromCurrency && wallets.length > 0) {
      const foundWallet = wallets.find(({ currency }) => currency === fromCurrency);
      if (foundWallet) {
        fromMaxAmountValue = new BigNumber(foundWallet.balance).decimalPlaces(2, 1).toNumber();
      }
    }
    setToAmount(undefined);
    setFromAmount(undefined);
    setFromMaxAmount(fromMaxAmountValue);
  }, [fromCurrency, wallets]);

  const handleSwapFields = useCallback(() => {
    fromCurrencyRef.current = toCurrency;
    toCurrencyRef.current = fromCurrency;
    setFromCurrency(toCurrency);
    setToCurrency(fromCurrency);
    setFromAmount(undefined);
    setToAmount(undefined);
  }, [toCurrency, fromCurrency]);

  const handleSwap = useCallback(() => {
    confirmAlert({
      title: t('pages.account.swap.content.swap') + '',
      okLabel: t('pages.account.swap.content.swap') + '',
      okVariant: 'success',
      content: (
        <div className="modal-content-bg">
          <Translate
            i18nKey={'pages.account.swap.content.temporary-success-swap'}
            values={{
              fromAmount,
              fromCurrency: fromCurrency && t(mapCurrency(fromCurrency)),
              toAmount,
              toCurrency: toCurrency && t(mapCurrency(toCurrency)),
            }}
          />
        </div>
      ),
    }).then((resp) => {
      if (resp) {
        setLoading(true);
        handleResponse(
          t('pages.account.swap.content.temporary-success-swap', {
            fromAmount,
            fromCurrency: fromCurrency && t(mapCurrency(fromCurrency)),
            toAmount,
            toCurrency: toCurrency && t(mapCurrency(toCurrency)),
          }) + '',
          false
        );
        fetchWallets();
        setFromAmount(undefined);
        setToAmount(undefined);
        setLoading(false);
      }
    });
  }, [t, handleResponse, fromAmount, fromCurrency, toAmount, toCurrency, fetchWallets]);

  const changeToAmount = useCallback(
    (fromAmountValue?: number) => {
      if (!fromAmountValue) {
        setToAmount(fromAmountValue);
      } else {
        setToAmount(Math.round(fromAmountValue * rates.from * 100) / 100);
      }
    },
    [rates]
  );

  const handleFromAmountInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.value.length > MAX_INPUT_LENGTH) {
        return;
      }

      const fromAmountValue = event.target.value ? +event.target.value : undefined;
      setFromAmount(fromAmountValue);
      changeToAmount(fromAmountValue);
    },
    [changeToAmount]
  );

  const handleToAmountInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.value.length > MAX_INPUT_LENGTH) {
        return;
      }

      const toAmountValue = event.target.value ? +event.target.value : undefined;
      setToAmount(toAmountValue);
      if (!toAmountValue) {
        setFromAmount(toAmountValue);
      } else {
        setFromAmount(Math.round(toAmountValue * rates.to * 100) / 100);
      }
    },
    [rates]
  );

  useEffect(() => {
    if (fromAmountRef.current === fromAmount && fromCurrencyRef.current === fromCurrency) {
      changeToAmount(fromAmount);
    } else {
      fromAmountRef.current = fromAmount;
      fromCurrencyRef.current = fromCurrency;
    }
  }, [changeToAmount, fromAmount, fromCurrency]);

  return (
    <>
      <Helmet>
        <title>Aurous | {t('pages.account.swap.title')}</title>
      </Helmet>
      <div id="swap">
        <h2 className="heading mb-2 tx-uppercase d-flex align-items-center gap-3">
          {t('pages.account.swap.content.heading')}
          <div className="badge bg-info">
            <Translate i18nKey="common.test" />
          </div>
        </h2>
        <h6 className="subheading mb-6 tx-gray-600">{t('pages.account.swap.content.subheading')}</h6>
        <div className="swap-block">
          <div className="mt-3 mb-1">
            <h5 className="tx-normal">{t('pages.account.swap.content.from')}</h5>
            <InputGroup className="mt-3 mb-1">
              <Form.Control
                type="number"
                size="lg"
                placeholder="0.00"
                maxLength={12}
                value={fromAmount !== undefined ? fromAmount : ''}
                id="fromAmount"
                onInput={handleFromAmountInput}
                disabled={isLoading}
              />
              <DropdownButton
                variant="outline-primary"
                title={
                  <div className="token-select notranslate">
                    {fromCurrency && t(mapCurrency(fromCurrency))}{' '}
                    <FontAwesomeIcon icon={faAngleDown} className="caret" />
                  </div>
                }
                align="start"
              >
                {fromCurrencies.map((currency) => (
                  <Dropdown.Item
                    key={currency}
                    className={currency === fromCurrency ? 'active' : ''}
                    onClick={() => setFromCurrency(currency)}
                  >
                    {t(mapCurrency(currency))}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </InputGroup>
            <div className="ml-3 tx-gray-600">
              {t('common.available')}:
              <FormattedNumber
                value={fromMaxAmount}
                postfix={fromCurrency && convertCurrency(fromCurrency)}
                decimals={2}
                floor
                className="text-decoration-underline ms-1 cur-pointer"
                onClick={() => {
                  setFromAmount(fromMaxAmount);
                  changeToAmount(fromMaxAmount);
                }}
              />
            </div>
          </div>
          <div className="d-flex justify-content-center my-3">
            <button className="btn btn-link tx-primary" onClick={handleSwapFields}>
              <FontAwesomeIcon icon={faArrowUpArrowDown} />
            </button>
          </div>
          <div className="mt-3 mb-1">
            <h5 className="tx-normal">{t('pages.account.swap.content.to')}</h5>
            <InputGroup className="mt-3 mb-1">
              <Form.Control
                type="number"
                size="lg"
                placeholder="0.00"
                maxLength={12}
                value={toAmount !== undefined ? toAmount : ''}
                id="toAmount"
                onInput={handleToAmountInput}
                disabled={isLoading}
              />
              {fromCurrency && (
                <DropdownButton
                  variant="outline-primary"
                  title={
                    <div className="token-select notranslate">
                      {toCurrency && t(mapCurrency(toCurrency))}{' '}
                      <FontAwesomeIcon icon={faAngleDown} className="caret" />
                    </div>
                  }
                  align="start"
                >
                  {currencyMap[fromCurrency].toCurrencies.map((currency) => (
                    <Dropdown.Item
                      key={currency}
                      className={currency === toCurrency ? 'active' : ''}
                      onClick={() => setToCurrency(currency)}
                    >
                      {t(mapCurrency(currency))}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              )}
            </InputGroup>
          </div>
          <div className="mt-5">
            <button
              className="notranslate btn btn-primary tx-primary mt-2 mt-lg-0"
              onClick={handleSwap}
              disabled={!fromAmount || !toAmount}
            >
              <IconWithLoading icon={faRotate} isLoading={isLoading} className="me-3" />
              {t('pages.account.swap.content.swap')}
            </button>
          </div>
        </div>
      </div>
    </>
  );
});
