import { PropsWithChildren } from 'react';
import { Outlet } from 'react-router-dom';

import { AccountNavigation } from '@~components/account-navigation/AccountNavigation';
import { LoginPage } from '@~pages/login/LoginPage';
import { ParticlesBackground } from '@~components/particles-background/ParticlesBackground';
import { UserStats } from '@~components/user-stats/UserStats';

interface IProtectedRoute extends PropsWithChildren {
  isAllowed: boolean;
}

export const ProtectedRoute = ({ isAllowed }: IProtectedRoute) => {
  if (!isAllowed) {
    return <LoginPage />;
  }

  return (
    <>
      <ParticlesBackground />
      <div className="page px-2 px-md-3 px-lg-4">
        <div id="account-layout">
          <div className="side-bar">
            <div className="user-stats">
              <UserStats />
            </div>
            <div className="navigation">
              <AccountNavigation />
            </div>
          </div>
          <div className="content">
            <div className="card account">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
