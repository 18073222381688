import { useTranslation } from 'react-i18next';
import { faRightToBracket } from '@fortawesome/pro-duotone-svg-icons';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import promoImage from '@~assets/promos/cappadocia-2023.png';
import { ReactComponent as RewardIcon } from '@~assets/promos/award-1.svg';
import { ReactComponent as TermsMoneyIcon } from '@~assets/promos/terms-money.svg';
import { ReactComponent as TermsTurnoverIcon } from '@~assets/promos/terms-turnover.svg';

import { FormattedNumber } from '@~components/formatted-number/FormattedNumber';
import { FormattedDate } from '@~components/formatted-date/FormattedDate';
import { IconWithLoading } from '@~components/icon-with-loading/IconWithLoading';
import { confirmAlert } from '@~components/modal-alert/corfirmAlert';
import { Preloader } from '@~components/preloader/Preloader';
import { Translate } from '@~components/translate/Translate';
import { convertCurrency } from '@~helpers/convertCurrency';
import { useResponseHandler } from '@~hooks/useResponseHandler';
import { applyForPromoCappadocia, getPromoCappadocia } from '@~network/account';
import { PromoCappadociaSep2023Modal } from '@~pages/account/promo-actions/modals/PromoCappadociaSep2023Modal';
import { ECurrency, EPromoStatus } from '@~types/enums';
import { IPromoCappadociaData } from '@~types/requests';

export const PromoCappadociaSep2023 = memo(() => {
  const { t } = useTranslation();

  const handleResponse = useResponseHandler();

  const [isLoading, setIsLoading] = useState(false);
  const [promoData, setPromoData] = useState<IPromoCappadociaData>();

  const requestSentRef = useRef(false);
  const actionSentRef = useRef(false);

  useEffect(() => {
    if (requestSentRef.current) {
      return;
    }

    requestSentRef.current = true;
    setIsLoading(true);
    getPromoCappadocia()
      .then((response) => {
        if (response.success && response.data) {
          setPromoData(response.data);
        } else {
          handleResponse(response);
        }
      })
      .catch((response) => {
        handleResponse(response.response.data);
      })
      .finally(() => {
        setIsLoading(false);
        requestSentRef.current = false;
      });
  }, [handleResponse]);

  const applyForPromo = useCallback(() => {
    if (actionSentRef.current) {
      return;
    }

    actionSentRef.current = true;

    confirmAlert({
      title: t('pages.account.promo-actions.content.promo-cappadocia-sep-2023.confirm.title') + '',
      okLabel: t('pages.account.promo-actions.content.promo-cappadocia-sep-2023.confirm.confirm-button') + '',
      okVariant: 'success',
      content: (
        <div className="modal-content-bg">
          {t('pages.account.promo-actions.content.promo-cappadocia-sep-2023.confirm.confirm-text')}
        </div>
      ),
    }).then((resp) => {
      if (resp) {
        setIsLoading(true);

        applyForPromoCappadocia()
          .then((response) => {
            if (response.success && response.data) {
              handleResponse(t('pages.account.promo-actions.content.promo-cappadocia-sep-2023.promo-applied') + '');
              setPromoData(response.data);
            } else {
              handleResponse(response);
            }
          })
          .catch((response) => {
            handleResponse(response.response.data);
          })
          .finally(() => {
            actionSentRef.current = false;
            setIsLoading(false);
          });
      } else {
        actionSentRef.current = false;
      }
    });
  }, [t, handleResponse]);

  const promoEndTime = useMemo(() => {
    if (!promoData || !promoData.promoEnds) {
      return new Date();
    }
    const endDate = new Date(promoData.promoEnds);
    endDate.setHours(endDate.getHours() - 24);
    return endDate;
  }, [promoData]);

  const turnoverPercentage = useMemo(() => {
    if (!promoData) {
      return 0;
    }
    const userTurnover = (promoData.totalTurnover / promoData.expectedTotalTurnover) * 100;
    return userTurnover > 100 ? 100 : userTurnover;
  }, [promoData]);

  return (
    <div className="card-block my-3">
      <div className="d-flex justify-content-between flex-wrap gap-3">
        <div className="image-block d-flex flex-column flex-1">
          <div className="d-flex justify-content-between">
            <h3 className="d-flex align-items-center gap-3 tx-uppercase">
              {t('pages.account.promo-actions.content.promo-cappadocia-sep-2023.heading')}
            </h3>
            <div>
              <PromoCappadociaSep2023Modal />
            </div>
          </div>
          {promoData && promoData.promoStarts && promoEndTime && (
            <div>
              <FormattedDate date={promoData.promoStarts} dateFormat="dd.MM" />
              {' - '}
              <FormattedDate date={promoEndTime} dateFormat="dd.MM" />
            </div>
          )}
          <div className="d-flex flex-1 justify-content-center align-items-center text-center">
            <img
              src={promoImage}
              alt={t('pages.account.promo-actions.content.promo-cappadocia-sep-2023.intro') + ''}
              className="pt-4"
            />
          </div>
        </div>
        <div className="details-block flex-1 mt-2">
          <div className="mb-4">
            <h5 className="tx-uppercase tx-spacing-1 mb-3">{t('pages.account.promo-actions.content.turnover')}</h5>
            {isLoading && !promoData && (
              <div className="ht-150 d-flex justify-content-center align-items-center">
                <Preloader inline />
              </div>
            )}
            {promoData && (
              <>
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow={
                      promoData.status === EPromoStatus.APPLIED
                        ? promoData.expectedTotalTurnover
                        : promoData.totalTurnover
                    }
                    aria-valuemin={0}
                    aria-valuemax={promoData.expectedTotalTurnover}
                    style={{
                      width: `${promoData.status === EPromoStatus.APPLIED ? 100 : turnoverPercentage}%`,
                    }}
                  />
                  <div className="progress-text tx-16">
                    <FormattedNumber value={promoData.totalTurnover} postfix={convertCurrency(ECurrency.USD)} />
                  </div>
                </div>
                <div className="progress-text tx-14 tx-italic text-end">
                  {t('pages.account.promo-actions.content.turnover-max')}{' '}
                  <FormattedNumber value={promoData.expectedTotalTurnover} postfix={convertCurrency(ECurrency.USD)} />
                </div>
              </>
            )}
          </div>

          <div className="plate-text tx-bold tx-italic my-4 p-2 text-center">
            {t('pages.account.promo-actions.content.promo-cappadocia-sep-2023.intro')}
          </div>
          <div className="mb-4">
            <h5 className="tx-uppercase tx-spacing-1 mb-3">{t('pages.account.promo-actions.content.rewards')}</h5>
            <div className="plate-text d-flex align-items-center p-3">
              <div className="pe-3">
                <RewardIcon width="40" height="40" />
              </div>
              <div>
                <Translate i18nKey="pages.account.promo-actions.content.promo-cappadocia-sep-2023.reward-1" />
              </div>
            </div>
            <div className="plate-text d-flex align-items-center p-3 mt-3">
              <div className="pe-3">
                <RewardIcon width="40" height="40" />
              </div>
              <div>
                <Translate i18nKey="pages.account.promo-actions.content.promo-cappadocia-sep-2023.reward-2" />
              </div>
            </div>
          </div>
          <div className="mb-4">
            <h5 className="tx-uppercase tx-spacing-1 mb-3">{t('pages.account.promo-actions.content.terms')}</h5>
            <div className="plate-text d-flex align-items-center p-3">
              <div className="pe-3">
                <TermsMoneyIcon width="40" height="40" />
              </div>
              <div>
                <Translate i18nKey="pages.account.promo-actions.content.promo-cappadocia-sep-2023.term-1" />
              </div>
            </div>
            <div className="plate-text d-flex align-items-center p-3 mt-3">
              <div className="pe-3">
                <TermsTurnoverIcon width="40" height="40" />
              </div>
              <div>
                <Translate i18nKey="pages.account.promo-actions.content.promo-cappadocia-sep-2023.term-2" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {promoData &&
        promoData.status === EPromoStatus.ACTIVE &&
        promoData.totalTurnover >= promoData.expectedTotalTurnover && (
          <div className="d-flex mt-5">
            <button className="notranslate btn btn-primary mb-4 mb-md-0 w-100" onClick={applyForPromo}>
              <IconWithLoading icon={faRightToBracket} className="me-2" isLoading={isLoading} />
              {t('pages.account.promo-actions.content.promo-cappadocia-sep-2023.apply-for-promo')}
            </button>
          </div>
        )}
      {promoData && promoData.status !== EPromoStatus.ACTIVE && (
        <div className="mt-3 text-center">
          {promoData.status === EPromoStatus.FINISHED &&
            t('pages.account.promo-actions.content.promo-cappadocia-sep-2023.promo-finished')}
          {promoData.status === EPromoStatus.APPLIED &&
            t('pages.account.promo-actions.content.promo-cappadocia-sep-2023.promo-applied')}
        </div>
      )}
    </div>
  );
});
