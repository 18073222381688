import { useTranslation } from 'react-i18next';
import { memo } from 'react';

import styles from './Quarter.module.scss';

interface IQuarterProps {
  index: number;
}

export const Quarter = memo(({ index }: IQuarterProps) => {
  const { t } = useTranslation();

  const quarters = [
    'pages.home.content.road-map.1-q-17',
    'pages.home.content.road-map.3-q-17',
    'pages.home.content.road-map.18',
    'pages.home.content.road-map.19',
    'pages.home.content.road-map.1-q-21',
    'pages.home.content.road-map.3-q-21',
    'pages.home.content.road-map.2-q-22',
    'pages.home.content.road-map.1-q-23',
    'pages.home.content.road-map.2-q-23',
    'pages.home.content.road-map.3-q-23',
  ];

  return (
    <div id={'road-map-q' + (index + 1)} className={styles.root}>
      <h4 className="tx-gradient-orange tx-bold tx-uppercase tx-24 tx-md-14 tx-lg-18">{t(quarters[index])}</h4>
    </div>
  );
});
