import { useAtom } from 'jotai';
import { useCallback } from 'react';
import { toast, ToastOptions } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { loggedAtom } from '@~store/account.store';
import type { IStatusResponse, TTemporaryAny } from '@~types/types';

export function useResponseHandler() {
  const [, setLogged] = useAtom(loggedAtom);

  const { t, i18n } = useTranslation();

  return useCallback(
    (response?: IStatusResponse<TTemporaryAny> | string, isError?: boolean) => {
      const toastOptions: ToastOptions = {
        autoClose: 5000,
        theme: 'dark',
      };
      let isSessionExpired = false;
      let messages: string[] = [];
      let hasError = !!isError;

      // console.log('useResponseHandler', {response});
      if (response && typeof response !== 'string') {
        if (response.errors?.length) {
          hasError = true;
          messages = response.errors.map((error) => (i18n.exists(error.code) ? t(error.code) : error.message));
        }
        // console.log({messages});
        if (
          response.statusCodeValue === 401 ||
          response.statusCodeValue === 403 ||
          response.statusCode === 'UNAUTHORIZED'
        ) {
          hasError = true;
          messages.push(t('error.session-expired'));
          isSessionExpired = true;
          setLogged(false);
        }
        if (!isError && response.status !== 200) {
          hasError = true;
        }
      } else if (typeof response === 'string') {
        hasError = hasError || response.toLocaleLowerCase().indexOf('error') > -1;
        messages.push(response);
      }

      if (isSessionExpired) {
        toastOptions.toastId = 'session-expired';
      }

      if (hasError && !messages.length) {
        messages.push(t('error.default'));
      }

      if (hasError) {
        messages.forEach((message) => {
          toast.error(message, toastOptions);
        });
      } else {
        messages.forEach((message) => {
          toast.success(message, toastOptions);
        });
      }
    },
    [t, i18n, setLogged]
  );
}
