// Make sure changes from this file are updated also in the $grid-breakpoints
export enum EBreakpoint {
  XS = 0,
  SM = 576,
  MD = 768,
  LG = 1050,
  XL = 1200,
  XXL = 1300,
  XXXL = 1600,
}
