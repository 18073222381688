import { faInfoCircle } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalAlert } from '@~components/modal-alert/ModalAlert';
import { FormattedNumber } from '@~components/formatted-number/FormattedNumber';
import { Table } from '@~components/table/Table';
import { convertCurrency } from '@~helpers/convertCurrency';
import { ECurrency } from '@~types/enums';
import type { IHeader, IRankRule } from '@~types/types';

const tableHeaders: IHeader[] = [
  { key: 'level', translateKey: 'pages.account.rank-system.content.level' },
  { key: 'rank', translateKey: 'pages.account.rank-system.content.rank' },
  { key: 'personal-turnover', translateKey: 'pages.account.rank-system.content.personal-turnover' },
  { key: 'main-branch-turnover', translateKey: 'pages.account.rank-system.content.main-branch-turnover' },
  { key: 'secondary-branch-turnover', translateKey: 'pages.account.rank-system.content.secondary-branch-turnover' },
  { key: 'other-branches-turnover', translateKey: 'pages.account.rank-system.content.other-branches-turnover' },
  { key: 'bonus', translateKey: 'pages.account.rank-system.content.bonus' },
];

interface IRanksTableModalProps {
  rankRules: IRankRule[];
}

export const RanksTableModal = memo(({ rankRules }: IRanksTableModalProps) => {
  const { t } = useTranslation();

  const [openModal, setOpenModal] = useState(false);

  const tableRows = useMemo(
    () =>
      rankRules.map((rule) => ({
        id: rule.name,
        cells: [
          rule.level,
          rule.name,
          <FormattedNumber
            key="personal-deposit"
            value={rule.personalTurnover}
            postfix={convertCurrency(ECurrency.USD)}
            className="tx-bold"
          />,
          <FormattedNumber
            key="main-branch-turnover"
            value={rule.mainBranchTurnover}
            postfix={convertCurrency(ECurrency.USD)}
            className="tx-bold"
          />,
          <FormattedNumber
            key="secondary-branch-turnover"
            value={rule.secondaryBranchTurnover}
            postfix={convertCurrency(ECurrency.USD)}
            className="tx-bold"
          />,
          <FormattedNumber
            key="other-branches-turnover"
            value={rule.otherBranchesTurnover}
            postfix={convertCurrency(ECurrency.USD)}
            className="tx-bold"
          />,
          <FormattedNumber
            key="bonus"
            value={rule.rankBonus}
            postfix={convertCurrency(ECurrency.USD)}
            className="tx-bold"
          />,
        ],
      })),
    [rankRules]
  );

  return (
    <>
      <FontAwesomeIcon
        icon={faInfoCircle}
        className="tx-primary interactive"
        size="2x"
        onClick={() => setOpenModal(true)}
        title={`${t('pages.account.rank-system.content.ranks-info')}`}
      />

      <ModalAlert
        key="modal-for-ranks-table"
        proceed={setOpenModal}
        show={openModal}
        title={t('pages.account.rank-system.content.ranks-info') + ''}
        cancelLabel={`${t('common.action.close')}`}
        size="xl"
        noOk
        enableEscape
        content={
          <div className="mb-3">
            <Table id="ranks-table" headers={tableHeaders} rows={tableRows} rowsPerPage={rankRules.length} />
          </div>
        }
      />
    </>
  );
});
