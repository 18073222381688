import { faEnvelope } from '@fortawesome/pro-regular-svg-icons/faEnvelope';
import { useAtom } from 'jotai';
import { memo, useCallback, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link, Navigate } from 'react-router-dom';

import { usernameRx } from '@~constants';
import { IconWithLoading } from '@~components/icon-with-loading/IconWithLoading';
import { ParticlesBackground } from '@~components/particles-background/ParticlesBackground';
import { Translate } from '@~components/translate/Translate';
import { useResponseHandler } from '@~hooks/useResponseHandler';
import { resetPasswordToken } from '@~network/account';
import { loggedAtom } from '@~store/account.store';
import { EAccountLink } from '@~types/navigationEnums';

export const PasswordRecoveryPage = memo(() => {
  const { t } = useTranslation();

  const [isLoggedIn] = useAtom(loggedAtom);

  const [isRequestSent, setRequestSent] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [loginValue, setLoginValue] = useState('');

  const handleResponse = useResponseHandler();

  const isLoginValid = useCallback(() => {
    if (!loginValue) {
      handleResponse(t('error.empty-login') + '', true);
      return false;
    }

    if (loginValue.length < 4 || !usernameRx.test(loginValue)) {
      handleResponse(t('error.wrong-login') + '', true);
      return false;
    }

    return true;
  }, [loginValue, handleResponse, t]);

  const submitForm = useCallback(() => {
    if (!isLoginValid()) {
      return;
    }

    setLoading(true);

    resetPasswordToken(loginValue)
      .then((response) => {
        if (response.success) {
          setLoginValue('');
          setRequestSent(true);
        } else {
          handleResponse(response);
        }
        setLoading(false);
      })
      .catch((response) => {
        handleResponse(response.response.data);
        setLoading(false);
      });
  }, [loginValue, isLoginValid, handleResponse]);

  if (isLoggedIn) {
    return <Navigate replace to={EAccountLink.ROOT} />;
  }

  return (
    <>
      <ParticlesBackground />
      <Helmet>
        <title>Aurous | {t('pages.password-recovery.title')}</title>
      </Helmet>
      <div id="password-recovery" className="page flex-1 p-4 p-md-5 pt-navbar">
        <div className="container">
          {!isRequestSent && (
            <div id="password-recovery-form" className="centered-block">
              <h1 className="mb-4 text-center tx-uppercase">
                <Translate i18nKey="pages.password-recovery.content.heading" />
              </h1>

              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  submitForm();
                }}
                className="card mb-3"
              >
                <h5 className="tx-16 mx-3 my-4">{t('pages.password-recovery.content.subheading')}</h5>

                <div className="form-group mb-6">
                  <div className="ms-3 tx-18 mb-1">{t('pages.password-recovery.content.login-label')}</div>
                  <input
                    type="text"
                    className="form-control form-control-lg mb-2"
                    placeholder={t('pages.password-recovery.content.login-placeholder') + ''}
                    value={loginValue}
                    id="login"
                    onChange={(event) => setLoginValue(event.target.value)}
                    disabled={isLoading}
                  />
                </div>

                <div className="text-center mb-4">
                  <button className="btn btn-lg btn-primary px-5" type="submit" disabled={isLoading}>
                    <IconWithLoading icon={faEnvelope} className="me-2" isLoading={isLoading} />
                    {t('pages.password-recovery.content.button-send-request')}
                  </button>
                </div>
              </form>

              <div className="card py-3">
                <div className="text-center">
                  {t('pages.login.content.no-account')}{' '}
                  <Link to={EAccountLink.REGISTER}>{t('pages.login.content.register')}</Link>
                </div>
              </div>
            </div>
          )}
          {isRequestSent && (
            <div id="request-sent" className="centered-block">
              <div className="card mb-3">
                <h1 className="mb-4 text-center tx-uppercase">
                  <Translate i18nKey="pages.password-recovery.content.heading" />
                </h1>
                <h5 className="text-center tx-18 mx-3 mb-4">{t('pages.password-recovery.content.success-request')}</h5>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
});
