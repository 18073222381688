import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CopyButton } from '@~components/copy-button/CopyButton';
import { FormattedDate } from '@~components/formatted-date/FormattedDate';
import { FormattedNumber } from '@~components/formatted-number/FormattedNumber';
import { Table } from '@~components/table/Table';
import { mapCurrency } from '@~helpers/mapCurrency';
import { useResponseHandler } from '@~hooks/useResponseHandler';
import { getTransactions } from '@~network/account';
import { TransactionDetails } from '@~components/transactions-table/components/TransactionDetails';
import { ECurrency, ETableView, ETransactionStatus, ETransactionType } from '@~types/enums';
import { IHeader, ITransaction } from '@~types/types';

const tableHeaders: IHeader[] = [
  { key: 'date', translateKey: 'pages.account.operations.content.header.date' },
  { key: 'type', translateKey: 'pages.account.operations.content.header.type' },
  { key: 'amount', translateKey: 'pages.account.operations.content.header.amount' },
  { key: 'id', translateKey: 'pages.account.operations.content.header.id' },
  { key: 'status', translateKey: 'pages.account.operations.content.header.status' },
  { key: 'details', translateKey: 'pages.account.operations.content.header.details' },
];

interface ITransactionTableProps {
  tableView: ETableView;
  transactionTypes: ETransactionType[];
}

export const TransactionTable = memo(({ tableView, transactionTypes }: ITransactionTableProps) => {
  const { t } = useTranslation();

  const handleResponse = useResponseHandler();

  const [transactions, setTransactions] = useState<ITransaction[]>([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isLoaded, setLoaded] = useState(false);

  const sentForTransactionsRef = useRef(false);

  const fetchTransactions = useCallback(
    (pageNr = 1, types: ETransactionType[] = []) => {
      if (sentForTransactionsRef.current) {
        return;
      }

      setLoading(true);
      sentForTransactionsRef.current = true;

      getTransactions(pageNr, undefined, types)
        .then((response) => {
          setTransactions(response.content);
          setTotal(response.totalElements);
        })
        .catch((response) => {
          handleResponse(response.response.data);
        })
        .finally(() => {
          sentForTransactionsRef.current = false;
          setLoading(false);
          setLoaded(true);
        });
    },
    [handleResponse]
  );

  const handlePageChange = useCallback((pageNr: number) => {
    setPage(pageNr);
  }, []);

  const tableRows = useMemo(
    () =>
      transactions.map((transaction) => {
        let transactionStatus = t('pages.account.operations.content.status.' + transaction.status);
        if (
          transaction.type === ETransactionType.WITHDRAWAL &&
          transaction.status === ETransactionStatus.COMPLETE &&
          !transaction.metadata?.withdrawalConfirmed
        ) {
          transactionStatus = t('pages.account.operations.content.status.IN_PROGRESS');
        }

        return {
          id: transaction.id,
          cells: [
            <FormattedDate key="date" date={transaction.creationTimestamp} />,
            t('pages.account.operations.content.type.' + transaction.type),
            <>
              {transaction.operations.map((operation) => (
                <div key={`transaction-${transaction.id}-operation-${operation.id}`} className="tx-bold">
                  <FormattedNumber
                    value={operation.balanceDiff}
                    floor
                    withSign
                    // postfix={convertCurrency(operation.currency as ECurrency)}
                    postfix={t(mapCurrency(operation.currency as ECurrency)) + ''}
                    className="d-block"
                  />
                  {![ECurrency.ETHI_USD, ECurrency.USD].includes(operation.currency as ECurrency) && (
                    <FormattedNumber
                      value={operation.balanceDiffUsd}
                      suffix="≈$"
                      floor
                      className="d-block tx-normal tx-14 tx-italic"
                    />
                  )}
                </div>
              ))}
            </>,
            <CopyButton
              key="transaction-id"
              text={transaction.id}
              element={<span>{transaction.id.split('-')[0]}</span>}
            />,
            transactionStatus,
            <TransactionDetails key="details" entry={transaction} />,
          ],
        };
      }),
    [transactions, t]
  );

  useEffect(() => {
    fetchTransactions(page, transactionTypes);
  }, [fetchTransactions, page, transactionTypes]);

  useEffect(() => {
    setPage(0);
  }, [transactionTypes]);

  return (
    <Table
      id="transaction-table"
      tableView={tableView}
      headers={tableHeaders}
      rows={tableRows}
      isLoading={loading}
      dataLoaded={isLoaded}
      total={total}
      handlePageChange={handlePageChange}
      rowsPerPage={10}
      noDataTranslationKey="pages.account.operations.content.no-transactions"
    />
  );
});
