import BigNumber from 'bignumber.js';
import classNames from 'classnames';
import { memo } from 'react';

import { Placeholder } from '@~components/placeholder/Placeholder';
import { numberWithCommas } from '@~helpers/numbers';

interface IFormattedNumberProps {
  value?: string | number | null;
  decimals?: number;
  floor?: boolean;
  className?: string;
  withSign?: boolean;
  postfix?: string;
  postfixClass?: string;
  suffix?: string;
  suffixClass?: string;
  subZeros?: boolean;
  onClick?: () => void;
}

export const FormattedNumber = memo((props: IFormattedNumberProps) => {
  const {
    value: originalValue,
    decimals = 2,
    floor,
    className,
    withSign,
    postfix,
    postfixClass,
    suffix,
    suffixClass,
    subZeros = false,
    onClick,
  } = props;

  if (originalValue === undefined || originalValue === null) {
    return (
      <span className={className}>
        <Placeholder />
      </span>
    );
  }

  const value = new BigNumber(originalValue).absoluteValue().toNumber();

  return (
    <span className={classNames('notranslate', className)} onClick={onClick}>
      {!!suffix && <span className={suffixClass}>{suffix}</span>}

      {withSign && <>{originalValue >= 0 ? '+ ' : '- '}</>}
      {!withSign && originalValue < 0 ? '-' : ''}
      {numberWithCommas(value, floor, decimals, subZeros)}
      {!!postfix && (
        <span className={postfixClass}>
          {['$', '%'].some((v) => postfix.includes(v)) ? '' : ' '}
          {postfix}
        </span>
      )}
    </span>
  );
});
