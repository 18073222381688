import { useAtom } from 'jotai';
import { useCallback } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Flag from 'react-world-flags';

import { selectedLanguageAtom } from '@~store/app.store';
import { ELanguage } from '@~types/enums';
import type { ILanguageMeta } from '@~types/types';

export interface ILanguageSwitcherButtonProps {
  languageMeta: ILanguageMeta;
  onClick?: () => void;
}

export const LanguageSwitcherButton = ({ languageMeta, onClick }: ILanguageSwitcherButtonProps) => {
  const [selectedLanguage, setSelectedLanguage] = useAtom(selectedLanguageAtom);
  const { i18n } = useTranslation();

  const changeLanguage = useCallback(
    (lang: ELanguage) => {
      setSelectedLanguage(lang);
      i18n.changeLanguage(lang);
    },
    [i18n, setSelectedLanguage]
  );

  return (
    <Dropdown.Item
      eventKey={languageMeta.id}
      active={selectedLanguage === languageMeta.lang}
      onClick={() => {
        changeLanguage(languageMeta.id);
        if (onClick) {
          onClick();
        }
      }}
      className="notranslate"
    >
      <Flag code={languageMeta.flag} width="20" height="16" className="me-3" />
      {languageMeta.name}
    </Dropdown.Item>
  );
};
