import { useAtom } from 'jotai';
import { memo, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useQuery } from '@~hooks/useQuery';
import { refIdAtom } from '@~store/account.store';

export const ReferralApplier = memo(() => {
  const [, setRefId] = useAtom(refIdAtom);

  const navigate = useNavigate();
  const location = useLocation();

  const query = useQuery();

  useEffect(() => {
    const refId = query.get('ref');
    if (refId) {
      setRefId(refId);

      query.delete('ref');

      const newQuery = query.toString();
      const paramsStr = newQuery ? `?${newQuery}` : '';
      navigate(`${location.pathname}${paramsStr}`, { replace: true });
    }
  }, [setRefId, navigate, query, location]);

  return null;
});
