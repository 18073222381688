import classNames from 'classnames';
import { useAtom } from 'jotai';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { Logo } from '@~components/logo/Logo';
import { LanguageSwitcher } from '@~components/language-switcher/LanguageSwitcher';
import { useIsVisible } from '@~hooks/useIsVisible';
import { loggedAtom } from '@~store/account.store';
import { EAccountLink, ELandingLink } from '@~types/navigationEnums';

import styles from './Header.module.scss';

const COLLAPSE_ID = 'navbarNavDropdown';

export const Header = () => {
  const toggleButtonRef = useRef<HTMLButtonElement>(null);

  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const isButtonVisible = useIsVisible(toggleButtonRef);

  const [isLoggedIn, setLogged] = useAtom(loggedAtom);

  const [isOpenMenu, setOpenMenu] = useState(false);
  const [isTransparent, setTransparent] = useState(true);

  const isHome = location.pathname === ELandingLink.HOME;

  const toggleNav = useCallback(() => {
    setOpenMenu((prev) => !prev);
  }, []);

  const closeMenu = useCallback(() => {
    setOpenMenu(false);
  }, []);

  const changeBackground = useCallback(() => {
    setTransparent(window.scrollY < 25);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', changeBackground);
    return () => {
      window.removeEventListener('scroll', changeBackground);
    };
  }, [changeBackground]);

  useEffect(() => {
    if (isHome) {
      const element = document.querySelector(location.hash || '#main');
      if (element) {
        const yCoordinate = element.getBoundingClientRect().top + window.scrollY;
        const yOffset = -100;
        window.scrollTo({
          top: yCoordinate + yOffset,
          behavior: 'smooth',
        });
      }
    }
  }, [isHome, location.hash]);

  const handleLogout = useCallback(() => {
    setLogged(false);
    setOpenMenu(false);
    navigate(ELandingLink.HOME);
  }, [setLogged, navigate]);

  const landingBlocks = useMemo(
    () => [
      {
        id: 'about-us',
        title: t('global.navigation.about'),
      },
      {
        id: 'ecosystem',
        title: t('global.navigation.ecosystem'),
      },
      {
        id: 'road-map',
        title: t('global.navigation.road-map'),
      },
      {
        id: 'partners',
        title: t('global.navigation.partners'),
      },
      {
        id: 'news-slider',
        title: t('global.navigation.news'),
      },
      {
        id: 'faq',
        title: t('global.navigation.faq'),
      },
    ],
    [t]
  );

  const isBlurred = !isTransparent || (isButtonVisible && isOpenMenu);

  return (
    <nav
      className={classNames(styles.root, 'navbar fixed-top navbar-expand-lg', {
        [styles.transparent]: !isBlurred,
      })}
      id="navbar"
    >
      <div className="fluid-container px-2 px-md-3 px-lg-4 flex-wrap">
        {!isHome && (
          <Link to={ELandingLink.HOME} className="navbar-brand" onClick={closeMenu}>
            <Logo />
          </Link>
        )}
        {isHome && (
          <Link
            to={`${ELandingLink.HOME}#main`}
            className={classNames(styles.navLink, 'navbar-brand')}
            onClick={closeMenu}
          >
            <Logo />
          </Link>
        )}

        <div
          className={classNames('collapse navbar-collapse d-lg-flex justify-content-end order-last order-lg-0', {
            show: isOpenMenu,
          })}
          id={COLLAPSE_ID}
        >
          <ul className="navbar-nav mw-lg-auto me-auto me-lg-2">
            {!isHome && (
              <li className="nav-item text-center" key="nav-link-home">
                <Link to={ELandingLink.HOME} className="nav-link" onClick={closeMenu}>
                  {t('global.navigation.to-home')}
                </Link>
              </li>
            )}
            {landingBlocks.map((block) => (
              <li className="nav-item text-center" key={'nav-link-' + block.id}>
                <Link
                  to={`${ELandingLink.HOME}#${block.id}`}
                  className={classNames(styles.navLink, 'nav-link')}
                  onClick={closeMenu}
                >
                  {block.title}
                </Link>
              </li>
            ))}
            {isHome && isLoggedIn && (
              <li className="nav-item text-center" key="nav-link-account">
                <Link to={EAccountLink.ROOT} className="nav-link" onClick={closeMenu}>
                  {t('global.navigation.account')}
                </Link>
              </li>
            )}
            <li className="nav-item text-center" key={'nav-link-auth'}>
              {!isLoggedIn && (
                <Link className={classNames(styles.navLink, 'nav-link')} to={EAccountLink.LOGIN} onClick={closeMenu}>
                  {t('global.auth.login')}
                </Link>
              )}
              {isLoggedIn && (
                <button className={classNames(styles.navLink, 'nav-link btn btn-link')} onClick={handleLogout}>
                  {t('global.auth.logout')}
                </button>
              )}
            </li>
          </ul>

          <div className="side-nav ml-lg-2 d-none d-lg-block">
            <LanguageSwitcher />
          </div>
        </div>

        <div className="d-inline-block d-lg-none flex-1 tx-right">
          <button
            className={classNames('navbar-toggler', { opened: isButtonVisible && isOpenMenu })}
            type="button"
            aria-controls={COLLAPSE_ID}
            aria-label="Toggle navigation"
            onClick={toggleNav}
            ref={toggleButtonRef}
          >
            <span className="navbar-toggler-span navbar-toggler-span-top">
              <span />
            </span>
            <span className="navbar-toggler-span navbar-toggler-span-bottom">
              <span />
            </span>
            <span className="navbar-toggler-icon" />
          </button>
        </div>

        <div className="d-inline-block d-lg-none">
          <div className="side-nav mt-4">
            <LanguageSwitcher />
          </div>
        </div>
      </div>
    </nav>
  );
};
