import { Provider as JotaiProvider } from 'jotai';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import { StateProvider } from '@~context/StateContext';

import { App } from './App';
import './i18n';

import 'react-toastify/dist/ReactToastify.css';
import '@~styles/index.scss';

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);
  root.render(
    <StrictMode>
      <JotaiProvider>
        <StateProvider>
          <HelmetProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </HelmetProvider>
        </StateProvider>
      </JotaiProvider>
    </StrictMode>
  );
}
