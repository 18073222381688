import classNames from 'classnames';
import { memo } from 'react';

import { Translate } from '@~components/translate/Translate';

import morganSpaceLogo from '@~assets/images/partners/logo-morgan-space.png';
import jinpengLogo from '@~assets/images/partners/logo-jinpeng.png';
import xinhaiLogo from '@~assets/images/partners/logo-xinhai.png';
import sudanLogo from '@~assets/images/partners/logo-sudan.png';

import styles from './Partners.module.scss';

const partnersLogo = [
  {
    id: 'morganSpace',
    image: morganSpaceLogo,
    title: 'Morgan Space',
  },
  {
    id: 'jinpeng',
    image: jinpengLogo,
    title: 'Jinpeng',
  },
  {
    id: 'xinhai',
    image: xinhaiLogo,
    title: 'Xinhai',
  },
  {
    id: 'sudan',
    image: sudanLogo,
    title: 'Sudan',
  },
];

export const Partners = memo(() => {
  return (
    <section className="pt-lg-7 pb-lg-4 pt-md-2 pb-md-4 pt-sm-4 pb-sm-4 pt-4 pb-4" id="partners">
      <div className="container">
        <h2 className="mb-5">
          <Translate i18nKey={'pages.home.content.partners.title'} />
        </h2>

        <div
          className={classNames(
            'd-flex justify-content-around justify-content-lg-between flex-wrap',
            styles.partnersList
          )}
        >
          {partnersLogo.map(({ id, image, title }) => (
            <div className="card flex-1 ht-100 ht-md-120 mb-4 p-0" key={id}>
              <div className="card-body d-flex justify-content-center align-items-center py-0">
                <img src={image} alt={title} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
});
