import type { ReactNode } from 'react';
import { useMemo } from 'react';

import { genericMemo } from '@~helpers/genericMemo';
import classNames from 'classnames';

export interface ISwitcherOption<T> {
  value: T;
  label: ReactNode;
}

interface IRadioSwitcherProps<T> {
  id: string;
  options: ISwitcherOption<T>[];
  handleValueChange: (value: T) => void;
  selectedValue?: T | null;
  onlyIcons?: boolean;
}

function RadioSwitcherComponent<T>({
  id,
  options,
  selectedValue,
  handleValueChange,
  onlyIcons,
}: IRadioSwitcherProps<T>) {
  const selected = useMemo(() => (selectedValue ? selectedValue : options[0]), [options, selectedValue]);
  return (
    <div className={classNames('switch-field', [{ 'only-icons': !!onlyIcons }])}>
      {options.map(({ value, label }) => (
        <span key={`radio-${value}`}>
          <input
            type="radio"
            id={value as string}
            name={id}
            value={value as string}
            checked={selected === value}
            onChange={() => handleValueChange(value)}
          />
          <label htmlFor={value as string}>{label}</label>
        </span>
      ))}
    </div>
  );
}

export const RadioSwitcher = genericMemo(RadioSwitcherComponent);
