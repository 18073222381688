import { faAngleDown, faChevronRight, faBars } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { memo, useMemo } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import { useBreakpoint } from '@~hooks/useBreakpoint';
import { accountPages } from '@~navigation/pages';
import { EAccountLink } from '@~types/navigationEnums';
import { EBreakpoint } from '@~styles/breakpoints';

export const AccountNavigation = memo(() => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const breakpoint = useBreakpoint();

  const currentPage = useMemo(
    () => accountPages.find((page) => location.pathname.indexOf(page.path) > 0) || accountPages[0],
    [location.pathname]
  );

  if (breakpoint < EBreakpoint.SM) {
    return (
      <div className="p-0 d-flex justify-content-between align-items-center">
        <div className="d-inline-flex align-items-center gap-2 pl-2">
          <span className="icon-holder">{currentPage.icon}</span>
          <span className="text">
            {t(currentPage.title)} {currentPage.badge}
          </span>
        </div>
        <Dropdown id="account-mobile-navigation" align="end">
          <Dropdown.Toggle variant="transparent" className="card-block p-3 account-menu-select notranslate">
            <FontAwesomeIcon icon={faBars} />
            <FontAwesomeIcon icon={faAngleDown} className="caret" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {accountPages.map((page) => (
              <Dropdown.Item
                key={page.id}
                className={classNames('d-inline-flex gap-4', { active: page.id === currentPage.id })}
                onClick={() => navigate(`${page.path}${location.hash}`)}
              >
                <span className="icon-holder">{page.icon}</span>
                <span className="text">
                  {t(page.title)} {page.badge}
                </span>
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }

  return (
    <div id="account-navigation">
      {accountPages.map((page) => (
        <NavLink to={`${page.path}${location.hash}`} key={page.id} className="account-link">
          {({ isActive }) => {
            const isDepositPage = location.pathname === EAccountLink.ROOT && page.path === EAccountLink.DEPOSIT;
            return (
              <button
                className={classNames('btn sub-menu-item', isActive || isDepositPage ? 'btn-primary' : 'btn-secondary')}
              >
                <div className="label">
                  <span className="icon-holder">{page.icon}</span>
                  <span className="text">
                    {t(page.title)}
                    {page.badge}
                  </span>
                </div>
                <div className="action-icon">
                  <FontAwesomeIcon icon={faChevronRight} />
                </div>
              </button>
            );
          }}
        </NavLink>
      ))}
    </div>
  );
});
