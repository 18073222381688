import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

import { PromoTurkeyMay2023 } from '@~pages/account/promo-actions/promos/PromoTurkeyMay2023';
import { PromoCappadociaSep2023 } from '@~pages/account/promo-actions/promos/PromoCappadociaSep2023';

export const PromoActions = memo(() => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>Aurous | {t('pages.account.promo-actions.title')}</title>
      </Helmet>
      <div id="promo-actions">
        <div>
          <h2 className="heading tx-uppercase mb-6">{t('pages.account.promo-actions.content.heading')}</h2>
        </div>
        <div>
          <PromoCappadociaSep2023 />
          <PromoTurkeyMay2023 />
        </div>
      </div>
    </>
  );
});
