import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faTriangleExclamation } from '@fortawesome/pro-duotone-svg-icons';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalAlert } from '@~components/modal-alert/ModalAlert';
import { Translate } from '@~components/translate/Translate';

export const PromoCappadociaSep2023Modal = memo(() => {
  const { t } = useTranslation();

  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <FontAwesomeIcon
        icon={faInfoCircle}
        className="me-2 interactive"
        size="2x"
        onClick={() => setOpenModal(true)}
        title={`${t('common.information')}`}
      />

      <ModalAlert
        key="modal-for-promo-cappadocia-info"
        proceed={setOpenModal}
        show={openModal}
        title={t('pages.account.promo-actions.content.promo-cappadocia-sep-2023.modal.title') + ''}
        cancelLabel={`${t('common.action.close')}`}
        centered={false}
        size="lg"
        noOk
        enableEscape
        content={
          <div className="modal-content-bg">
            <p className="tx-bold">{t('pages.account.promo-actions.content.promo-cappadocia-sep-2023.modal.text.1')}</p>
            <p className="my-4">{t('pages.account.promo-actions.content.promo-cappadocia-sep-2023.modal.text.2')}</p>
            <p>
              <Translate i18nKey={'pages.account.promo-actions.content.promo-cappadocia-sep-2023.modal.text.3-html'} />
            </p>
            <p className="mt-5">
              {t('pages.account.promo-actions.content.promo-cappadocia-sep-2023.modal.text.4-availabilities.title')}
            </p>
            <p>
              <ul>
                <li>
                  {t('pages.account.promo-actions.content.promo-cappadocia-sep-2023.modal.text.4-availabilities.1')}
                </li>
                <li>
                  {t('pages.account.promo-actions.content.promo-cappadocia-sep-2023.modal.text.4-availabilities.2')}
                </li>
                <li>
                  {t('pages.account.promo-actions.content.promo-cappadocia-sep-2023.modal.text.4-availabilities.3')}
                </li>
                <li>
                  {t('pages.account.promo-actions.content.promo-cappadocia-sep-2023.modal.text.4-availabilities.4')}
                </li>
                <li>
                  {t('pages.account.promo-actions.content.promo-cappadocia-sep-2023.modal.text.4-availabilities.5')}
                </li>
                <li>
                  {t('pages.account.promo-actions.content.promo-cappadocia-sep-2023.modal.text.4-availabilities.6')}
                </li>
              </ul>
            </p>
            <p className="d-flex text-center mt-5">
              <FontAwesomeIcon icon={faTriangleExclamation} className="tx-primary me-3" size="2x" />
              {t('pages.account.promo-actions.content.promo-cappadocia-sep-2023.modal.text.5')}
              <FontAwesomeIcon icon={faTriangleExclamation} className="tx-primary ms-3" size="2x" />
            </p>
          </div>
        }
      />
    </>
  );
});
