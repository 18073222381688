import { useTranslation } from 'react-i18next';
import { memo, useEffect, useMemo, useRef, useState } from 'react';

import promoImage from '@~assets/promos/turkey-2023.png';
import { ReactComponent as TermsTurnoverIcon } from '@~assets/promos/terms-turnover.svg';
import { ReactComponent as TermsMoneyIcon } from '@~assets/promos/terms-money.svg';
import { ReactComponent as RewardIcon } from '@~assets/promos/award-1.svg';

import { FormattedNumber } from '@~components/formatted-number/FormattedNumber';
import { FormattedDate } from '@~components/formatted-date/FormattedDate';
import { Preloader } from '@~components/preloader/Preloader';
import { Translate } from '@~components/translate/Translate';
import { convertCurrency } from '@~helpers/convertCurrency';
import { useResponseHandler } from '@~hooks/useResponseHandler';
import { getPromoTurkey } from '@~network/account';
import { PromoTurkeyMay2023Modal } from '@~pages/account/promo-actions/modals/PromoTurkeyMay2023Modal';
import { ECurrency, EPromoStatus } from '@~types/enums';
import { IPromoTurkeyData } from '@~types/requests';

export const PromoTurkeyMay2023 = memo(() => {
  const { t } = useTranslation();

  const handleResponse = useResponseHandler();

  const [isLoading, setIsLoading] = useState(false);
  const [promoData, setPromoData] = useState<IPromoTurkeyData>();

  const requestSentRef = useRef(false);

  useEffect(() => {
    if (requestSentRef.current) {
      return;
    }

    requestSentRef.current = true;
    setIsLoading(true);
    getPromoTurkey()
      .then((response) => {
        if (response.success && response.data) {
          setPromoData(response.data);
        } else {
          handleResponse(response);
        }
      })
      .catch((response) => {
        handleResponse(response.response.data);
      })
      .finally(() => {
        setIsLoading(false);
        requestSentRef.current = false;
      });
  }, [handleResponse]);

  const promoEndTime = useMemo(() => {
    if (!promoData) {
      return new Date();
    }
    const startDate = new Date(promoData.promoStartFrom);
    startDate.setMonth(startDate.getMonth() + 1);
    return startDate;
  }, [promoData]);

  const teamTurnover = useMemo(() => {
    if (!promoData) {
      return 0;
    }
    const firstLineTurnover = promoData.firstLineUserTurnovers.reduce(
      (accumulator, currentValue) => accumulator + currentValue.personalTurnoverInUsd,
      0
    );
    return promoData.personalTurnoverInUsd + firstLineTurnover;
  }, [promoData]);

  const turnoverPercentage = useMemo(() => {
    if (!promoData) {
      return 0;
    }
    const userTurnover = (teamTurnover / promoData.expectedTotalTurnover) * 100;
    return userTurnover > 100 ? 100 : userTurnover;
  }, [promoData, teamTurnover]);

  return (
    <div className="card-block my-3">
      <div className="d-flex justify-content-between flex-wrap gap-3">
        <div className="plate-text image-block d-flex flex-column flex-1">
          <div className="d-flex justify-content-between">
            <h3 className="d-flex align-items-center gap-3 tx-uppercase">
              {t('pages.account.promo-actions.content.promo-turkey-may-2023.heading')}
            </h3>
            <div>
              <PromoTurkeyMay2023Modal />
            </div>
          </div>
          {promoData && (
            <div>
              <FormattedDate date={promoData.promoStartFrom} dateFormat="dd.MM" />
              {' - '}
              <FormattedDate date={promoEndTime} dateFormat="dd.MM" />
            </div>
          )}
          <div className="d-flex flex-1 justify-content-center align-items-center text-center">
            <img
              src={promoImage}
              alt={t('pages.account.promo-actions.content.promo-turkey-may-2023.intro') + ''}
              className="pt-4"
            />
          </div>
        </div>
        <div className="details-block flex-1 mt-2">
          <div className="mb-4">
            <h5 className="tx-uppercase tx-spacing-1 mb-3">{t('pages.account.promo-actions.content.turnover')}</h5>
            {isLoading && !promoData && (
              <div className="ht-150 d-flex justify-content-center align-items-center">
                <Preloader inline />
              </div>
            )}
            {promoData && (
              <>
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow={
                      promoData.promoStatus === EPromoStatus.APPLIED ? promoData.expectedTotalTurnover : teamTurnover
                    }
                    aria-valuemin={0}
                    aria-valuemax={promoData.expectedTotalTurnover}
                    style={{
                      width: `${promoData.promoStatus === EPromoStatus.APPLIED ? 100 : turnoverPercentage}%`,
                    }}
                  />
                  <div className="progress-text tx-16">
                    <FormattedNumber value={teamTurnover} postfix={convertCurrency(ECurrency.USD)} />
                  </div>
                </div>
                <div className="progress-text tx-14 tx-italic text-end">
                  {t('pages.account.promo-actions.content.turnover-max')}{' '}
                  <FormattedNumber value={promoData.expectedTotalTurnover} postfix={convertCurrency(ECurrency.USD)} />
                </div>
              </>
            )}
          </div>

          <div className="plate-text tx-bold tx-italic my-4 p-2 text-center">
            {t('pages.account.promo-actions.content.promo-turkey-may-2023.intro')}
          </div>
          <div className="mb-4">
            <h5 className="tx-uppercase tx-spacing-1 mb-3">{t('pages.account.promo-actions.content.rewards')}</h5>
            <div className="plate-text d-flex align-items-center p-3">
              <div className="pe-3">
                <RewardIcon width="40" height="40" />
              </div>
              <div>
                <Translate i18nKey="pages.account.promo-actions.content.promo-turkey-may-2023.reward-1" />
              </div>
            </div>
            <div className="plate-text d-flex align-items-center p-3 mt-3">
              <div className="pe-3">
                <RewardIcon width="40" height="40" />
              </div>
              <div>
                <Translate i18nKey="pages.account.promo-actions.content.promo-turkey-may-2023.reward-2" />
              </div>
            </div>
          </div>
          <div className="mb-4">
            <h5 className="tx-uppercase tx-spacing-1 mb-3">{t('pages.account.promo-actions.content.terms')}</h5>
            <div className="plate-text d-flex align-items-center p-3">
              <div className="pe-3">
                <TermsMoneyIcon width="40" height="40" />
              </div>
              <div>
                <Translate i18nKey="pages.account.promo-actions.content.promo-turkey-may-2023.term-1" />
              </div>
            </div>
            <div className="plate-text d-flex align-items-center p-3 mt-3">
              <div className="pe-3">
                <TermsTurnoverIcon width="40" height="40" />
              </div>
              <div>
                <Translate i18nKey="pages.account.promo-actions.content.promo-turkey-may-2023.term-2" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {promoData && (
        <div className="mt-3 text-center">
          {(promoData.promoStatus === EPromoStatus.FINISHED || promoData.promoStatus === EPromoStatus.ACTIVE) &&
            t('pages.account.promo-actions.content.promo-turkey-may-2023.promo-finished')}
          {promoData.promoStatus === EPromoStatus.APPLIED &&
            t('pages.account.promo-actions.content.promo-turkey-may-2023.promo-applied')}
        </div>
      )}
    </div>
  );
});
