import { memo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const ClearLocalStorageApplier = memo(() => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash === '#clearmydirtybrowser') {
      localStorage.clear();
    }
  }, [location]);

  return null;
});
