import { memo, useEffect, useRef, useState } from 'react';
import QRCodeStyling, { Options } from 'qr-code-styling';

import { defaultOptions } from '@~components/qrcode/options/defaultOptions';

interface IQrCodeProps {
  options: Options;
}

export const QrCode = memo(({ options }: IQrCodeProps) => {
  const [qrCode] = useState<QRCodeStyling>(new QRCodeStyling(defaultOptions));

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      qrCode.append(ref.current);
    }
  }, [qrCode]);

  useEffect(() => {
    if (!qrCode || !options) {
      return;
    }
    qrCode.update({
      ...defaultOptions,
      ...options,
    });
  }, [qrCode, options]);

  return <div ref={ref} className="qrcode-holder" />;
});
