import { useAtom } from 'jotai';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { FormattedNumber } from '@~components/formatted-number/FormattedNumber';
import { getPercentage } from '@~helpers/numbers';
import { tokenBankAtom } from '@~store/account.store';
import type { IInvestPlan } from '@~types/types';
import { ECurrency } from '@~types/enums';
import { convertCurrency } from '@~helpers/convertCurrency';

interface IAftInvestPlanProps {
  planData?: IInvestPlan;
}

export const AftInvestPlan = memo(({ planData }: IAftInvestPlanProps) => {
  const { t } = useTranslation();

  const [tokenBank] = useAtom(tokenBankAtom);

  const percentage = getPercentage(planData?.paidAufPassive || 0, planData?.maxAufProfit || 0) || 0;

  if (!planData) {
    return null;
  }

  return (
    <div className="card-block d-block d-xxl-flex justify-content-between align-items-center my-3">
      <div className="d-flex justify-content-center justify-content-md-start flex-column flex-sm-row align-items-center align-items-sm-start gap-4 mb-3 mb-xxxl-0 text-center text-sm-start">
        <div>
          <div className="label tx-uppercase">{t('pages.account.staking.content.invested')}</div>
          <div className="value">
            <FormattedNumber value={planData.totalAufInvested} postfix={convertCurrency(ECurrency.AUF)} />
            {planData.totalAufInvested > 0 && tokenBank && (
              <FormattedNumber
                className="d-block tx-normal tx-14 tx-italic"
                value={planData.totalAufInvested * tokenBank.aufPrice}
                suffix="≈$"
                floor
              />
            )}
          </div>
          <div className="value">
            <FormattedNumber value={planData.totalUsdInvested} postfix={convertCurrency(ECurrency.USD)} />
          </div>
        </div>
        <div>
          <div className="label tx-uppercase">{t('pages.account.staking.content.stake-balance')}</div>
          <div className="value">
            <FormattedNumber value={planData.aufInvestmentBody} postfix={convertCurrency(ECurrency.AUF)} />
            {planData.aufInvestmentBody > 0 && tokenBank && (
              <FormattedNumber
                className="d-block tx-normal tx-14 tx-italic"
                value={planData.aufInvestmentBody * tokenBank.aufPrice}
                suffix="≈$"
                floor
              />
            )}
          </div>
        </div>
        <div>
          <div className="label tx-uppercase">{t('pages.account.staking.content.paid')}</div>
          <div className="value">
            <FormattedNumber value={planData.paidAufPassive} postfix={convertCurrency(ECurrency.AUF)} />
            {planData.paidAufPassive > 0 && tokenBank && (
              <FormattedNumber
                className="d-block tx-normal tx-14 tx-italic"
                value={planData.paidAufPassive * tokenBank.aufPrice}
                suffix="≈$"
                floor
              />
            )}
          </div>
          <div className="value">
            <FormattedNumber value={planData.paidAufRef} postfix={convertCurrency(ECurrency.AUF)} />
            {planData.paidAufRef > 0 && tokenBank && (
              <FormattedNumber
                className="d-block tx-normal tx-14 tx-italic"
                value={planData.paidAufRef * tokenBank.aufPrice}
                suffix="≈$"
                floor
              />
            )}
          </div>
        </div>
      </div>
      <div>
        <div className="progress d-flex">
          <div
            className="progress-bar"
            role="progressbar"
            aria-valuenow={percentage}
            aria-valuemin={0}
            aria-valuemax={planData.maxAufProfit}
            style={{ width: `${percentage}%` }}
          />
          <div className="progress-text tx-16">{t('pages.account.staking.content.x3-factor')}</div>
        </div>
      </div>
    </div>
  );
});
