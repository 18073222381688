import { memo, useCallback } from 'react';
import Particles from 'react-tsparticles';
import { loadFull } from 'tsparticles';
import type { Engine, ISourceOptions } from 'tsparticles-engine';

import config from '@~assets/particles/stars-config.json';

import styles from './ParticlesBackground.module.scss';

export const ParticlesBackground = memo(() => {
  const particlesInit = useCallback(async (engine: Engine) => {
    await loadFull(engine);
  }, []);

  return (
    <div className={styles.root}>
      <Particles className={styles.particles} options={config as ISourceOptions} init={particlesInit} />
    </div>
  );
});
