import classNames from 'classnames';
import { useAtom } from 'jotai';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { FormattedNumber } from '@~components/formatted-number/FormattedNumber';
import { Translate } from '@~components/translate/Translate';
import { tokenBankAtom } from '@~store/account.store';

import styles from './AssetsPrice.module.scss';

export const AssetsPrice = memo(() => {
  const { t } = useTranslation();

  const [tokenBank] = useAtom(tokenBankAtom);

  return (
    <section className={classNames(styles.root, 'container-fluid bg-carbon mt-5')}>
      <div className="container">
        <div className="row pb-1 tx-center">
          <div className="col-md-4 justify-content-md-center tx-18 d-flex flex-column">
            <span className="pt-3">
              <Translate i18nKey={'pages.home.content.aft-asset-value'} />
            </span>
            <span className="tx-32 tx-lg-40 pt-md-1">
              <FormattedNumber value={tokenBank?.aufPrice} postfix={' $'} decimals={5} />
            </span>
          </div>

          <div className="col-md-4 justify-content-md-center tx-18 d-flex flex-column">
            <span className="pt-3">
              <Translate i18nKey={'pages.home.content.gat-asset-value'} />
            </span>
            <span className="tx-32 tx-lg-40 pt-md-1">
              <FormattedNumber value={tokenBank?.gatPrice} postfix={' $'} decimals={5} />
            </span>
          </div>

          <div className="col-md-4 tx-18 d-flex flex-column">
            <span className="pt-3">
              <Translate i18nKey={'pages.home.content.gold-price'} />
            </span>
            <span className="tx-24 tx-lg-30 pt-2">
              <FormattedNumber
                value={tokenBank?.gatPrice ? tokenBank.gatPrice * 100 : undefined}
                postfix={' $'}
                decimals={2}
              />
              <sub>/{t('common.gram')}</sub>
            </span>
          </div>
        </div>
      </div>
    </section>
  );
});
