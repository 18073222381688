import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/pro-regular-svg-icons/faEye';
import { faEyeSlash } from '@fortawesome/pro-regular-svg-icons/faEyeSlash';
import { faKey } from '@fortawesome/pro-duotone-svg-icons/faKey';
import { useAtom } from 'jotai';
import { memo, useCallback, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';

import { IconWithLoading } from '@~components/icon-with-loading/IconWithLoading';
import { ParticlesBackground } from '@~components/particles-background/ParticlesBackground';
import { Translate } from '@~components/translate/Translate';
import { useQuery } from '@~hooks/useQuery';
import { useResponseHandler } from '@~hooks/useResponseHandler';
import { changePasswordWithToken } from '@~network/account';
import { loggedAtom } from '@~store/account.store';
import { EAccountLink } from '@~types/navigationEnums';

export const PasswordResetPage = memo(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isLoggedIn] = useAtom(loggedAtom);

  const query = useQuery();
  const handleResponse = useResponseHandler();

  const [isLoading, setLoading] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isPasswordChanged, setPasswordChanged] = useState(false);

  const token = useMemo(() => query.get('token'), [query]);

  const isValidPasswordsInput = useCallback(() => {
    if (confirmPassword !== newPassword) {
      handleResponse(t('error.passwords-not-match') + '', true);
      return;
    }

    if (newPassword.length < 8 || newPassword.length > 40) {
      handleResponse(t('error.password-length') + '', true);
      return;
    }

    return true;
  }, [newPassword, confirmPassword, handleResponse, t]);

  const handlePasswordChange = useCallback(() => {
    if (!isValidPasswordsInput()) {
      return;
    }

    if (!token) {
      return;
    }

    setLoading(true);
    changePasswordWithToken(token, newPassword)
      .then((response) => {
        if (response.success) {
          setNewPassword('');
          setConfirmPassword('');
          handleResponse(t('pages.password-reset.content.password-changed') + '', false);
          setPasswordChanged(true);
          setTimeout(() => {
            navigate(EAccountLink.LOGIN);
          }, 5000);
        } else {
          handleResponse(response);
        }
        setLoading(false);
      })
      .catch((response) => {
        handleResponse(response.response.data);
        setLoading(false);
      });
  }, [token, newPassword, handleResponse, isValidPasswordsInput, t, navigate]);

  if (isLoggedIn) {
    return <Navigate replace to={EAccountLink.ROOT} />;
  }

  return (
    <>
      <ParticlesBackground />
      <Helmet>
        <title>Aurous | {t('pages.password-reset.title')}</title>
      </Helmet>
      <div id="password-reset" className="page flex-1 p-4 p-md-5 pt-navbar">
        <div className="container">
          {!isPasswordChanged && (
            <div id="password-reset-form" className="centered-block">
              <h1 className="mb-4 text-center">
                <Translate i18nKey="pages.password-reset.content.heading" />
              </h1>

              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handlePasswordChange();
                }}
                className="card mb-3"
              >
                <div className="form-group mb-4">
                  <div className="ms-3 tx-18 mb-1">{t('pages.password-reset.content.password-label')}</div>
                  <div className="input-group">
                    <input
                      type={showPassword ? 'text' : 'password'}
                      className="form-control form-control-lg"
                      placeholder={t('pages.password-reset.content.password-placeholder') + ''}
                      value={newPassword}
                      id="password"
                      onChange={(event) => setNewPassword(event.target.value)}
                      disabled={isLoading}
                    />
                    <div className="input-group-text">
                      <FontAwesomeIcon
                        icon={showPassword ? faEye : faEyeSlash}
                        role="button"
                        onClick={() => setShowPassword((prevState) => !prevState)}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group mb-6">
                  <div className="ms-3 tx-18 mb-1">{t('pages.password-reset.content.password-repeat-label')}</div>
                  <div className="input-group">
                    <input
                      type={showPassword ? 'text' : 'password'}
                      className="form-control form-control-lg"
                      placeholder={t('pages.password-reset.content.password-repeat-placeholder') + ''}
                      value={confirmPassword}
                      id="password-repeat"
                      onChange={(event) => setConfirmPassword(event.target.value)}
                      disabled={isLoading}
                    />
                    <div className="input-group-text">
                      <FontAwesomeIcon
                        icon={showPassword ? faEye : faEyeSlash}
                        role="button"
                        onClick={() => setShowPassword((prevState) => !prevState)}
                      />
                    </div>
                  </div>
                </div>

                <div className="text-center mb-4">
                  <button className="btn btn-lg btn-primary px-5" type="submit" disabled={isLoading}>
                    <IconWithLoading icon={faKey} className="me-2" isLoading={isLoading} />
                    {t('pages.password-reset.content.button-change-password')}
                  </button>
                </div>
              </form>
            </div>
          )}
          {isPasswordChanged && (
            <div id="password-changed" className="centered-block">
              <div className="card mb-3">
                <h1 className="mb-4 text-center tx-uppercase">
                  <Translate i18nKey="pages.password-reset.content.heading" />
                </h1>
                <h5 className="text-center tx-18 mx-3 mb-4">{t('pages.password-reset.content.success-change')}</h5>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
});
