import { faMoneyFromBracket } from '@fortawesome/pro-duotone-svg-icons/faMoneyFromBracket';
import BigNumber from 'bignumber.js';
import { useAtom } from 'jotai';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Dropdown, DropdownButton, Form, InputGroup } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { FormattedNumber } from '@~components/formatted-number/FormattedNumber';
import { IconWithLoading } from '@~components/icon-with-loading/IconWithLoading';
import { Translate } from '@~components/translate/Translate';
import { useStateUpdate } from '@~context/StateContext';
import { useResponseHandler } from '@~hooks/useResponseHandler';
import { checkEthiClaimIsAvailable, withdraw } from '@~network/account';
import { WithdrawalsModal } from '@~pages/account/withdrawal/modals/WithdrawalsModal';
import { profileInfoAtom, tokenBankAtom, walletsAtom } from '@~store/account.store';
import { ECurrency } from '@~types/enums';
import { ModalAlert } from '@~components/modal-alert/ModalAlert';
import { digitsRx } from '@~constants';
import { convertCurrency } from '@~helpers/convertCurrency';
import { mapCurrency } from '@~helpers/mapCurrency';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';

const availableOptions = [ECurrency.AUF, ECurrency.GAT, ECurrency.USD, ECurrency.ETHI_USD, ECurrency.SWAP_USD];

export const WithdrawalPage = memo(() => {
  const { t } = useTranslation();

  const [profile] = useAtom(profileInfoAtom);
  const [wallets] = useAtom(walletsAtom);
  const [tokenBank] = useAtom(tokenBankAtom);

  const [openModal, setOpenModal] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState<ECurrency>();
  const [isLoading, setIsLoading] = useState(false);
  const [withdrawalAmount, setWithdrawalAmount] = useState<number>();
  const [withdrawalMaxAmount, setWithdrawalMaxAmount] = useState<number>(0);
  const [withdrawalAddress, setWithdrawalAddress] = useState('');
  const [convertedAmount, setConvertedAmount] = useState(0);
  const [oneTimePassword, setOneTimePassword] = useState<string>();
  const [ethiReadyForWithdrawal, setEthiReadyForWithdrawal] = useState(false);

  const sentWithdrawRequestRef = useRef(false);

  const { fetchWallets } = useStateUpdate();
  const handleResponse = useResponseHandler();

  const isAddressValid = useMemo((): boolean => {
    return RegExp(/^T[A-Za-z1-9]{33}$/).test(withdrawalAddress);
  }, [withdrawalAddress]);

  const walletByCurrency = useMemo(() => {
    if (wallets.length === 0 || !selectedCurrency) {
      return null;
    }

    const foundWallet = wallets.find(({ currency }) => currency === selectedCurrency);
    if (!foundWallet) {
      return null;
    }

    return foundWallet;
  }, [wallets, selectedCurrency]);

  const isDataValid = useCallback(() => {
    if (!walletByCurrency) {
      return false;
    }

    if (!withdrawalAmount || withdrawalAmount < 0) {
      handleResponse(t('error.wrong-amount') + '', true);
      return false;
    }

    if (withdrawalAmount && withdrawalAmount > walletByCurrency.balance) {
      handleResponse(t('error.not-enough-money') + '', true);
      return false;
    }

    if (!isAddressValid) {
      handleResponse(t('error.wrong-usdt-address') + '', true);
      return false;
    }

    return true;
  }, [isAddressValid, withdrawalAmount, walletByCurrency, handleResponse, t]);

  const handleWithdraw = useCallback(() => {
    if (sentWithdrawRequestRef.current) {
      return;
    }

    if (!withdrawalAmount || !selectedCurrency) {
      return;
    }

    if (!isDataValid()) {
      return;
    }

    setIsLoading(true);
    sentWithdrawRequestRef.current = true;

    withdraw(withdrawalAmount, withdrawalAddress, selectedCurrency, oneTimePassword)
      .then((response) => {
        if (response.success) {
          handleResponse(t('pages.account.withdrawal.content.success') + '');
          setWithdrawalAddress('');
          setWithdrawalAmount(undefined);
          setOneTimePassword(undefined);
          setOpenModal(false);
          fetchWallets();
        } else {
          handleResponse(response, true);
        }
      })
      .catch((response) => {
        handleResponse(response.response.data, true);
      })
      .finally(() => {
        sentWithdrawRequestRef.current = false;
        setIsLoading(false);
      });
  }, [
    isDataValid,
    withdrawalAmount,
    withdrawalAddress,
    selectedCurrency,
    fetchWallets,
    oneTimePassword,
    handleResponse,
    t,
  ]);

  useEffect(() => {
    setSelectedCurrency(availableOptions[0]);
  }, []);

  useEffect(() => {
    if (selectedCurrency === ECurrency.ETHI_USD) {
      checkEthiClaimIsAvailable().then((response) => {
        if (response.data) {
          setEthiReadyForWithdrawal(response.data.withdrawalAvailability);
        } else {
          setEthiReadyForWithdrawal(false);
        }
      });
    }
  }, [selectedCurrency]);

  useEffect(() => {
    let withdrawalMaxAmountValue = 0;
    if (selectedCurrency && walletByCurrency) {
      withdrawalMaxAmountValue = new BigNumber(walletByCurrency.balance).decimalPlaces(2, 1).toNumber();
    }
    setWithdrawalAmount(undefined);
    setWithdrawalMaxAmount(withdrawalMaxAmountValue);
  }, [selectedCurrency, walletByCurrency]);

  useEffect(() => {
    if (!withdrawalAmount || !tokenBank || !selectedCurrency) {
      setConvertedAmount(0);
      return;
    }

    if (selectedCurrency === ECurrency.AUF) {
      setConvertedAmount(withdrawalAmount * tokenBank.aufPrice);
    } else if (selectedCurrency === ECurrency.GAT) {
      setConvertedAmount(withdrawalAmount * tokenBank.gatPrice);
    }
  }, [withdrawalAmount, tokenBank, selectedCurrency]);

  const handleModalAction = useCallback(
    (success: boolean) => {
      if (!success) {
        setOpenModal(false);
        return;
      }

      if (profile?.isTwoFactorEnabled) {
        if (!oneTimePassword) {
          handleResponse(t('error.one-time-password-required') + '', true);
          return;
        }

        if (oneTimePassword.length < 6 || !digitsRx.test(oneTimePassword)) {
          handleResponse(t('error.one-time-password-error') + '', true);
          return;
        }
      }

      handleWithdraw();
    },
    [handleWithdraw, profile, oneTimePassword, handleResponse, t]
  );

  return (
    <>
      <Helmet>
        <title>Aurous | {t('pages.account.withdrawal.title')}</title>
      </Helmet>
      <div id="withdrawal">
        <h2 className="heading d-flex justify-content-between align-items-center mb-2">
          <div className="tx-uppercase">{t('pages.account.withdrawal.content.heading')}</div>
          <div>
            <WithdrawalsModal />
          </div>
        </h2>
        <h6 className="subheading mb-6 tx-gray-600">{t('pages.account.withdrawal.content.subheading')}</h6>
        <div className="withdrawal-block mt-6">
          <div>
            <h5 className="tx-normal">
              {t('pages.account.withdrawal.content.withdraw-label', {
                currency: selectedCurrency && convertCurrency(selectedCurrency),
              })}
            </h5>
            <div className="d-flex mt-3 mb-1 align-items-center gap-3">
              <InputGroup className="mt-3 mb-1">
                <Form.Control
                  type="number"
                  size="lg"
                  placeholder="0.00"
                  maxLength={12}
                  value={withdrawalAmount !== undefined ? withdrawalAmount : ''}
                  id="withdrawalAmount"
                  onChange={(event) => setWithdrawalAmount(event.target.value ? +event.target.value : undefined)}
                  disabled={isLoading}
                />
                <DropdownButton
                  variant="outline-primary"
                  title={
                    <div className="token-select notranslate">
                      {selectedCurrency && t(mapCurrency(selectedCurrency))}{' '}
                      <FontAwesomeIcon icon={faAngleDown} className="caret" />
                    </div>
                  }
                  align="start"
                >
                  {availableOptions.map((currency) => (
                    <Dropdown.Item
                      key={currency}
                      className={currency === selectedCurrency ? 'active' : ''}
                      onClick={() => setSelectedCurrency(currency)}
                    >
                      {t(mapCurrency(currency))}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </InputGroup>
              {selectedCurrency !== ECurrency.USD && convertedAmount > 0 && (
                <FormattedNumber
                  value={convertedAmount}
                  suffix={'≈ '}
                  postfix={convertCurrency(ECurrency.USD)}
                  decimals={2}
                  floor
                  className="converted-value tx-italic tx-gray-500"
                />
              )}
            </div>
            <div className="ml-3 tx-gray-600">
              {t('common.available')}:
              <FormattedNumber
                value={withdrawalMaxAmount}
                postfix={selectedCurrency && convertCurrency(selectedCurrency)}
                decimals={2}
                floor
                className="text-decoration-underline ms-1 cur-pointer"
                onClick={() => setWithdrawalAmount(withdrawalMaxAmount)}
              />
            </div>
          </div>
          <div className="mt-5">
            <h5 className="tx-normal">{t('pages.account.withdrawal.content.wallet-label')}</h5>
            <div className="d-flex mt-3 mb-1 align-items-center gap-3">
              <Form.Control
                type="text"
                size="lg"
                className={`form-control ${isAddressValid ? 'is-valid' : withdrawalAddress && 'is-invalid'}`}
                placeholder={t('pages.account.withdrawal.content.address') + ''}
                value={withdrawalAddress}
                id="trc20-address"
                onChange={(event) => setWithdrawalAddress(event.target.value)}
                disabled={isLoading}
              />
            </div>
          </div>
          <div className="mt-5">
            <button
              className="notranslate btn btn-primary tx-primary mt-2 mt-lg-0"
              onClick={() => setOpenModal(true)}
              disabled={
                !withdrawalAddress ||
                !withdrawalAmount ||
                !walletByCurrency ||
                !walletByCurrency.withdrawal ||
                (selectedCurrency === ECurrency.ETHI_USD && !ethiReadyForWithdrawal)
              }
            >
              <IconWithLoading icon={faMoneyFromBracket} isLoading={isLoading} className="me-3" />
              {t('pages.account.withdrawal.content.withdraw')}
            </button>
          </div>
          {selectedCurrency && walletByCurrency && !walletByCurrency.withdrawal && (
            <div className="mt-2 tx-danger">
              {t('error.withdrawal-is-not-available', { currency: t(mapCurrency(selectedCurrency)) })}
            </div>
          )}
          {selectedCurrency === ECurrency.ETHI_USD && walletByCurrency && walletByCurrency.withdrawal && (
            <>
              {ethiReadyForWithdrawal ? (
                <div className="mt-2 tx-success">{t('pages.account.withdrawal.content.ethi.withdrawal-is-ready')}</div>
              ) : (
                <div className="mt-2 tx-warning">
                  {t('pages.account.withdrawal.content.ethi.withdrawal-is-not-ready')}
                </div>
              )}
            </>
          )}
        </div>

        <ModalAlert
          key="modal-for-withdrawal"
          proceed={handleModalAction}
          show={openModal}
          title={t('pages.account.withdrawal.content.heading') + ''}
          okLabel={t('pages.account.withdrawal.content.withdraw') + ''}
          size="lg"
          loading={isLoading}
          enableEscape
          content={
            <div className="modal-content-bg word-break text-center">
              <Translate
                i18nKey={'pages.account.withdrawal.content.withdraw-confirmation'}
                values={{
                  amount: withdrawalAmount && withdrawalAmount.toLocaleString(),
                  currency: selectedCurrency && t(mapCurrency(selectedCurrency)),
                  address: withdrawalAddress,
                }}
              />
              {profile?.isTwoFactorEnabled && (
                <div className="form-group mt-6 mb-3 text-center">
                  <div className="tx-18 mb-1">{t('pages.login.content.2fa.password-label')}</div>
                  <input
                    id="2fa-password"
                    type="text"
                    className="form-control one-time-password-input"
                    placeholder={t('pages.login.content.2fa.password-placeholder') + ''}
                    defaultValue={oneTimePassword}
                    maxLength={6}
                    onChange={(event) => setOneTimePassword(event.target.value)}
                  />
                  <div className="tx-gold">{t('pages.login.content.2fa.modal-subheader')}</div>
                </div>
              )}
            </div>
          }
        />
      </div>
    </>
  );
});
