import { faLink, faLinkSlash, faShieldKeyhole, faShieldSlash } from '@fortawesome/pro-duotone-svg-icons';
import { faCircle1, faCircle2, faCircle3 } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAtom } from 'jotai';
import { memo, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as GaLogo } from '@~assets/logo/ga-logo.svg';
import { ReactComponent as GooglePlay } from '@~assets/images/icons/google-play.svg';
import { ReactComponent as AppStore } from '@~assets/images/icons/app-store.svg';
import { ModalAlert } from '@~components/modal-alert/ModalAlert';
import { Translate } from '@~components/translate/Translate';
import { digitsRx } from '@~constants';
import { useStateUpdate } from '@~context/StateContext';
import { useResponseHandler } from '@~hooks/useResponseHandler';
import { disableTwoFactor, enableTwoFactor, getTwoFactorSetup } from '@~network/account';
import { profileInfoAtom } from '@~store/account.store';
import { CopyButton } from '@~components/copy-button/CopyButton';

export const Manage2FaModals = memo(() => {
  const { t } = useTranslation();

  const [profile] = useAtom(profileInfoAtom);

  const [openModal, setOpenModal] = useState(false);
  const [qrCode, setQrCode] = useState('');
  const [secretCode, setSecretCode] = useState('');
  const [open2FaActivateModal, setOpen2FaActivateModal] = useState(false);
  const [open2FaDeactivateModal, setOpen2FaDeactivateModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [oneTimePassword, setOneTimePassword] = useState<string>();

  const requestSentRef = useRef(false);

  const { fetchProfileInfo } = useStateUpdate();
  const handleResponse = useResponseHandler();

  const fetchQrCode = useCallback(() => {
    getTwoFactorSetup()
      .then((response) => {
        if (response.success) {
          setQrCode(response.data?.qrCode || '');
          setSecretCode(response.data?.secretCode || '');
        } else {
          handleResponse(response);
        }
      })
      .catch((response) => {
        handleResponse(response.response.data);
      });
  }, [handleResponse]);

  const activate2Fa = useCallback(() => {
    if (requestSentRef.current || !oneTimePassword) {
      return;
    }

    requestSentRef.current = true;
    setLoading(true);

    enableTwoFactor(oneTimePassword)
      .then((response) => {
        if (response.success) {
          handleResponse(t('pages.account.profile.content.security.2fa.2fa-activated') + '', false);
          fetchProfileInfo();
          setOpen2FaActivateModal(false);
          setOneTimePassword(undefined);
        } else {
          handleResponse(response);
        }
      })
      .catch((response) => {
        handleResponse(response.response.data);
      })
      .finally(() => {
        requestSentRef.current = false;
        setLoading(false);
      });
  }, [fetchProfileInfo, oneTimePassword, handleResponse, t]);

  const deactivate2Fa = useCallback(() => {
    if (requestSentRef.current || !oneTimePassword) {
      return;
    }

    requestSentRef.current = true;
    setLoading(true);

    disableTwoFactor(oneTimePassword)
      .then((response) => {
        if (response.success) {
          handleResponse(t('pages.account.profile.content.security.2fa.2fa-deactivated') + '', false);
          fetchProfileInfo();
          setOpen2FaDeactivateModal(false);
          setOneTimePassword(undefined);
        } else {
          handleResponse(response);
        }
      })
      .catch((response) => {
        handleResponse(response.response.data);
      })
      .finally(() => {
        requestSentRef.current = false;
        setLoading(false);
      });
  }, [fetchProfileInfo, oneTimePassword, handleResponse, t]);

  const handleModalActivationAction = useCallback(
    (success: boolean) => {
      if (!success) {
        setOpen2FaActivateModal(false);
        return;
      }

      if (!oneTimePassword) {
        handleResponse(t('error.one-time-password-required') + '', true);
        return;
      }

      if (oneTimePassword.length < 6 || !digitsRx.test(oneTimePassword)) {
        handleResponse(t('error.one-time-password-error') + '', true);
        return;
      }

      activate2Fa();
    },
    [activate2Fa, oneTimePassword, handleResponse, t]
  );

  const handleModalDeactivationAction = useCallback(
    (success: boolean) => {
      if (!success) {
        setOpen2FaDeactivateModal(false);
        return;
      }

      if (!oneTimePassword) {
        handleResponse(t('error.one-time-password-required') + '', true);
        return;
      }

      if (oneTimePassword.length < 6 || !digitsRx.test(oneTimePassword)) {
        handleResponse(t('error.one-time-password-error') + '', true);
        return;
      }

      deactivate2Fa();
    },
    [deactivate2Fa, oneTimePassword, handleResponse, t]
  );

  const handle2FaDeactivate = () => {
    setOpenModal(false);
    setOpen2FaDeactivateModal(true);
  };

  const handle2FaActivate = () => {
    setOpenModal(false);
    setOpen2FaActivateModal(true);
    fetchQrCode();
  };

  return (
    <>
      <button className="btn btn-link tx-primary py-1 px-2" onClick={() => setOpenModal(true)}>
        <FontAwesomeIcon icon={profile?.isTwoFactorEnabled ? faLinkSlash : faLink} />
      </button>

      <ModalAlert
        key="modal-for-manage-2-fa"
        proceed={setOpenModal}
        show={openModal}
        title={t('pages.account.profile.content.security.2fa.modal-title') + ''}
        cancelLabel={`${t('common.action.close')}`}
        size="lg"
        noOk
        enableEscape
        content={
          <div className="modal-content-bg mb-3 text-center">
            {profile?.isTwoFactorEnabled && (
              <div>
                <Translate i18nKey="pages.account.profile.content.security.2fa.activated-text" />
                <div>
                  <button className="btn btn-warning tx-primary mt-5" onClick={handle2FaDeactivate}>
                    <FontAwesomeIcon icon={faShieldSlash} className="me-2" />{' '}
                    {t('pages.account.profile.content.security.2fa.deactivate-button')}
                  </button>
                </div>
              </div>
            )}
            {!profile?.isTwoFactorEnabled && (
              <div>
                <Translate i18nKey="pages.account.profile.content.security.2fa.deactivated-text" />
                <div>
                  <button className="btn btn-primary tx-primary mt-5" onClick={handle2FaActivate}>
                    <FontAwesomeIcon icon={faShieldKeyhole} className="me-2" />{' '}
                    {t('pages.account.profile.content.security.2fa.activate-button')}
                  </button>
                </div>
              </div>
            )}
          </div>
        }
      />

      {!profile?.isTwoFactorEnabled && (
        <ModalAlert
          key="modal-for-2fa-activation"
          proceed={handleModalActivationAction}
          show={open2FaActivateModal}
          title={t('pages.account.profile.content.security.2fa.activation-modal-title') + ''}
          okLabel={`${t('pages.account.profile.content.security.2fa.activate-button')}`}
          okIcon={faShieldKeyhole}
          okVariant="success"
          size="lg"
          loading={isLoading}
          content={
            <div className="modal-content-bg mb-3">
              <section className="mb-3">
                <h5 className="heading d-flex align-items-center">
                  <FontAwesomeIcon icon={faCircle1} size="2x" className="tx-primary me-3" />
                  <span className="tx-24">{t('pages.account.profile.content.security.2fa.activation-step-1')}</span>
                </h5>
                <div className="ga-block d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center gap-2">
                  <div className="ga-logo">
                    <GaLogo width={48} height={48} />
                    <span className="text-md-start">
                      Google
                      <br />
                      Authenticator
                    </span>
                  </div>
                  <div className="ga-apps text-md-end text-center">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&amp;hl=en"
                      target="_blank"
                      rel="noreferrer"
                      type="button"
                      className="p-1"
                    >
                      <GooglePlay />
                    </a>
                    <a
                      href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                      target="_blank"
                      rel="noreferrer"
                      type="button"
                      className="p-1"
                    >
                      <AppStore />
                    </a>
                  </div>
                </div>
              </section>
              <section className="mb-3">
                <h5 className="heading d-flex align-items-center">
                  <FontAwesomeIcon icon={faCircle2} size="2x" className="tx-primary me-3" />
                  <span className="tx-24">{t('pages.account.profile.content.security.2fa.activation-step-2')}</span>
                </h5>
                <div className="text-center p-3">
                  <img src={qrCode} alt="qrCode" className="ga-qr-code" />
                </div>
                <div className="text-center">
                  <div className="tx-16">{t('pages.account.profile.content.security.2fa.activation-step-2-note')}</div>
                  <div className="tx-16">
                    {t('pages.account.profile.content.security.2fa.activation-step-2-code')}
                    {': '}
                    <CopyButton text={secretCode} element={secretCode} className="btn btn-link tx-primary word-break" />
                  </div>
                </div>
              </section>
              <section className="mb-3">
                <h5 className="heading d-flex align-items-center">
                  <FontAwesomeIcon icon={faCircle3} size="2x" className="tx-primary me-3" />
                  <span className="tx-24">{t('pages.account.profile.content.security.2fa.activation-step-3')}</span>
                </h5>
                <div className="form-group my-3 text-center">
                  <div className="tx-18 mb-1">{t('pages.login.content.2fa.password-label')}</div>
                  <input
                    id="2fa-password-for-deactivate"
                    type="text"
                    className="form-control one-time-password-input"
                    placeholder={t('pages.login.content.2fa.password-placeholder') + ''}
                    defaultValue={oneTimePassword}
                    maxLength={6}
                    onChange={(event) => setOneTimePassword(event.target.value)}
                  />
                  <div className="tx-gold">{t('pages.login.content.2fa.modal-subheader')}</div>
                </div>
              </section>
            </div>
          }
        />
      )}

      {profile?.isTwoFactorEnabled && (
        <ModalAlert
          key="modal-for-2fa-deactivation"
          proceed={handleModalDeactivationAction}
          show={open2FaDeactivateModal}
          title={t('pages.account.profile.content.security.2fa.deactivation-modal-title') + ''}
          okLabel={`${t('pages.account.profile.content.security.2fa.deactivate-button')}`}
          okIcon={faShieldSlash}
          okVariant="warning"
          size="lg"
          enableEscape
          loading={isLoading}
          content={
            <div className="modal-content-bg mb-3 text-center">
              <div className="tx-18 mb-1 text-center">
                <Translate i18nKey="pages.account.profile.content.security.2fa.deactivation-description" />
              </div>
              <div className="form-group mt-6 mb-3 text-center">
                <div className="tx-18 mb-1">{t('pages.login.content.2fa.password-label')}</div>
                <input
                  id="2fa-password-for-deactivate"
                  type="text"
                  className="form-control one-time-password-input"
                  placeholder={t('pages.login.content.2fa.password-placeholder') + ''}
                  defaultValue={oneTimePassword}
                  maxLength={6}
                  onChange={(event) => setOneTimePassword(event.target.value)}
                />
                <div className="tx-gold">{t('pages.login.content.2fa.modal-subheader')}</div>
              </div>
            </div>
          }
        />
      )}
    </>
  );
});
