import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import { faSackDollar } from '@fortawesome/pro-duotone-svg-icons';
import BigNumber from 'bignumber.js';
import { useAtom } from 'jotai';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { FormattedNumber } from '@~components/formatted-number/FormattedNumber';
import { IconWithLoading } from '@~components/icon-with-loading/IconWithLoading';
import { confirmAlert } from '@~components/modal-alert/corfirmAlert';
// import { ISwitcherOption, RadioSwitcher } from '@~components/radio-switcher/RadioSwitcher';
import { Translate } from '@~components/translate/Translate';
import { useStateUpdate } from '@~context/StateContext';
import { convertCurrency } from '@~helpers/convertCurrency';
import { mapCurrency } from '@~helpers/mapCurrency';
import { useResponseHandler } from '@~hooks/useResponseHandler';
import { getEthiPlanPackages, investToEthiPlan } from '@~network/account';
import { ethiPackagesAtom, walletsAtom } from '@~store/account.store';
import { ECurrency, EInvestPlanStatus } from '@~types/enums';
import { IEthiInvestPlan, IEthiPlanPackage } from '@~types/types';

interface IEthiInvestBlockProps {
  label: string;
  investPlanEthi?: IEthiInvestPlan;
  fetchEthiInvestPlan: () => void;
}

function isDisabledOption(availableAmount: number, packageAmount: number, ethiPlanPackage?: IEthiPlanPackage | null) {
  if (ethiPlanPackage) {
    return availableAmount < packageAmount - ethiPlanPackage.price;
  }
  return availableAmount < packageAmount;
}

// const availableOptions = [ECurrency.USD, ECurrency.ETHI_USD];

export const EthiInvestBlock = memo(({ label, investPlanEthi, fetchEthiInvestPlan }: IEthiInvestBlockProps) => {
  const { t } = useTranslation();

  const [wallets] = useAtom(walletsAtom);
  const [ethiPackages, setEthiPackages] = useAtom(ethiPackagesAtom);

  const [investMaxAmount, setInvestMaxAmount] = useState<number>(0);
  // const [selectedCurrency, setSelectedCurrency] = useState<ECurrency>(availableOptions[0]);
  const [selectedEthiPackage, setSelectedEthiPackage] = useState<IEthiPlanPackage | null>(null);
  const [isInvestLoading, setInvestLoading] = useState(false);

  const getPackagesRequestRef = useRef(false);
  const sentInvestRequestRef = useRef(false);

  const { fetchWallets, fetchTokenBank } = useStateUpdate();
  const handleResponse = useResponseHandler();

  const selectedCurrency = ECurrency.USD;

  useEffect(() => {
    let investMaxAmountValue = 0;
    if (selectedCurrency && wallets.length > 0) {
      const foundWallet = wallets.find(({ currency }) => currency === selectedCurrency);
      if (foundWallet) {
        investMaxAmountValue = new BigNumber(foundWallet.balance).decimalPlaces(2, 1).toNumber();
      }
    }
    setInvestMaxAmount(investMaxAmountValue);
  }, [selectedCurrency, wallets]);

  useEffect(() => {
    if (getPackagesRequestRef.current) {
      return;
    }

    getPackagesRequestRef.current = true;
    if (ethiPackages.length === 0) {
      getEthiPlanPackages()
        .then((response) => setEthiPackages(response))
        .finally(() => (getPackagesRequestRef.current = false));
    }
  }, [ethiPackages, setEthiPackages]);

  // const options: ISwitcherOption<ECurrency>[] = useMemo(
  //   () => availableOptions.map((currency) => ({ value: currency, label: t(mapCurrency(currency)) })),
  //   [t]
  // );
  //
  // const handleCurrencyChange = useCallback((value: ECurrency) => {
  //   setSelectedCurrency(value);
  // }, []);

  const handleEthiInvest = useCallback(() => {
    if (sentInvestRequestRef.current || !selectedEthiPackage) {
      return;
    }

    sentInvestRequestRef.current = true;

    confirmAlert({
      title: t('pages.account.staking.content.invest') + '',
      okLabel: t('pages.account.staking.content.invest') + '',
      okVariant: 'success',
      content: (
        <div className="modal-content-bg">
          <Translate
            i18nKey={
              investPlanEthi?.planPackage
                ? 'pages.account.staking.content.upgrade-confirmation'
                : 'pages.account.staking.content.invest-confirmation'
            }
            values={{
              amount: selectedEthiPackage.price.toLocaleString(),
              requiredAmount: (
                selectedEthiPackage.price - (investPlanEthi?.planPackage ? investPlanEthi.planPackage.price : 0)
              ).toLocaleString(),
              currency: convertCurrency(selectedCurrency),
              investPlan: label,
            }}
          />
        </div>
      ),
    }).then((resp) => {
      if (resp) {
        setInvestLoading(true);

        investToEthiPlan(selectedEthiPackage.id)
          .then(() => {
            handleResponse(
              t(
                investPlanEthi?.planPackage
                  ? 'pages.account.staking.content.success-upgrade'
                  : 'pages.account.staking.content.success-invest',
                {
                  amount: selectedEthiPackage.price.toLocaleString(),
                  currency: convertCurrency(ECurrency.USD),
                  invest: label,
                }
              ) + '',
              false
            );
            setSelectedEthiPackage(null);

            fetchEthiInvestPlan();
            fetchWallets();
            fetchTokenBank();
          })
          .catch((response) => {
            handleResponse(response.response.data, true);
          })
          .finally(() => {
            sentInvestRequestRef.current = false;
            setInvestLoading(false);
          });
      } else {
        sentInvestRequestRef.current = false;
      }
    });
  }, [
    t,
    selectedEthiPackage,
    handleResponse,
    fetchEthiInvestPlan,
    fetchWallets,
    fetchTokenBank,
    label,
    investPlanEthi,
    selectedCurrency,
  ]);

  if (ethiPackages.length === 0) {
    return null;
  }

  const latestEthiPackage = ethiPackages[ethiPackages.length - 1];
  if (!investPlanEthi || investPlanEthi.planPackage?.id === latestEthiPackage.id) {
    return null;
  }

  return (
    <div className="invest-block mt-6">
      <h5 className="tx-normal">
        {t('pages.account.staking.content.invest-into')} {label}
      </h5>
      {/*<div className="switcher-holder my-4">
        <RadioSwitcher<ECurrency>
          id="currency-select-for-ethi"
          options={options}
          handleValueChange={handleCurrencyChange}
          selectedValue={selectedCurrency}
        />
      </div>
      */}
      <Dropdown className="mt-3">
        <Dropdown.Toggle variant="transparent" className="ethi-package-select notranslate">
          <span>
            {selectedEthiPackage
              ? `${t('pages.account.staking.content.ethi-plan.package-title')} ${selectedEthiPackage.price}`
              : t('pages.account.staking.content.ethi-plan.select-plan')}
          </span>
          <FontAwesomeIcon icon={faAngleDown} className="caret" />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {ethiPackages
            .filter((ethiPackage) => {
              if (!ethiPackage.active) {
                return false;
              }
              if (investPlanEthi?.planPackage != null) {
                return ethiPackage.id > investPlanEthi.planPackage.id;
              }
              return true;
            })
            .map((ethiPackage) => (
              <Dropdown.Item
                key={ethiPackage.id}
                className={selectedEthiPackage?.id === ethiPackage.id ? 'active' : ''}
                onClick={() => {
                  setSelectedEthiPackage(ethiPackage);
                }}
                disabled={isDisabledOption(investMaxAmount, ethiPackage.price, investPlanEthi?.planPackage)}
              >
                {t(`pages.account.staking.content.ethi-plan.package-title`)} {ethiPackage.price}
                {ethiPackage.maxPercent > 0 ? ` (0.54% - ${ethiPackage.maxPercent}%)` : ' (0.54%)'}
              </Dropdown.Item>
            ))}
        </Dropdown.Menu>
      </Dropdown>
      <div className="tx-gray-600 mb-5">
        {t('common.available')}:
        <FormattedNumber
          value={investMaxAmount}
          postfix={selectedCurrency && convertCurrency(selectedCurrency)}
          decimals={2}
          floor
          className="ms-1"
        />
      </div>
      {selectedEthiPackage && (
        <div className="mb-2">
          {t('common.total')}:
          <FormattedNumber
            value={selectedEthiPackage.price - (investPlanEthi.planPackage ? investPlanEthi.planPackage.price : 0)}
            postfix={selectedCurrency && t(mapCurrency(selectedCurrency)) + ''}
            decimals={2}
            floor
            className="ms-1"
          />
        </div>
      )}
      <div>
        <button
          className="notranslate btn btn-primary tx-primary mt-2 mt-lg-0"
          onClick={handleEthiInvest}
          disabled={!selectedEthiPackage}
        >
          <IconWithLoading icon={faSackDollar} isLoading={isInvestLoading} className="me-3" />
          {t(
            `pages.account.staking.content.${
              investPlanEthi?.status === EInvestPlanStatus.ACTIVE ? 'upgrade' : 'invest'
            }`
          )}
        </button>
      </div>
    </div>
  );
});
