import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightFromBracket } from '@fortawesome/pro-duotone-svg-icons';
import { isFuture } from 'date-fns';
import { memo, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalAlert } from '@~components/modal-alert/ModalAlert';
import { useStateUpdate } from '@~context/StateContext';
import { useResponseHandler } from '@~hooks/useResponseHandler';
import { exitUsd } from '@~network/account';
import type { IUsdInvestPlan } from '@~types/types';

interface IEarlyWithdrawalModalProps {
  fetchSwapInvestPlan: () => void;
  planData?: IUsdInvestPlan;
}

export const EarlyWithdrawalModal = memo(({ fetchSwapInvestPlan, planData }: IEarlyWithdrawalModalProps) => {
  const { t } = useTranslation();

  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const requestSentRef = useRef(false);

  const handleResponse = useResponseHandler();
  const { fetchWallets } = useStateUpdate();

  const submitForm = useCallback(() => {
    if (requestSentRef.current) {
      return;
    }

    requestSentRef.current = true;
    setLoading(true);

    exitUsd()
      .then((response) => {
        if (response.success) {
          fetchWallets();
          fetchSwapInvestPlan();
          handleResponse(t('pages.account.staking.content.swap-plan.exit-success') + '', false);
        } else {
          handleResponse(response, true);
        }
      })
      .catch((response) => {
        handleResponse(response.response.data, true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [fetchWallets, fetchSwapInvestPlan, handleResponse, t]);

  const handleModalAction = useCallback(
    (success: boolean) => {
      if (!success) {
        setOpenModal(false);
        return;
      }

      submitForm();
    },
    [submitForm]
  );

  const usdWorkUntil = planData?.workUntil ? new Date(planData.workUntil) : null;

  return (
    <>
      <button
        className="btn btn-link tx-primary py-1 px-2"
        onClick={() => setOpenModal(true)}
        disabled={!usdWorkUntil || !isFuture(usdWorkUntil)}
      >
        <FontAwesomeIcon icon={faRightFromBracket} />
      </button>

      <ModalAlert
        key="modal-for-early-usdt-withdrawal"
        proceed={handleModalAction}
        show={openModal}
        title={t('pages.account.staking.content.swap-plan.exit-title') + ''}
        okLabel={t('pages.account.staking.content.swap-plan.exit-ok') + ''}
        okVariant="warning"
        size="lg"
        enableEscape
        loading={isLoading}
        content={
          <div className="modal-content-bg">
            <div className="tx-bold tx-warning">{t('pages.account.staking.content.swap-plan.exit-confirmation')}</div>
            <p className="tx-14 mt-5">* {t('pages.account.staking.content.swap-plan.text.note')}</p>
          </div>
        }
      />
    </>
  );
});
