import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Lightbox from 'yet-another-react-lightbox';
import Captions from 'yet-another-react-lightbox/plugins/captions';
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';
import Slideshow from 'yet-another-react-lightbox/plugins/slideshow';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';

import doc1 from '@~assets/documents/doc1.jpeg';
import doc2 from '@~assets/documents/doc2.jpeg';
import doc3 from '@~assets/documents/doc3.jpeg';
import doc4 from '@~assets/documents/doc4.jpeg';
import doc5 from '@~assets/documents/doc5.jpeg';
import doc6 from '@~assets/documents/doc6.jpeg';
import doc7 from '@~assets/documents/doc7.jpeg';
import doc8 from '@~assets/documents/doc8.jpeg';
import docEco from '@~assets/documents/doc-eco.jpg';
import docNew from '@~assets/documents/doc-new.jpg';

import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

import styles from './Documents.module.scss';

export const Documents = () => {
  const { t } = useTranslation();

  const [isOpen, setOpen] = useState(false);

  const documents = useMemo(
    () => [
      { src: doc1, title: t('footer.document-titles.doc1'), description: 'Description' },
      { src: doc2, title: t('footer.document-titles.doc2'), description: 'Description' },
      { src: docNew, title: t('footer.document-titles.docNew'), description: 'Description' },
      { src: doc3, title: t('footer.document-titles.doc3'), description: 'Description' },
      { src: doc4, title: t('footer.document-titles.doc4'), description: 'Description' },
      { src: doc5, title: t('footer.document-titles.doc5'), description: 'Description' },
      { src: doc6, title: t('footer.document-titles.doc6'), description: 'Description' },
      { src: doc7, title: t('footer.document-titles.doc7'), description: 'Description' },
      { src: doc8, title: t('footer.document-titles.doc8'), description: 'Description' },
      { src: docEco, title: t('footer.document-titles.docEco'), description: 'Description' },
    ],
    [t]
  );

  return (
    <div className={styles.root}>
      <button className="btn btn-link p-0" onClick={() => setOpen(true)}>
        {t('footer.documents')}
      </button>

      <Lightbox
        open={isOpen}
        close={() => setOpen(false)}
        slides={documents}
        plugins={[Captions, Fullscreen, Slideshow, Thumbnails, Zoom]}
      />
    </div>
  );
};
