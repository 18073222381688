import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faTriangleExclamation } from '@fortawesome/pro-duotone-svg-icons';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalAlert } from '@~components/modal-alert/ModalAlert';

export const PromoTurkeyMay2023Modal = memo(() => {
  const { t } = useTranslation();

  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <FontAwesomeIcon
        icon={faInfoCircle}
        className="me-2 interactive"
        size="2x"
        onClick={() => setOpenModal(true)}
        title={`${t('common.information')}`}
      />

      <ModalAlert
        key="modal-for-promo-turkey-info"
        proceed={setOpenModal}
        show={openModal}
        title={t('pages.account.promo-actions.content.promo-turkey-may-2023.modal.title') + ''}
        cancelLabel={`${t('common.action.close')}`}
        centered={false}
        size="lg"
        noOk
        enableEscape
        content={
          <div className="modal-content-bg">
            <p>{t('pages.account.promo-actions.content.promo-turkey-may-2023.modal.text.1')}</p>
            <p>{t('pages.account.promo-actions.content.promo-turkey-may-2023.modal.text.2')}</p>
            <p className="text-center">
              <FontAwesomeIcon icon={faTriangleExclamation} className="tx-primary me-3" size="2x" />
              {t('pages.account.promo-actions.content.promo-turkey-may-2023.modal.text.3-note')}
              <FontAwesomeIcon icon={faTriangleExclamation} className="tx-primary ms-3" size="2x" />
            </p>
            <p className="py-5">{t('pages.account.promo-actions.content.promo-turkey-may-2023.modal.text.4-dates')}</p>
            <p>{t('pages.account.promo-actions.content.promo-turkey-may-2023.modal.text.5')}</p>
            <p>
              <ul>
                <li>{t('pages.account.promo-actions.content.promo-turkey-may-2023.modal.text.6-list')}</li>
                <li>{t('pages.account.promo-actions.content.promo-turkey-may-2023.modal.text.7-list')}</li>
              </ul>
            </p>
            <p className="text-center">
              <FontAwesomeIcon icon={faTriangleExclamation} className="tx-primary me-3" size="2x" />
              {t('pages.account.promo-actions.content.promo-turkey-may-2023.modal.text.8-note')}
              <FontAwesomeIcon icon={faTriangleExclamation} className="tx-primary ms-3" size="2x" />
            </p>
          </div>
        }
      />
    </>
  );
});
