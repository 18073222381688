import { useTranslation } from 'react-i18next';

import { CopyButton } from '@~components/copy-button/CopyButton';
import { typesFilterMap } from '@~pages/account/history/constants';
import type { ITransaction } from '@~types/types';
import { ETransactionStatus } from '@~types/enums';

export interface ITransactionDetailsProps {
  entry: ITransaction;
}

export const TransactionDetails = ({ entry }: ITransactionDetailsProps) => {
  const { t } = useTranslation();

  let element;
  // let prefix;
  const withCopy = false;

  if (typesFilterMap.withdrawals.includes(entry.type)) {
    element = entry.metadata?.withdrawalConfirmed ? (
      <div>
        <div>
          <span className="me-1">{t('pages.account.operations.content.hash')}:</span>
          <CopyButton
            text={entry.metadata.transactionHash}
            element={
              entry.metadata.transactionHash.length > 12
                ? `${entry.metadata.transactionHash.slice(0, 5)}...${entry.metadata.transactionHash.slice(-4)}`
                : entry.metadata.transactionHash
            }
          />
        </div>
        {entry.metadata.withdrawalAddress && (
          <div>
            <span className="me-1">{t('pages.account.operations.content.address')}:</span>
            <CopyButton
              text={entry.metadata.withdrawalAddress}
              element={`${entry.metadata.withdrawalAddress.slice(0, 5)}...${entry.metadata.withdrawalAddress.slice(
                -4
              )}`}
            />
          </div>
        )}
      </div>
    ) : (
      <div>
        <div>
          {entry.status === ETransactionStatus.CANCEL
            ? t('pages.account.operations.content.withdrawal-cancelled')
            : t('pages.account.operations.content.withdrawal-pending')}
        </div>
        {entry.metadata.withdrawalAddress && (
          <div>
            <span className="me-1">{t('pages.account.operations.content.address')}:</span>
            <CopyButton
              text={entry.metadata.withdrawalAddress}
              element={`${entry.metadata.withdrawalAddress.slice(0, 5)}...${entry.metadata.withdrawalAddress.slice(
                -4
              )}`}
            />
          </div>
        )}
      </div>
    );
  } else if (typesFilterMap.referrals.includes(entry.type)) {
    element = (
      <div>
        {t(`pages.account.partners.content.affiliate-program.level`)} {entry.metadata.referralLine}
      </div>
    );
  }
  //
  // if (entry.type === TransactionType.WITHDRAWAL) {
  //   element = entry.metadata?.withdrawalAddress;
  //   prefix = t('common.address');
  //   withCopy = true;
  // }
  //
  // if (entry.type === TransactionType.BUY_CONTRACT) {
  //   element = entry.metadata?.contractTemplateName;
  //   prefix = t('common.contract-template-name');
  // }
  //
  // if (entry.type === TransactionType.REFERRAL_REWARD) {
  //   element =
  //     t('transaction.details.referral-reward')
  //       .replace('%line', entry.metadata?.rewardLine + '')
  //       .replace('%usd', entry.metadata?.usdBuyPrice + '');
  // }

  if (element) {
    return (
      <span className="transaction-details word-break">
        {/*{!!prefix && (
          <div className="tx-semibold">
            {prefix}:
          </div>
        )}*/}
        {withCopy && typeof element === 'string' ? <CopyButton text={element} /> : <>{element}</>}
      </span>
    );
  }

  return null;
};
