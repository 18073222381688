import { useAtom } from 'jotai';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

import place1 from '@~assets/images/cups/place1.png';
import place2 from '@~assets/images/cups/place2.png';
import place3 from '@~assets/images/cups/place3.png';
import { CopyButton } from '@~components/copy-button/CopyButton';
import { FormattedNumber } from '@~components/formatted-number/FormattedNumber';
import { Placeholder } from '@~components/placeholder/Placeholder';
import { convertCurrency } from '@~helpers/convertCurrency';
import { getRefLink } from '@~helpers/getRefLink';
import { RanksTableModal } from '@~pages/account/rank-system/modals/RanksTableModal';
import { profileInfoAtom, rankRulesAtom, userRankInfoAtom } from '@~store/account.store';
import { ECurrency } from '@~types/enums';

export const RankSystem = memo(() => {
  const { t } = useTranslation();

  const [profileInfo] = useAtom(profileInfoAtom);
  const [userRankInfo] = useAtom(userRankInfoAtom);
  const [rankRules] = useAtom(rankRulesAtom);

  const currentRank = useMemo(() => {
    if (rankRules.length && userRankInfo && userRankInfo.rankLevel > 0) {
      return rankRules.find(({ level }) => level === userRankInfo.rankLevel) || null;
    }
    return null;
  }, [rankRules, userRankInfo]);

  return (
    <>
      <Helmet>
        <title>Aurous | {t('pages.account.rank-system.title')}</title>
      </Helmet>
      <div id="rank-system">
        <h2 className="heading mb-2 tx-uppercase d-flex justify-content-between align-items-center flex-wrap">
          <div>{t('pages.account.rank-system.content.heading')}</div>
          <CopyButton
            text={getRefLink(profileInfo)}
            element={t('pages.account.partners.content.partners-link')}
            className="btn btn-link tx-primary p-0 copy-link"
            iconClass="tx-primary ml-2 p-0"
          />
        </h2>
        <h6 className="subheading mb-6 tx-gray-600">{t('pages.account.rank-system.content.subheading')}</h6>

        <div className="card-block mb-6">
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-wrap my-2 gap-4">
              <div>
                <div className="tx-uppercase tx-spacing-1">{t('pages.account.rank-system.content.level')}</div>
                <div className="tx-gray-600 tx-bold">
                  {userRankInfo ? userRankInfo.rankLevel : <Placeholder width="50px" />}
                </div>
              </div>
              <div>
                <div className="tx-uppercase tx-spacing-1">{t('pages.account.rank-system.content.rank')}</div>
                <div className="tx-gray-600 tx-bold">{currentRank ? currentRank.name : '---'}</div>
              </div>
              <div>
                <div className="tx-uppercase tx-spacing-1">
                  {t('pages.account.rank-system.content.main-branch-turnover')}
                </div>
                <div className="tx-gray-600 tx-bold">
                  {userRankInfo ? (
                    <>
                      <div className="tx-bold">{userRankInfo.mainBranchUsername}</div>
                      <FormattedNumber
                        value={userRankInfo.mainBranchTurnover}
                        postfix={convertCurrency(ECurrency.USD)}
                        className="tx-bold"
                      />
                    </>
                  ) : (
                    <Placeholder width="50px" />
                  )}
                </div>
              </div>
              <div>
                <div className="tx-uppercase tx-spacing-1">
                  {t('pages.account.rank-system.content.secondary-branch-turnover')}
                </div>
                <div className="tx-gray-600 tx-bold">
                  {userRankInfo ? (
                    <>
                      <div className="tx-bold">{userRankInfo.secondaryBranchUsername}</div>
                      <FormattedNumber
                        value={userRankInfo.secondaryBranchTurnover}
                        postfix={convertCurrency(ECurrency.USD)}
                        className="tx-bold"
                      />
                    </>
                  ) : (
                    <Placeholder width="50px" />
                  )}
                </div>
              </div>
              <div>
                <div className="tx-uppercase tx-spacing-1">
                  {t('pages.account.rank-system.content.other-branches-turnover')}
                </div>
                <div className="tx-gray-600 tx-bold">
                  {userRankInfo ? (
                    <FormattedNumber
                      value={userRankInfo.otherBranchesTurnover}
                      postfix={convertCurrency(ECurrency.USD)}
                      className="tx-bold"
                    />
                  ) : (
                    <Placeholder width="50px" />
                  )}
                </div>
              </div>
              <div>
                <div className="tx-uppercase tx-spacing-1">
                  {t('pages.account.rank-system.content.personal-turnover')}
                </div>
                <div className="tx-gray-600 tx-bold">
                  {userRankInfo ? (
                    <FormattedNumber
                      value={userRankInfo.personalTurnover}
                      postfix={convertCurrency(ECurrency.USD)}
                      className="tx-bold"
                    />
                  ) : (
                    <Placeholder width="50px" />
                  )}
                </div>
              </div>
            </div>
            <div className="mt-2">
              <RanksTableModal rankRules={rankRules} />
            </div>
          </div>
        </div>

        <div className="">
          <h3 className="heading">{t('pages.account.rank-system.content.best-partners-title')}</h3>
          <h6 className="subheading mb-4 tx-gray-600">
            {t('pages.account.rank-system.content.best-partners-subtitle')}
          </h6>

          <table>
            <tr>
              <td className="line-behind p-2">
                <img
                  src={place1}
                  alt={t('pages.account.rank-system.content.main-branch-turnover') + ''}
                  title={t('pages.account.rank-system.content.main-branch-turnover') + ''}
                />
              </td>
              <td className="place1">
                {userRankInfo ? (
                  <div className="d-flex gap-4">
                    {userRankInfo.mainBranchTurnover > 0 ? (
                      <>
                        <div className="tx-bold">1. {userRankInfo.mainBranchUsername}</div>
                        <FormattedNumber
                          value={userRankInfo.mainBranchTurnover}
                          postfix={convertCurrency(ECurrency.USD)}
                          className="tx-bold"
                        />
                      </>
                    ) : (
                      '---'
                    )}
                  </div>
                ) : (
                  <Placeholder width="50px" />
                )}
              </td>
            </tr>
            <tr>
              <td className="line-behind p-2">
                <img
                  src={place2}
                  alt={t('pages.account.rank-system.content.secondary-branch-turnover') + ''}
                  title={t('pages.account.rank-system.content.secondary-branch-turnover') + ''}
                />
              </td>
              <td className="place2">
                {userRankInfo ? (
                  <div className="d-flex gap-4">
                    {userRankInfo.secondaryBranchTurnover > 0 ? (
                      <>
                        <div className="tx-bold">2. {userRankInfo.secondaryBranchUsername}</div>
                        <FormattedNumber
                          value={userRankInfo.secondaryBranchTurnover}
                          postfix={convertCurrency(ECurrency.USD)}
                          className="tx-bold"
                        />
                      </>
                    ) : (
                      '---'
                    )}
                  </div>
                ) : (
                  <Placeholder width="50px" />
                )}
              </td>
            </tr>
            <tr>
              <td className="line-behind p-2">
                <img
                  src={place3}
                  alt={t('pages.account.rank-system.content.other-branches-turnover') + ''}
                  title={t('pages.account.rank-system.content.other-branches-turnover') + ''}
                />
              </td>
              <td className="place3">
                {userRankInfo ? (
                  <div className="d-flex gap-4">
                    {userRankInfo.otherBranchesTurnover > 0 ? (
                      <>
                        <div className="tx-bold">
                          3. {t('pages.account.rank-system.content.other-branches-turnover')}
                        </div>
                        <FormattedNumber
                          value={userRankInfo.otherBranchesTurnover}
                          postfix={convertCurrency(ECurrency.USD)}
                          className="tx-bold"
                        />
                      </>
                    ) : (
                      '---'
                    )}
                  </div>
                ) : (
                  <Placeholder width="50px" />
                )}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </>
  );
});
