import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/pro-regular-svg-icons';
import { memo } from 'react';

import { Translate } from '@~components/translate/Translate';

interface IPreloaderProps {
  className?: string;
  iconClass?: string;
  textClass?: string;
  text?: string;
  inline?: boolean;
  longWait?: boolean;
}

export const Preloader = memo(({ className, iconClass, textClass, text, inline, longWait }: IPreloaderProps) => {
  const defaultClass = inline === true ? 'tx-24 tx-center tx-md-left ' : 'preloader tx-center ';
  const defaultIconClass = inline === true ? 'mr-2 ' : 'mb-2 ';

  return (
    <div className={defaultClass + className}>
      <span className={defaultIconClass + iconClass}>
        <FontAwesomeIcon icon={faCircleNotch} spin />
      </span>
      <span className={textClass}>
        {text || <Translate i18nKey={!longWait ? 'common.loading' : 'common.loading-one-minute'} />}
      </span>
    </div>
  );
});
