import { useState, useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { EBreakpoint } from '@~styles/breakpoints';

const resolveBreakpoint = (width: number): EBreakpoint => {
  if (width < EBreakpoint.SM) {
    return EBreakpoint.XS;
  }
  if (width < EBreakpoint.MD) {
    return EBreakpoint.SM;
  }
  if (width < EBreakpoint.LG) {
    return EBreakpoint.MD;
  }
  if (width < EBreakpoint.XL) {
    return EBreakpoint.LG;
  }
  if (width < EBreakpoint.XXL) {
    return EBreakpoint.XL;
  }
  if (width < EBreakpoint.XXXL) {
    return EBreakpoint.XXL;
  }
  return EBreakpoint.XXXL;
};

export const useBreakpoint = () => {
  const [breakpoint, setBreakpoint] = useState(() => resolveBreakpoint(window.innerWidth));

  const update = useDebouncedCallback(() => {
    setBreakpoint(resolveBreakpoint(window.innerWidth));
  }, 200);

  useEffect(() => {
    window.addEventListener('resize', update);
    return () => window.removeEventListener('resize', update);
  }, [update]);

  return breakpoint;
};
