import { faMoneySimpleFromBracket } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalAlert } from '@~components/modal-alert/ModalAlert';
import { TransactionTable } from '@~components/transactions-table/TransactionTable';
import { ETableView, ETransactionType } from '@~types/enums';

export const WithdrawalsModal = memo(() => {
  const { t } = useTranslation();

  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <button className="btn btn-link tx-primary py-1 px-2" onClick={() => setOpenModal(true)}>
        {t('pages.account.withdrawal.content.withdrawals')}
        <FontAwesomeIcon icon={faMoneySimpleFromBracket} className="ml-3" />
      </button>

      <ModalAlert
        key="withdrawal-table-modal"
        proceed={setOpenModal}
        show={openModal}
        title={t('pages.account.withdrawal.content.withdrawals') + ''}
        cancelLabel={`${t('common.action.close')}`}
        noOk
        enableEscape
        centered={false}
        size="xl"
        content={
          <div className="mb-3">
            {openModal && (
              <TransactionTable tableView={ETableView.TABLE} transactionTypes={[ETransactionType.WITHDRAWAL]} />
            )}
          </div>
        }
      />
    </>
  );
});
