import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAtom } from 'jotai';
import { memo, useCallback, useMemo, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { FormattedNumber } from '@~components/formatted-number/FormattedNumber';
import { mapCurrency } from '@~helpers/mapCurrency';
import { rankRulesAtom, tokenBankAtom, userRankInfoAtom, walletsAtom } from '@~store/account.store';
import { ECurrency } from '@~types/enums';
import { convertCurrency } from '@~helpers/convertCurrency';

const currencies = [ECurrency.AUF, ECurrency.GAT, ECurrency.USD, ECurrency.SWAP_USD, ECurrency.ETHI_USD];
const hashToCurrencyMap: Record<string, ECurrency> = {
  aft: ECurrency.AUF,
  gat: ECurrency.GAT,
  usdt: ECurrency.USD,
  'swap-usdt': ECurrency.SWAP_USD,
  'ethi-usdt': ECurrency.ETHI_USD,
};

const currencyToHashMap: Record<ECurrency, string> = {
  [ECurrency.AUF]: 'aft',
  [ECurrency.GAT]: 'gat',
  [ECurrency.USD]: 'usdt',
  [ECurrency.SWAP_USD]: 'swap-usdt',
  [ECurrency.ETHI_USD]: 'ethi-usdt',
};

export const UserStats = memo(() => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [tokenBank] = useAtom(tokenBankAtom);
  const [userRankInfo] = useAtom(userRankInfoAtom);
  const [rankRules] = useAtom(rankRulesAtom);
  const [wallets] = useAtom(walletsAtom);

  const currencyHash = hashToCurrencyMap[location.hash.slice(1)] || ECurrency.AUF;
  const [selectedToken, setSelectedToken] = useState(currencyHash);

  const rankName = useMemo(() => {
    if (!userRankInfo || rankRules.length < 1) {
      return '';
    }
    return rankRules.find(({ level }) => level === userRankInfo.rankLevel)?.name || '---';
  }, [userRankInfo, rankRules]);

  const selectedWallet = useMemo(() => {
    let amount = 0;
    let balance = 0;
    if (wallets.length > 0 && tokenBank) {
      const wallet = wallets.find(({ currency }) => currency === selectedToken);
      if (wallet) {
        amount = wallet.balance + (wallet.metadata.fakeBalance || 0);

        if (selectedToken === ECurrency.AUF && tokenBank?.aufPrice) {
          balance = wallet.balance * tokenBank.aufPrice + (wallet.metadata.fakeBalance || 0) * tokenBank.aufPrice;
        } else if (selectedToken === ECurrency.GAT && tokenBank?.gatPrice) {
          balance = wallet.balance * tokenBank.gatPrice + (wallet.metadata.fakeBalance || 0) * tokenBank.gatPrice;
        } else {
          balance = wallet.usdBalance + (wallet.metadata.fakeBalance || 0);
        }
      }
    }
    return {
      amount,
      balance,
    };
  }, [wallets, tokenBank, selectedToken]);

  const handleTokenChange = useCallback(
    (currency: ECurrency) => () => {
      setSelectedToken(currency);
      navigate(`#${currencyToHashMap[currency]}`, { replace: true });
    },
    [navigate]
  );

  return (
    <div id="user-stats" className="card account">
      <div className="user-rank">
        <div className="notranslate rank-label tx-uppercase">{t('components.user-stats.rank-label')}</div>
        <div className="notranslate rank-value tx-uppercase">{rankName}</div>
      </div>
      <div className="token-selector">
        <Dropdown>
          <Dropdown.Toggle variant="transparent" className="token-select notranslate">
            {t(mapCurrency(selectedToken))} <FontAwesomeIcon icon={faAngleDown} className="caret" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {currencies.map((currency) => (
              <Dropdown.Item
                key={currency}
                className={currency === selectedToken ? 'active' : ''}
                onClick={handleTokenChange(currency)}
              >
                {t(mapCurrency(currency))}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="info-block">
        {selectedToken === ECurrency.AUF && (
          <>
            <div className="info-line">
              <span className="label">{t('components.user-stats.token-price')}</span>
              <span className="value">
                <FormattedNumber value={tokenBank?.aufPrice} postfix={convertCurrency(ECurrency.USD)} decimals={5} />
              </span>
            </div>
            <div className="info-line">
              <span className="label">{t('components.user-stats.until-increase')}</span>
              <span className="value">
                <FormattedNumber
                  value={tokenBank?.nextAufUpdateIn}
                  postfix={convertCurrency(ECurrency.USD)}
                  decimals={2}
                />
              </span>
            </div>
          </>
        )}
        {selectedToken === ECurrency.GAT && (
          <>
            <div className="info-line">
              <span className="label">{t('components.user-stats.token-price')}</span>
              <span className="value">
                <FormattedNumber value={tokenBank?.gatPrice} postfix={convertCurrency(ECurrency.USD)} decimals={5} />
              </span>
            </div>
            <div className="info-line">
              <span className="label">{t('components.user-stats.market-cap')}</span>
              <span className="value">
                <FormattedNumber value={tokenBank?.gatSoldCount} postfix={ECurrency.GAT} decimals={2} />
              </span>
            </div>
          </>
        )}
      </div>
      <div className="user-amount">
        <div className="tokens-amount">
          <FormattedNumber value={selectedWallet.amount} postfix={convertCurrency(selectedToken)} decimals={2} />
        </div>
        {selectedWallet.balance > 0 && [ECurrency.AUF, ECurrency.GAT].includes(selectedToken) && (
          <div className="tokens-balance">
            <FormattedNumber value={selectedWallet.balance} className="tx-italic" suffix={'≈$'} decimals={2} />
          </div>
        )}
      </div>
    </div>
  );
});
