import { EHistoryFilter, ETransactionType } from '@~types/enums';

export const typesFilterMap: Record<EHistoryFilter, ETransactionType[]> = {
  [EHistoryFilter.ALL]: [],
  [EHistoryFilter.DEPOSITS]: [
    ETransactionType.DEPOSIT_USDT,
    ETransactionType.ADMIN_DEPOSIT_GAT,
    ETransactionType.ADMIN_DEPOSIT_AFT,
    ETransactionType.ADMIN_DEPOSIT_USDT,
    ETransactionType.ADMIN_DEPOSIT_ETHI,
    ETransactionType.ADMIN_DEPOSIT_SWAP,
  ],
  [EHistoryFilter.INVESTMENTS]: [
    ETransactionType.INVEST_AFT,
    ETransactionType.INVEST_USDT,
    ETransactionType.GAT_INVEST_AFT,
    ETransactionType.GAT_INVEST_GAT,
    ETransactionType.GAT_INVEST_USDT,
    ETransactionType.USDT_INVEST_USDT,
    ETransactionType.ETHI_INVEST_USDT,
    ETransactionType.ETHI_UPGRADE_USDT,
  ],
  [EHistoryFilter.PAYOUTS]: [
    ETransactionType.PASSIVE_PAYOUT,
    ETransactionType.GAT_PASSIVE_PAYOUT,
    ETransactionType.USDT_PASSIVE_PAYOUT,
    ETransactionType.ETHI_PASSIVE_PAYOUT,
  ],
  [EHistoryFilter.BONUSES]: [ETransactionType.RANK_BONUS_PAYOUT],
  [EHistoryFilter.REFERRALS]: [
    ETransactionType.REFERRAL_PAYOUT,
    ETransactionType.REFERRAL_PAYOUT_GAT,
    ETransactionType.REFERRAL_PAYOUT_USDT,
    ETransactionType.REFERRAL_PAYOUT_ETHI,
  ],
  [EHistoryFilter.WITHDRAWALS]: [ETransactionType.WITHDRAWAL],
  [EHistoryFilter.OTHERS]: [
    ETransactionType.AFT_INVEST_EXIT,
    ETransactionType.GAT_GREAT_PASSIVE_PAYOUT,
    ETransactionType.USDT_INVEST_EXIT,
    ETransactionType.USDT_INVEST_EMERGENCY_EXIT,
    ETransactionType.USDT_INVEST_LAST_PERCENTS_EXIT,
    ETransactionType.ETHI_INVEST_DOWNGRADE,
    ETransactionType.ETHI_INVEST_EXIT,
    ETransactionType.ETHI_EMERGENCY_INVEST_EXIT,
    ETransactionType.SWAP_ETHI_USDT_TO_USDT,
    ETransactionType.SWAP_CURRENCY_TO_CURRENCY,
    ETransactionType.TRANSFER_USDT_TO_ETHI,
    ETransactionType.ETHI_INVEST_PLAN_DELETE,
    ETransactionType.USDT_INVEST_PLAN_DELETE,
    ETransactionType.GAT_INVEST_PLAN_DELETE,
    ETransactionType.AFT_INVEST_PLAN_DELETE,
    ETransactionType.ETHI_SET_BALANCE_TO_ZERO,
    ETransactionType.USDT_SET_BALANCE_TO_ZERO,
    ETransactionType.AFT_SET_BALANCE_TO_ZERO,
    ETransactionType.GAT_SET_BALANCE_TO_ZERO,
  ],
};
