import { faRightToBracket } from '@fortawesome/pro-duotone-svg-icons/faRightToBracket';
import { useAtom } from 'jotai';
import { memo, useCallback, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link, Navigate, useNavigate } from 'react-router-dom';

import { digitsRx, TWO_FACTOR_REQUIRED, usernameRx } from '@~constants';
import { IconWithLoading } from '@~components/icon-with-loading/IconWithLoading';
import { ModalAlert } from '@~components/modal-alert/ModalAlert';
import { ParticlesBackground } from '@~components/particles-background/ParticlesBackground';
import { Translate } from '@~components/translate/Translate';
import { useResponseHandler } from '@~hooks/useResponseHandler';
import { login } from '@~network/account';
import { loggedAtom, tokenAtom } from '@~store/account.store';
import { EAccountLink } from '@~types/navigationEnums';

export const LoginPage = memo(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isLoggedIn] = useAtom(loggedAtom);
  const [, setToken] = useAtom(tokenAtom);

  const [isLoading, setLoading] = useState(false);
  const [loginValue, setLoginValue] = useState('');
  const [passwordValue, setPasswordValue] = useState('');
  const [oneTimePassword, setOneTimePassword] = useState<string>();
  const [open2FaModal, setOpen2FaModal] = useState(false);

  const handleResponse = useResponseHandler();

  const isLoginAndPasswordValid = useCallback(() => {
    if (!loginValue) {
      handleResponse(t('error.empty-login') + '', true);
      return false;
    }

    if (loginValue.length < 4 || !usernameRx.test(loginValue)) {
      handleResponse(t('error.wrong-login') + '', true);
      return false;
    }

    if (!passwordValue) {
      handleResponse(t('error.empty-password') + '', true);
      return false;
    }

    if (passwordValue.length < 8 || passwordValue.length > 40) {
      handleResponse(t('error.password-length') + '', true);
      return false;
    }

    return true;
  }, [loginValue, passwordValue, handleResponse, t]);

  const submitForm = useCallback(() => {
    if (!isLoginAndPasswordValid()) {
      return;
    }

    setLoading(true);

    login(loginValue, passwordValue, oneTimePassword)
      .then((response) => {
        if (response.success && response.data?.token) {
          setToken(response.data.token);
          navigate(EAccountLink.ROOT);
        } else {
          handleResponse(response);
        }
      })
      .catch((response) => {
        if (response.response.data.errors[0].code === TWO_FACTOR_REQUIRED) {
          setOpen2FaModal(true);
        } else {
          handleResponse(response.response.data);
        }
      })
      .finally(() => {
        setLoading(false);
        setOneTimePassword(undefined);
      });
  }, [setToken, navigate, loginValue, passwordValue, oneTimePassword, isLoginAndPasswordValid, handleResponse]);

  const proceedSubmitForm = useCallback(
    (success: boolean) => {
      if (!success) {
        setOpen2FaModal(false);
        return;
      }

      if (!oneTimePassword) {
        handleResponse(t('error.one-time-password-required') + '', true);
        return;
      }

      if (oneTimePassword.length < 6 || !digitsRx.test(oneTimePassword)) {
        handleResponse(t('error.one-time-password-error') + '', true);
        return;
      }

      submitForm();
    },
    [submitForm, oneTimePassword, handleResponse, t]
  );

  if (isLoggedIn) {
    return <Navigate replace to={EAccountLink.ROOT} />;
  }

  return (
    <>
      <ParticlesBackground />
      <Helmet>
        <title>Aurous | {t('pages.login.title')}</title>
      </Helmet>
      <div id="login" className="page flex-1 p-4 p-md-5 pt-navbar">
        <div className="container">
          <div id="login-form" className="centered-block">
            <h1 className="mb-4 text-center">
              <Translate i18nKey="pages.login.content.heading" />
            </h1>

            <form
              onSubmit={(e) => {
                e.preventDefault();
                submitForm();
              }}
              className="card mb-3"
            >
              <h2 className="tx-uppercase ms-3 mt-3 mb-5">{t('pages.login.title')}</h2>

              <div className="form-group mb-4">
                <div className="ms-3 tx-18 mb-1">{t('pages.login.content.login-label')}</div>
                <input
                  type="text"
                  className="form-control form-control-lg mb-2"
                  placeholder={t('pages.login.content.login-placeholder') + ''}
                  value={loginValue}
                  id="login"
                  onChange={(event) => setLoginValue(event.target.value)}
                  disabled={isLoading}
                />
              </div>

              <div className="form-group mb-6">
                <div className="ms-3 tx-18 mb-1">{t('pages.login.content.password-label')}</div>
                <input
                  type="password"
                  className="form-control form-control-lg mb-2"
                  placeholder={t('pages.login.content.password-placeholder') + ''}
                  value={passwordValue}
                  id="password"
                  onChange={(event) => setPasswordValue(event.target.value)}
                  disabled={isLoading}
                />
              </div>

              <div className="text-center mb-4">
                <button className="btn btn-lg btn-primary px-5" type="submit" disabled={isLoading}>
                  <IconWithLoading icon={faRightToBracket} className="me-2" isLoading={isLoading} />
                  {t('pages.login.content.button-login')}
                </button>
              </div>

              <div className="text-center">
                {t('pages.login.content.forgot-password')}{' '}
                <Link to={EAccountLink.PASSWORD_RECOVERY}>{t('pages.login.content.restore-password')}</Link>
              </div>
            </form>
            <div className="card py-3">
              <div className="text-center">
                {t('pages.login.content.no-account')}{' '}
                <Link to={EAccountLink.REGISTER}>{t('pages.login.content.register')}</Link>
              </div>
            </div>
          </div>
        </div>

        <ModalAlert
          key="modal-for-2fa-password"
          proceed={proceedSubmitForm}
          show={open2FaModal}
          title={t('pages.login.content.2fa.modal-title') + ''}
          okLabel={t('pages.login.content.button-login') + ''}
          enableEscape
          content={
            <div className="mb-3 text-center">
              <div className="form-group mb-3">
                <div className="tx-18 mb-1">{t('pages.login.content.2fa.password-label')}</div>
                <input
                  id="2fa-password"
                  type="text"
                  className="form-control one-time-password-input"
                  placeholder={t('pages.login.content.2fa.password-placeholder') + ''}
                  defaultValue={oneTimePassword}
                  maxLength={6}
                  onChange={(event) => setOneTimePassword(event.target.value)}
                />
                <div className="tx-gold">{t('pages.login.content.2fa.modal-subheader')}</div>
              </div>
            </div>
          }
        />
      </div>
    </>
  );
});
