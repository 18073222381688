import classNames from 'classnames';
import { memo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ELandingLink } from '@~types/navigationEnums';

import styles from './NotFound.module.scss';

export const NotFound = memo(() => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>Aurous | {t('pages.not-found.title')}</title>
      </Helmet>
      <div
        id="not-found"
        className={classNames(
          styles.root,
          'flex-1 bg-overlay p-4 p-md-5 pt-navbar d-flex flex-column justify-content-center align-items-center'
        )}
      >
        <h1 className="tx-36 tx-lg-42 tx-center">
          <div className="tx-bold tx-primary tx-80">404</div>
          {t('pages.not-found.content.page-not-found')}
        </h1>
        <Link to={ELandingLink.HOME} className="btn btn-primary btn-lg tx-20 mt-4">
          {t('pages.not-found.content.back-to-home')}
        </Link>
      </div>
    </>
  );
});
