import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowRight } from '@fortawesome/pro-regular-svg-icons/faLongArrowRight';
import { format, parseISO } from 'date-fns';
import React, { memo } from 'react';
import { Link } from 'react-router-dom';

import { Translate } from '@~components/translate/Translate';
import type { INews } from '@~types/types';

interface INewsViewProps {
  item: INews;
  short?: boolean;
}

export const NewsView = memo(({ item, short = false }: INewsViewProps) => {
  const { image, title, slug, shortDescription, description, date } = item;
  return (
    <article id={'news-' + slug} className="clearfix">
      <img src={image} alt={title} className={short ? 'news-image' : 'float-md-left wd-md-500 mr-3'} />

      {!short && <div className="mt-5 tx-gray-600">{format(parseISO(date), 'dd.MM.yyyy HH:mm')}</div>}
      <h3 className={short ? 'tx-26 mt-5 mb-4' : 'tx-26 mt-2 mb-4'}>{title}</h3>
      <div className="tx-gray-400 mb-3">{short ? shortDescription : description}</div>

      {short && (
        <Link to={'/news/' + slug} className="read-more d-flex align-items-center wd-150">
          <Translate i18nKey={'button.read-more'} />
          <FontAwesomeIcon icon={faLongArrowRight} className="ml-2" />
        </Link>
      )}
    </article>
  );
});
