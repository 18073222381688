import { useAtom } from 'jotai';
import { memo } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { AdminApplier } from '@~components/admin-applier/AdminApplier';
import { ClearLocalStorageApplier } from '@~components/clear-local-storage-applier/ClearLocalStorageApplier';
import { ReferralApplier } from '@~components/referral-applier/ReferralApplier';
import { Footer } from '@~components/footer/Footer';
import { Header } from '@~components/header/Header';
import { ProtectedRoute } from '@~components/protected-route/ProtectedRoute';
import { ScrollToTop } from '@~components/scroll-to-top/ScrollToTop';
import { DepositPage } from '@~pages/account/deposit/DepositPage';
import { HistoryPage } from '@~pages/account/history/HistoryPage';
import { PartnersPage } from '@~pages/account/partners/PartnersPage';
import { ProfilePage } from '@~pages/account/profile/ProfilePage';
import { PromoActions } from '@~pages/account/promo-actions/PromoActions';
import { RankSystem } from '@~pages/account/rank-system/RankSystem';
import { StakingPage } from '@~pages/account/staking/StakingPage';
import { SwapPage } from '@~pages/account/swap/SwapPage';
import { WithdrawalPage } from '@~pages/account/withdrawal/WithdrawalPage';
import { Landing } from '@~pages/landing/Landing';
import { LoginPage } from '@~pages/login/LoginPage';
import { NewsPage } from '@~pages/news/NewsPage';
import { NotFound } from '@~pages/not-found/NotFound';
import { PasswordRecoveryPage } from '@~pages/password-recovery/PasswordRecoveryPage';
import { PasswordResetPage } from '@~pages/password-reset/PasswordResetPage';
import { RegisterPage } from '@~pages/register/RegisterPage';
import { loggedAtom } from '@~store/account.store';
import { EAccountLink, ELandingLink } from '@~types/navigationEnums';

import styles from './App.module.scss';

export const App = memo(() => {
  const [isLoggedIn] = useAtom(loggedAtom);

  return (
    <>
      <div className={styles.root}>
        <ClearLocalStorageApplier />
        <AdminApplier />
        <ReferralApplier />
        <ScrollToTop />
        <Header />
        <Routes>
          <Route path={ELandingLink.HOME} element={<Landing />} />
          <Route path={ELandingLink.NEWS} element={<NewsPage />} />

          <Route path={EAccountLink.LOGIN} element={<LoginPage />} />
          <Route path={EAccountLink.REGISTER} element={<RegisterPage />} />
          <Route path={EAccountLink.PASSWORD_RECOVERY} element={<PasswordRecoveryPage />} />
          <Route path={EAccountLink.PASSWORD_RESET} element={<PasswordResetPage />} />

          <Route path={EAccountLink.ROOT} element={<ProtectedRoute isAllowed={isLoggedIn} />}>
            <Route index element={<DepositPage />} />
            <Route path={EAccountLink.DEPOSIT} element={<DepositPage />} />
            <Route path={EAccountLink.SWAP} element={<SwapPage />} />
            <Route path={EAccountLink.STAKING} element={<StakingPage />} />
            <Route path={EAccountLink.PARTNERS} element={<PartnersPage />} />
            <Route path={EAccountLink.RANK_SYSTEM} element={<RankSystem />} />
            <Route path={EAccountLink.HISTORY} element={<HistoryPage />} />
            <Route path={EAccountLink.PROFILE} element={<ProfilePage />} />
            <Route path={EAccountLink.WITHDRAWAL} element={<WithdrawalPage />} />
            <Route path={EAccountLink.PROMO_ACTIONS} element={<PromoActions />} />
          </Route>

          <Route key="/404" path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </div>
      <ToastContainer position="top-center" autoClose={10_000} theme="dark" />
    </>
  );
});
