import classNames from 'classnames';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Translate } from '@~components/translate/Translate';

import styles from './Video.module.scss';

export const Video = memo(() => {
  const { t } = useTranslation();

  return (
    <section className="pt-lg-7 pb-lg-4 pt-md-2 pb-md-4 pt-sm-4 pb-sm-4 pt-4 pb-4 pos-relative" id="video">
      <div className="container pb-6 padding-top-dv">
        <h2 className="mb-md-5 mt-md-5 pt-md-3 tx-lg-48 pb-3">
          <Translate i18nKey={'pages.home.content.video.title'} />
        </h2>

        <div className={classNames(styles.gapForVideo, 'row')}>
          <div className="col-md-6 order-2 order-md-1">
            <div className="mb-5 me-5">
              <Translate i18nKey={'pages.home.content.video.text'} />
            </div>
          </div>
          <div className="col-md-6 order-1 order-md-2 align-items-center d-flex d-flex justify-content-center">
            <div className={styles.videoMain}>
              <div className={styles.videoHolder}>
                <iframe
                  src={t('pages.home.content.video.link') + ''}
                  title="Aurous.Finance"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen={true}
                  className={styles.iframe}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});
